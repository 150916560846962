/* eslint-disable no-unneeded-ternary */
import React, { createContext, useContext, useRef, useState } from 'react';
import ModalErrorMsg from '../components/common/ModalErrorMsg';
import ModalCommonMessage from '../components/common/modals/ModalCommonMessage';

const MessagesHandlerContext = createContext({
  setError: () => {},
  setSuccess: () => {}
});

const ProviderMessagesHandler = ({ children }) => {
  const closeCallBackRef = useRef();
  const [state, setState] = useState({
    errorMessage: null,
    errorTitle: null,
    successTitle: null,
    successMessage: null,
    errorImage: false
  });

  const setErrorStateHandler = (message, title, ctaTitle, closeCallBack, errorImage) => {
    if (message !== state.errorMessage || title !== state.errorTitle) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: message,
        errorTitle: title,
        ctaTitle,
        errorImage
      }));
    }
    closeCallBackRef.current = closeCallBack;
  };

  const setMessageStateHandler = (message, title, ctaTitle, closeCallBack) => {
    if (message !== state.successMessage || title !== state.successTitle) {
      setState((prevState) => ({
        ...prevState,
        successMessage: message,
        successTitle: title,
        ctaTitle
      }));
      closeCallBackRef.current = closeCallBack;
    }
  };

  const closeErrorModal = (isBtnCloseClicked) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: null,
      errorTitle: null,
      errorImage: false,
      ctaTitle: null
    }));
    if (isBtnCloseClicked) {
      closeCallBackRef.current?.();
    }
    closeCallBackRef.current = undefined;
  };

  const closeSuccessModal = (isBtnCloseClicked) => {
    setState((prevState) => ({
      ...prevState,
      successMessage: null,
      successTitle: null,
      errorImage: false,
      ctaTitle: null
    }));
    if (isBtnCloseClicked) {
      closeCallBackRef.current?.();
    }
    closeCallBackRef.current = undefined;
  };

  return (
    <MessagesHandlerContext.Provider value={{ setError: setErrorStateHandler, setSuccess: setMessageStateHandler }}>
      <>
        {children}
        {state.errorMessage && (
          <ModalErrorMsg
            visibility={true}
            headerTitle={state.errorTitle ?? null}
            errorMessage={state.errorMessage}
            handleCloseModal={closeErrorModal}
            btnTitle={state.ctaTitle ?? 'OK'}
            isErrorImage={state.errorImage === undefined || !state.errorImage ? true : false}
          />
        )}
        {state.successMessage && (
          <ModalCommonMessage
            show={true}
            title={state.successTitle ?? ''}
            message={state.successMessage ?? ''}
            handleClose={closeSuccessModal}
            btnTitle={state.ctaTitle ?? 'OK'}
          />
        )}
      </>
    </MessagesHandlerContext.Provider>
  );
};

export default ProviderMessagesHandler;

export const useMessagesHandler = () => useContext(MessagesHandlerContext);
