import { firestoreInstance } from "../firebase";
import {
  collection,
  query,
  getDocs,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,addDoc
} from "firebase/firestore";
import axios from "axios";
import firebase from "firebase/app";
import { increment } from "firebase/firestore";

export const setDocument = async (collectionPath, docId, data) => {
  try {
    if (docId===null) {
      await addDoc(collection(firestoreInstance, collectionPath), data);
    } else {
      await setDoc(doc(firestoreInstance, collectionPath,docId), data, {
        merge: true,
      });
    }
  } catch (error) {
    console.error("error", error);
  }
};

export const getPublicCribsListing = async (
  page = 1,
  activeTab,
  selectedFilter,
  value,
  shouldAddQueryParam,
  sortfilter
) => {
  let paramObj = {};
  if (shouldAddQueryParam) {
    paramObj = {
      page,
      experience: activeTab === "all" ? "" : activeTab,
      size: selectedFilter ? selectedFilter : "",
      experience_name: value ? value : "",
      sort_field: sortfilter ? sortfilter : "",
    };
  } else {
    paramObj = {
      page: 1,
    };
  }
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CRIB_LISTING}invites/public-cribs?`,
      headers: {},
      params: {
        ...paramObj,
      },
    };
    const result = await axios(config);
    const listingData = result.data.payload;
    return listingData;
  } catch (error) {
    console.log(error);
  }
};

// export const getPublicCribsListing = async (page = 1, ip, isActive, limitValue = LIMIT) => {
//   let q = null;
//   if (isActive !== "all") {
//     q = query(
//       collection(firestoreInstance, "/invites"),
//       where("is_public", "==", true),
//       where("is_expired", "==", false),
//       where("experience", "==", isActive),
//       limit(page * limitValue)
//     )
//   } else {
//     q = query(collection(firestoreInstance, "/invites"),
//       where("is_public", "==", true),
//       where("is_expired", "==", false),
//       limit(page * limitValue)
//     );
//   }

//   let result = await getDocs(q)

//   let data = await Promise.all(result?.docs?.map(async (docFItem) => {
//     let isLiked = false
//     if (ip !== '') {
//       try {
//         let isExistDoc = await getDoc(doc(firestoreInstance, `/invites/${docFItem.id}/favorite_user_ips/${ip}`))
//         if (isExistDoc?.exists())
//           isLiked = true;
//       } catch (ignore) {
//         console.log(ignore)
//       }
//     }
//     return { ...docFItem.data(), doc_id: docFItem.id, is_liked: isLiked }
//   }))
//   return data
// };

// export const getTotalRecords = async (page = 1, ip, item, limitValue = LIMIT,) => {
//   let q = null;
//   if (item !== "all") {
//     q = query(
//       collection(firestoreInstance, "/invites"),
//       where("is_public", "==", true),
//       where("is_expired", "==", false),
//       where("experience", "==", item),
//     )
//   } else {
//     q = query(collection(firestoreInstance, "/invites"),
//       where("is_public", "==", true),
//       where("is_expired", "==", false),
//     );
//   }
//   return (await getCountFromServer(q)).data();
// };

export const handleLikeDislike = async (ip, doc_id, is_like, callback) => {
  let isLiked = !is_like ? true : false;
  if (ip !== "") {
    try {
      await setDoc(
        doc(firestoreInstance, `/invites/${doc_id}`),
        { is_liked: isLiked },
        { merge: true }
      );
      callback();
    } catch (ignore) {
      console.log(ignore);
    }
  }
};

// export const getSearchedCribs = async (search = "", limitValue = LIMIT) => {
//   const q = query(
//     collection(firestoreInstance, "/invites"),
//     where("is_public", "==", true),
//     where("is_expired", "==", false),
//     limit(limitValue),
//     orderBy("experience_name"),
//     startAt(search.toString()),
//     endAt(search.toString() + '\uf8ff'));
//   return await getDocs(q);
// };

export const hideChar = (str) => {
  if (str) {
    return str?.slice(0, 5) + str?.slice(-10).replace(/.(?=...)/g, "*");
  }
};

export const fetchMetaverseConfig = async (callback) => {
  const docRef = doc(
    firestoreInstance,
    process.env.REACT_APP_FIREBASE_CONFIG_ENDPOINT
  );
  return await onSnapshot(docRef, callback);
};

export const inviteVistorCounter = async (doc_id) => {
  try {
    const docRef = doc(firestoreInstance, "invites", doc_id);
    await updateDoc(docRef, {
      invite_visitor_counter: increment(1),
    });
  } catch (error) {
    console.error("Error updating invite visitor counter: ", error);
  }
};
// export const inviteVistorCounter = async (doc_id) => {
//   const docRef = await firestoreInstance.collection('/invites').doc(doc_id);
//   console.log(docRef,"docRef")
//   debugger
//   await docRef.set({
//     invite_visitor_counter: FieldValue.increment(1)
//   }, { merge: true });

//   console.log('Invite visitor counter updated successfully');
// }

export const inviteLikeCounter = async (currentCrib, ip) => {
  try {
    if (currentCrib.is_liked == false) {
      await deleteDoc(
        doc(
          firestoreInstance,
          `/invites/${currentCrib.document_id}/favorite_user_ips/${ip}`
        )
      );
      await setDoc(
        doc(firestoreInstance, `/invites/${currentCrib.document_id}`),
        { is_liked: false, favorite_count: currentCrib.favorite_count },
        { merge: true }
      );
    } else {
      const storeIp = doc(
        firestoreInstance,
        `/invites/${currentCrib.document_id}/favorite_user_ips/${ip}`
      );
      await setDoc(storeIp, { ip_address: ip }, { merge: true });
      await setDoc(
        doc(firestoreInstance, `/invites/${currentCrib.document_id}`),
        { is_liked: true, favorite_count: currentCrib.favorite_count },
        { merge: true }
      );
    }
  } catch (error) {
    console.log(error, "initvies error");
  }
};

export const getAbuseContent = async () => {
  const q = query(
    collection(
      firestoreInstance,
      `${process.env.REACT_APP_ENV_PATH}/abuse_content/content/`
    )
  );
  return await getDocs(q);
};

export const reportAbuseContent = async (
  reason,
  iP,
  cribDoc,
  description,
  closeModal,
  captchaToken,
  successNotification,
  setLoader,
  errorNotification
) => {
  try {
    let data = {
      token: captchaToken,
      invite_id: cribDoc,
      ip_address: iP,
      description: description ? description : reason,
      reason: reason,
    };
    await axios
      .post(`${process.env.REACT_APP_ABUSE_PUBLIC_CRIB}abuse-public-crib`, data)
      .then((res) => {
        setLoader(false);
        successNotification();
        if (res.data.payload.success) {
          closeModal();
        } else {
          return false;
        }
      })
      .catch((error) => {
        setLoader(false);

        errorNotification();
        console.log("error", error);
      });
  } catch (error) {
    console.log(error, "error");
  }

  // try {
  //   return await setDoc(doc(firestoreInstance, `/invites/${cribDoc}/crib_user_abuse/${iP}`), {
  //     reason: reason,
  //     description: description
  //   }, { merge: true }).then(() => {
  //     setTimeout(() => {
  //       closeModal();
  //     }, [5000])
  //   });
  // } catch (error) {
  //   console.log(error)
  // }
};
