import "./experience.scss";
import React from "react";
import { Video } from "../hero-section/HeroSectionStyled";
import { Para, SubTitle } from "../../../components/styledComponent";
import Button from "../../../components/generic/button";
import styled from "styled-components";
import { REACT_APP_S3_CLOUDFLARE_ASSETS_URL, REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";

const GradientEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  transform: rotate(180deg);
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;

const Experience = () => {
  return (
    <>
      <section className="relative experience-section gradient-effect">
        <GradientEffect />
        <Video poster={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}video_poster-1.jpg`} autoPlay loop muted playsInline>
          <source
            src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}stainless_games_bg.mp4`}
            // src="https://inventory.staging.bimtvi.com/virtua_home/home_banners/stainless_games_bg.mp4"
            type="video/mp4"
          />
        </Video>
        <div className="main-content">
          <SubTitle>play. own. experience.</SubTitle>
          <Para>Play exclusive Web3 games and collect exciting rewards!</Para>
          <Button upper secondary as="a" href="/games">
            Explore More
          </Button>
        </div>
      </section>
    </>
  );
};

export default Experience;
