import axios from "axios"

// Define the get method
const get = async (path, token) => {
    try {
        const response = await axios.get(path, {
            headers: {
                'Authorization': token ? `Bearer ${token}` : '',
                'Content-Type': 'application/json',
                // Add any other default headers here
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error making GET request', error);
        throw error;
    }
};

// Define the post method
const post = async (path, payload) => {
    try {
        const {token,data}=payload;
        const response = await axios.post(path, data, {
            headers: {
                'Authorization': token ? `Bearer ${token}` : '',
                'Content-Type': 'application/json',
                // Add any other default headers here
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error making POST request', error);
        throw error;
    }
};

const put=async(path,token,id)=>
{
    try {
        const response = await axios.put(path, {notificationId: id}, {
            headers: {
                'Authorization': token ? `Bearer ${token}` : '',
                'Content-Type': 'application/json',
                // Add any other default headers here
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error making POST request', error);
        throw error;
    }
}


// Define the methods object
const methods = {
    get: (path, token) => get(path, token),
    post: (path, payload) => post(path,payload),
    put:(path,token,id)=>put(path,token,id)
};


export default methods;