import Container from "../../components/generic/container";

export const TermsServices = () => {
    return (
        <>
            <Container>
                <div className="privacy-policy content">
                    <h1 className="main-title">Virtua Terms of Use</h1>
                    <div className="top_notice">Welcome to Terra Virtua! Terra Virtua is a platform that allows you to
                        buy, sell, and display Digital Collectibles. These Terms of Use are entered into by and between
                        you and Terra Virtua, Limited (“Terra Virtua,” “Company,” “we,” or “us”). The following terms
                        and conditions, together with any documents they expressly incorporate by reference, govern your
                        access to and use of Terra Virtua, including any content, functionality, and services offered on
                        or through <a href="https://virtua.com/" target="_blank" className="link"
                            rel="noopener">www.virtua.com</a> (the “Site”).
                    </div>
                    <h5>1. Accepting these Terms</h5>
                    <p> These Terms of Use set out your rights and responsibilities when you use Terra Virtua </p>
                    <p>• to buy, sell, or display non-fungible tokens (“Digital Collectibles” or “Digital Collectible”) or create a
                        collection of Digital Collectibles (collectively, the “Services”), so please read them carefully. OR</p>
                    <p> • to buy, sell or use the “vCoins” means virtual currency, including without limitation virtual coins, cash, tokens,
                        or points, all for use in the platform or the metaverse of the Company.</p>
                    <p> Terra Virtua is an administrative platform that facilitates transactions between a buyer and a seller but is not a
                        party to any agreement between the buyer and seller of Digital Collectibles or between any users. By clicking on the
                        “I Accept” button, completing the account registration process, or using the Site, you accept and agree to be bound
                        and abide by these Terms of Use and all of the terms incorporated herein by reference. By agreeing to these terms,
                        you hereby certify that you are at least 18 years of age. If you do not agree to these Terms of Use, you must not
                        access or use the Site.</p>
                    <p>Please note that Section 18 contains an arbitration clause and class action waiver. By agreeing
                        to these Terms of Use, you agree to resolve all disputes through binding individual arbitration,
                        which means that you waive any right to have the dispute decided by a judge or jury, and you
                        waive any right to participate in collective action, whether that be a class action, class
                        arbitration, or representative action.</p>
                    <p>Terra Virtua reserves the right to change or modify these Terms of Use at any time and in our
                        sole discretion. You agree and understand that by logging into your account, accessing or using
                        the Site following any change to these Terms of Use, you agree to the revised Terms of Use and
                        all of the terms incorporated therein by reference. We encourage you to review the Terms of Use
                        from time to time to ensure that you understand the terms and conditions that apply when you
                        access or use the Site.</p>
                    <h5>2. Your Terra Virtua Account</h5>
                    <p>You need to create an account with Terra Virtua to use the Services. When you create an account,
                        we will ask you for some information about yourself. We may require you, in our sole discretion,
                        to provide additional information and/or documents. If you do not provide complete and accurate
                        information and/or documents in response to such a request, we may refuse to provide you with
                        the Services.</p>
                    <p>Your Terra Virtua account is subject to the following conditions:</p>
                    <p><strong>• </strong>Access: you understand and agree that access to your Terra Virtua account is
                        limited solely to you. You agree that you will not sell, rent, lease, or grant access to your
                        Terra Virtua account to any person without our prior written permission.</p>
                    <p><strong>• </strong>Security: you understand and agree that you are solely responsible for
                        maintaining the security of your account and control over any user names, passwords, or any
                        other codes that you use to access the Services. Any unauthorized access to your account by
                        third parties could result in the loss or theft of Digital Collectibles and/or funds held in
                        your account and any associated accounts, including your linked bank account(s) and credit
                        card(s). You understand and agree that you will not hold us responsible for managing and
                        maintaining the security of your account. You further understand and agree that we are not
                        responsible (and you will not hold us responsible) for any unauthorized access to or use of your
                        account. You are responsible for monitoring your account. If you notice any unauthorized or
                        suspicious activity in your account, please notify us immediately.</p>
                    <p><strong>• </strong>Trading Fees: by buying or selling a Digital Collectible on Terra Virtua, you
                        agree to pay all applicable fees and you authorize Terra Virtua to automatically deduct fees
                        directly from your payment. You will be provided with a breakdown of fees related to your
                        purchase or sale of a Digital Collectible, on demand. Contact support.</p>
                    <p><strong>• </strong>Your item price includes 20% VAT, 5% Platform Fee, and 1% Transaction Fee for
                        all Primary Sales.</p>
                    <p><strong>• </strong>Your item price includes a 3% Transaction Fee for all Secondary Sales.</p>
                    <p><strong>• </strong>2.9% + 30¢ are credit card processing fees in case of FIAT transactions.</p>
                    <p><strong>• </strong>The minimum threshold for withdrawals is $30, and you are not entitled to
                        withdraw less than USD 30.</p>
                    <p><strong>• </strong>Communication: you agree and understand that we will communicate with you via
                        electronic means. To ensure that you receive all of our communications, you agree to keep your
                        email address and telephone number current and notify us if there are any changes. You agree
                        that any notices, agreements, disclosures, or other communications delivered to your email
                        address on record is considered valid.</p>
                    <p><strong>• </strong>USD Balance: certain approved users may carry a balance in U.S. Dollars to
                        facilitate transactions on the Terra Virtua platform. You are the owner of your balance.</p>
                    <p><strong>• </strong>Unclaimed Property: if Terra Virtua is holding funds in your account and has
                        no record of your use of the Service for several years, we may be required, upon passage of
                        applicable time periods, to report these funds as unclaimed property in accordance with the
                        abandoned property and escheat laws. If this occurs, we will use reasonable efforts to give you
                        written notice. If you fail to respond within seven business days or as required by law, we may
                        be required to deliver any such funds to the applicable state or jurisdiction as unclaimed
                        property. We reserve the right to deduct a dormancy fee or administrative fee from such
                        unclaimed funds, as permitted by applicable law.</p>
                    <p><strong>• </strong>Refunds: Since transactions on the blockchain are irreversible, we cannot
                        provide refunds. We have no ability to cancel, revert, or modify any transactions on the
                        Ethereum network.</p>
                    <h5>3. Account Suspension</h5>
                    <p>You agree that Terra Virtua has the right to immediately suspend your account, pause or cancel
                        your access to the Services, or close your account if we suspect, in our sole discretion, that
                        (1) your account is being used for money laundering or any illegal activity; (2) you have
                        concealed or provided false identification information or other details; (3) you have engaged in
                        fraudulent activity; or (4) you have engaged in transactions in violation of these Terms of
                        Use.</p>
                    <p><strong>• Breach of this policy</strong></p>
                    <p>a) When we consider that a breach of this acceptable use policy has occurred, we may take such
                        action as we deem appropriate.</p>
                    <p>b) Failure to comply with this acceptable use policy constitutes a material breach of the terms
                        of use upon which you are permitted to use our site, and may result in our taking all or any of
                        the following actions:</p>
                    <p>c) Immediate, temporary or permanent withdrawal of your right to use our site.</p>
                    <p>d) Immediate, temporary or permanent removal of any Contribution uploaded by you to our site.</p>
                    <p>e) Issue of a warning to you.</p>
                    <p>f) Legal proceedings against you for reimbursement of all costs on an indemnity basis (including,
                        but not limited to, reasonable administrative and legal costs) resulting from the breach.</p>
                    <p>g) Further legal action against you.</p>
                    <p>h) Disclosure of such information to law enforcement authorities as we reasonably feel is
                        necessary or as required by law.</p>
                    <p>i) We exclude our liability for all action we may take in response to breaches of this acceptable
                        use policy. The actions we may take are not limited to those described above, and we may take
                        any other action we reasonably deem appropriate.</p>
                    <h5>4. Refund Policy</h5>
                    <p>Terra Virtua cannot provide refunds for purchases made between two parties (ie ‘Trading’).</p>
                    <p>Auction items are also exempt from refund so please be certain you definitely want the item
                        before placing a bid.</p>
                    <p>You will not be eligible for refunds for items for which you have violated the terms of service.
                        In addition, you may not be eligible for refunds if Terra Virtua determines that you are abusing
                        the refund policy, for instance if you have made multiple refund requests.</p>
                    <h5>5. Communication</h5>
                    <p>You agree that we may send you promotional communications by email, including, but not limited
                        to, newsletters, special offers, surveys, and other news and information we think will be of
                        interest to you.</p>
                    <p>You agree that we may send you communications by email or text message that pertain to the status
                        of a purchase or sale of a Digital Collectible on Terra Virtua, and other communications that
                        pertain to your interaction with the website, including but not limited to notifications about
                        the status of a Digital Collectible you are interested in purchasing.</p>
                    <p>You may opt out of promotional communications at any time by following the instructions provided
                        therein.</p>
                    <h5>6. User Content</h5>
                    <p>The Site allows (1) users to create a profile where they can post certain information about
                        themselves, link to other websites, and display Digital Collectibles that they own
                        (collectively, “Profile Information”); and (2) artists or creators of Digital Collectibles
                        (“Digital Collectible Creators”) to post their Digital Collectibles (“Digital Collectible
                        Creations”), and bios (collectively, with Profile Information, “User Content”). All User Content
                        must comply with these Terms of Use.</p>
                    <p>Any Profile Information you post to the Site will be considered non-confidential and
                        non-proprietary. By providing any User Content on the Website, you grant us and our affiliates
                        and our respective licensees, successors, and assigns the right to use, reproduce, modify,
                        perform, display, distribute, retransmit, publish, broadcast, and otherwise disclose to third
                        parties any such material for any purpose. You represent and warrant that (1) you own and
                        control all rights in and to your User Content and have the right to grant such licenses to us
                        and our affiliates and our respective licensees, successors, and assigns; and (2) all of your
                        User Content do and will comply with these Terms of Use.</p>
                    <p>You understand and agree that you are responsible for any User Content you submit or contribute,
                        and you, not Terra Virtua, have full responsibility for such content, including its legality,
                        reliability, accuracy, and appropriateness. We are not responsible or liable to any third party
                        for the content, accuracy, or appropriateness of any User Content posted by you or any other
                        user on the Site.</p>
                    <p>If you are a Digital Collectible Creator, you hereby grant Terra Virtua the right to use your
                        name and image for marketing or promotional purposes. If you are a Digital Collectible Creator,
                        you agree that we may use or modify images from the Digital Collectibles that you create for
                        marketing or promotional purposes. You also agree that we can use your biography and other
                        public information about you to promote the Digital Collectibles that you create.</p>
                    <p>As a Digital Collectible Creator, you understand and agree that:</p>
                    <p><strong>• </strong>You are responsible for the accuracy and content of your Digital Collectible
                        Creations;</p>
                    <p><strong>• </strong>Any Digital Collectible Creation that violates any of Terra Virtua’s policies
                        may be obfuscated or deleted by Terra Virtua, in its sole discretion;</p>
                    <p><strong>• </strong>You will respect the intellectual property of others; and</p>
                    <p><strong>• </strong>You will not coordinate pricing with other Digital Collectible Creators.</p>
                    <p>We reserve the right, in our sole discretion, to prohibit you from uploading your Digital
                        Collectible Creations. Although we are not required to monitor any User Content, we may, in our
                        sole discretion, remove User Content at any time and for any reason without notice. Terra Virtua
                        may monitor User Content to detect and prevent fraudulent activity or violation of these Terms
                        of Use.</p>
                    <p>Whenever you make use of a feature that allows you to upload content to our site, or to make
                        contact with other users of our site, you must comply with the content standards set out
                        herein.</p>
                    <p>You warrant that any such contribution does comply with those standards, and you will be liable
                        to us and indemnify us for any breach of that warranty. This means you will be responsible for
                        any loss or damage we suffer as a result of your breach of warranty.</p>
                    <p>Any content you upload to our site will be considered non-confidential and non-proprietary. You
                        retain all of your ownership rights in your content, but you are required to grant us [and other
                        users of our site] a limited licence to use, store and copy that content and to distribute and
                        make it available to third parties.</p>
                    <p>We also have the right to disclose your identity to any third party who is claiming that any
                        content posted or uploaded by you to our site constitutes a violation of their intellectual
                        property rights, or of their right to privacy.</p>
                    <p>We have the right to remove any posting you make on our site if, in our opinion, your post does
                        not comply with the content standards as required by us.</p>
                    <p>You are solely responsible for securing and backing up your content.</p>
                    <p>We do not store terrorist content.</p>
                    <h5>vCoins use and its policy:</h5>
                    <p>a. How you can get vCoins</p>
                    <p> In-App Purchases</p>
                    <p>• From time to time, we may offer vCoins for purchase through the App Store, Google Play or other application
                        platforms ("in-app purchases"). If you choose to make an in-app purchase, you will be prompted to enter details for
                        your account with the mobile platform you are using (e.g., Apple, Android, etc.) ("your IAP Account"), and your IAP
                        Account will be charged for the in-app purchase in accordance with the terms disclosed to you at the time of
                        purchase as well as the general terms for in-app purchases that apply to your IAP Account.</p>
                    <p>The price of the vCoins will be displayed at the point of purchase. All charges and payments for vCoins will be made
                        in the currency specified at the point of purchase through the relevant payment mechanism. Currency exchange
                        settlements, foreign transaction fees and payment channel fees, if any, are based on your agreement with the
                        applicable payment provider.</p>
                    <p>• You will be responsible for the payment of any vCoins purchased by you. Once your purchase has been completed, your
                        user account will be credited with vCoins.</p>
                    <p>• If you live in the European Union, you have certain rights to withdraw from a purchase under the Consumer Rights
                        Directive and its implementing legislation. However, if you purchase vCoins, you acknowledge and agree that we start
                        supplying the vCoins to you promptly once the purchase is complete and therefore, your right to cancel or withdraw
                        from the agreement to purchase is lost at this point.</p>
                    <p>• Wherever, a game/upgrade offers vCoins and such vCoins collected by users are generated by the system and are NOT
                        purchased by user, you agree that absolute right to manage, regulate, control, modify and/or eliminate such vCoins
                        lies solely with the Company as it sees fit in its sole discretion, in any general or specific case, and that
                        Company will have no liability to you based on its exercise of such right.</p>
                    <p> b. Use of vCoins</p>
                    <p>• Participate in games</p>
                    <p> • Purchase season pass to games</p>
                    <p>• Other existing uses and uses Company may add in the future</p>
                    <p> • Upgrade the level in the game to get more rewards</p>
                    <p> • Purchase profile frames, themes, and other tokens</p>
                    <p>• Company has the sole right to adjust the uses and usage rules of vCoins from time to time.</p>
                    <p> • The vCoins in your account will be consumed because they are used.</p>
                    <p>• vCoins cannot be converted into or exchanged for or cash, or be refunded or reimbursed by us for any reason.</p>
                    <p>• vCoins can only be used on our website/platform/metaverse/in-app games as part of our services, and cannot be
                        combined or used in conjunction with other promotions, coupons, discounts or special offers, except those designated
                        by us.</p>
                    <p>• No vCoins may be assigned or transferred to any other user of the services or third party except as expressly
                        permitted by us in writing. The sale, barter, assignment or other disposal of any vCoins, other than by us, is
                        expressly prohibited.</p>
                    <p>• Accrued vCoins do not constitute property and are not transferable: (a) upon death; (b) as part of a domestic
                        relations matter; or (c) otherwise by operation of law.</p>
                    <p>• Any vCoins assigned, sold, or otherwise transferred without the express written consent of us are void. Any user of
                        the services who violates this restriction may have his or her account terminated by us, have vCoins forfeited from
                        his or her account, and/or be subject to liability for damages and litigation and transaction costs.</p>
                    <p>• All vCoins of a user will expire automatically upon termination of such user's account for any reason.</p>
                    <p>• We reserve the right to deduct vCoins from your account if the user acquire them by illegal or fraud way.</p>
                    <p>• You agree that we have the right to manage, regulate, control, modify and/or eliminate such vCoins, where we have a
                        valid reason to do so such as where we reasonably believe you have violated this Policy, you are in breach of any
                        applicable law or regulation or for legal, security or technical reasons and that we will have no liability to you
                        based on our exercise of such right.</p>
                    <p>c.Special description</p>
                    <p>vCoins and subscription services require users to purchase from officially approved channels such as Apple/Google App
                        Store through their Apple/Google App Store accounts.</p>
                    <p>Handing over your account to others for operation, or conducting private transactions with others, or malicious
                        refunds after recharging are all violations of the platform rules. These actions will disrupt the normal management
                        of the platform, and will also give users account and personal information bring safety hazards.</p>
                    <p>In response to this type of behavior, the platform will take measures including but not limited to the following:</p>
                    <p>• Take back all the virtual currency and purchased items in the account suspected of proxy charging or malicious
                        refund.</p>
                    <p>• Freeze all remaining virtual items in the account until the refund is completed.</p>
                    <p>• Time-limited or permanent ban on the account.</p>
                    <p>You may cancel your account at any time, and we may terminate your account in accordance with the terms. All the
                        vCoins in your account will be forfeited immediately upon the cancellation or termination of your account. There
                        will be no refunds for any unused or unredeemed vCoins. So we recommend that you use them before terminating your
                        account.</p>
                    <p>If you have any problems with vCoins, please <a className="link" href="mailto:support@virtua.com">contact us</a>.</p>
                    <h5>7. Ownership</h5>
                    <p>Unless otherwise indicated in writing by us, the Site, all content, and all other materials
                        contained therein, including, without limitation, the Terra Virtua logo, and all designs, text,
                        graphics, pictures, information, data, software, sound files, other files, and the selection and
                        arrangement thereof (collectively, “Terra Virtua Content”) are the proprietary property of Terra
                        Virtua or our affiliates, licensors, or users, as applicable. The Terra Virtua logo and any
                        Terra Virtua product or service names, logos, or slogans that may appear on the Site or
                        elsewhere are trademarks of Terra Virtua or our affiliates, and may not be copied, imitated or
                        used, in whole or in part, without our prior written permission.</p>
                    <p>You may not use any Terra Virtua Content to link to the Site or Content without our express
                        written permission. You may not use framing techniques to enclose any Terra Virtua Content
                        without our express written consent. In addition, the look and feel of the Site and Content,
                        including without limitation, all page headers, custom graphics, button icons, and scripts
                        constitute the service mark, trademark, or trade dress of Terra Virtua and may not be copied,
                        imitated, or used, in whole or in part, without our prior written permission.</p>
                    <p>Notwithstanding anything to the contrary herein, you understand and agree that you shall have no
                        ownership or other property interest in your account, and you further agree that all rights in
                        and to your account are and shall forever be owned by and inure to the benefit of Terra
                        Virtua.</p>
                    <h5>8. Intellectual Property</h5>
                    <p>Outside the Terra Virtua Content, all other trademarks, product names, and logos on the Site are
                        the property of their respective owners and may not be copied, imitated, or used, in whole or in
                        part, without the permission of the applicable trademark holder. Without limiting the foregoing,
                        if you believe that third-party material hosted by Terra Virtua infringes your copyright or
                        trademark rights, please file a notice of infringement by contacting Terra Virtua at <a
                            href="mailto:info@virtua.com" className="link">info@virtua.com</a> with the subject line ‘COPYRIGHT
                        INFRINGEMENT’</p>
                    <p>In such event, please provide Terra Virtua with the following information: (1) an electronic or
                        physical signature of the person authorized to act on behalf of the owner of the copyright
                        interest; (2) a description of the copyrighted work that you claim has been infringed; (3) a
                        description of the location on Site of the material that you claim is infringing; (4) your
                        address, telephone number and e-mail address; (5) a written statement by you that you have a
                        good faith belief that the disputed use is not authorized by the copyright owner, its agent or
                        the law; and (6) a statement by you, made under penalty of perjury, that the above information
                        in your notice is accurate and that you are the copyright owner or authorized to act on the
                        copyright owner’s behalf.</p>
                    <p>Terra Virtua’s policy is to suspend or terminate the account of repeat infringers. Terra Virtua’s
                        response may depend on relevant aggravating or mitigating circumstances, but generally we will
                        terminate an account if it is the subject of three valid infringement notices.</p>
                    <h5>9. Your Use of Terra Virtua</h5>
                    <p>We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and
                        use the Site and Content. Our grant of such license is subject to the following conditions:</p>
                    <p><strong>• </strong>Unlawful Activity: you agree not to engage, or assist, in any activity that
                        violates any law, statute, ordinance, regulation, or sanctions program, including but not
                        limited to the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or that
                        involves proceeds of any unlawful activity.</p>
                    <p><strong>• </strong>Abusive Activity: you agree not to engage in any activity that poses a threat
                        to Terra Virtua, for example by distributing a virus or other harmful code, or through
                        unauthorized access to the Site or other users’ accounts.</p>
                    <p><strong>• </strong>Inappropriate Behavior: you agree not to interfere with other users’ access to
                        or use of the Services.</p>
                    <p><strong>• </strong>Communication: you agree not to communicate with other users for purposes of
                        (1) sending unsolicited advertising or promotions, requests for donations, or spam; (2)
                        harassing or abusing other users; (3) interfering with transactions of other users. You agree
                        not to use data collected from the Site to contact individuals, companies, or other persons or
                        entities outside the Site for any purpose, including but not limited to marketing activity.</p>
                    <p><strong>• </strong>Fraud: you agree not to engage in any activity which operates to defraud Terra
                        Virtua, others users, or any other person; or to provide any false, inaccurate, or misleading
                        information to Terra Virtua.</p>
                    <p><strong>• </strong>Gambling: you agree not to utilize the Services to engage in any lottery,
                        bidding fee auctions, contests, sweepstakes, or other games of chance.</p>
                    <p><strong>• </strong>Taxes: you agree that you are solely responsible for determining what, if any,
                        taxes apply to your Digital Collectible transactions. Neither Terra Virtua nor any other Terra
                        Virtua Party is responsible for determining the taxes that apply to your Digital Collectible
                        transactions.</p>
                    <p><strong>• </strong>Intellectual Property Infringement: you agree not to (1) engage in
                        transactions involving items that infringe or violate any copyright, trademark, right of
                        publicity or privacy or any other proprietary right under the law; (2) use Terra Virtua Content
                        (as defined in Section 7 above) without express written consent from Terra Virtua; or (3) engage
                        in any action that implies an untrue endorsement or affiliation with Terra Virtua.</p>
                    <p><strong>• </strong>Hyperlinks: you are hereby granted a limited, non-exclusive, non-transferable,
                        revocable license to create a text hyperlink to the Site for noncommercial purposes, provided
                        that such link does not portray Terra Virtua, our affiliates, or the Services in a false,
                        misleading, derogatory, or otherwise defamatory manner, and provided further that the linking
                        site does not contain any adult or illegal materials, or other materials that is offensive,
                        harassing, or otherwise objectionable.</p>
                    <p><strong>• </strong>Additional Prohibitions: you agree not to (1) distribute, publish, broadcast,
                        reproduce, copy, retransmit, or publicly display any Content; (2) modify or create derivative
                        works from the Site or Content, or any portion thereof; (3) use any data mining, robots, or
                        similar data gathering or extraction methods on the Site or Content; (4) download any portion of
                        the Site or Content, other than for purposes of page caching, except as expressly permitted by
                        us.</p>
                    <p><strong>• </strong>Individual Use:<strong><span> </span></strong>You agree and represent that
                        your account was created and is being used solely by you personally and that you are not
                        accessing our Site on behalf of any other person or entity.</p>
                    <p>If you are unsure whether a contemplated use would violate these Terms of Use, please contact us
                        at the address listed below under Contact Information.</p>
                    <h5>10. Privacy</h5>
                    <p>You acknowledge and agree to the collection, use, and disclosure of your personal information in
                        accordance with <a href="https://virtua.com/privacy-policy" target="_blank"
                            rel="noopener" className="link">Terra Virtua’s Privacy Policy</a>, which is
                        incorporated into this User Agreement by reference.</p>
                    <h5>11. Modifications</h5>
                    <p>You agree and understand that we may modify part or all of Terra Virtua or the Services without
                        notice.</p>
                    <h5>12. Risks</h5>
                    <p>Please note the following risks in accessing or using Terra Virtua:</p>
                    <p><strong>• </strong>The price and liquidity of blockchain assets, including Digital Collectibles,
                        are extremely volatile and may be subject to large fluctuations;</p>
                    <p><strong>• </strong>Fluctuations in the price of other digital assets could materially and
                        adversely affect Digital Collectibles, which may also be subject to significant price
                        volatility;</p>
                    <p><strong>• </strong>Legislative and regulatory changes or actions at the state, federal, or
                        international level may adversely affect the use, transfer, exchange, and value of Digital
                        Collectibles;</p>
                    <p><strong>• </strong>Digital Collectibles are not legal tender and are not backed by the
                        government;</p>
                    <p><strong>• </strong>Transactions in Digital Collectibles may be irreversible, and, accordingly,
                        losses due to fraudulent or accidental transactions may not be recoverable;</p>
                    <p><strong>• </strong>Some transactions in Digital Collectibles shall be deemed to be made when
                        recorded on a public ledger, which is not necessarily the date or time that you initiated the
                        transaction;</p>
                    <p>The value of Digital Collectibles may be derived from the continued willingness of market
                        participants to exchange fiat currency or digital assets for Digital Collectibles, which may
                        result in the potential for permanent and total loss of value of a particular Digital
                        Collectible should the market for that Digital Collectible disappear;</p>
                    <p><strong>• </strong> The nature of Digital Collectibles may lead to an increased risk of fraud or
                        cyber attack, and may mean that technological difficulties experienced by Terra Virtua may
                        prevent the access to or use of your Digital Assets;</p>
                    <p><strong>• </strong>Changes to Third Party Sites (discussed in Section 12 below) may create a risk
                        that your access to and use of the Site will suffer.</p>
                    <p>You agree and understand that you are solely responsible for determining the nature, potential
                        value, suitability, and appropriateness of these risks for yourself, and that Terra Virtua does
                        not give advice or recommendations regarding Digital Collectibles, including the suitability and
                        appropriateness of, and investment strategies for, Digital Collectibles. <strong><em>You agree
                            and understand that you access and use Terra Virtua at your own risk</em></strong>; however,
                        this brief statement does not disclose all of the risks associated with Digital Collectibles and
                        other digital assets. You agree and understand that Terra Virtua will not be responsible for any
                        communication failures, disruptions, errors, distortions or delays you may experience when using
                        Digital Collectibles, however caused.</p>
                    <p><strong>We are not responsible for viruses and you must not introduce them</strong></p>
                    <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
                    <p>You are responsible for configuring your information technology, computer programmes and platform
                        to access our site. You should use your own virus protection software.</p>
                    <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or
                        other material that is malicious or technologically harmful. You must not attempt to gain
                        unauthorised access to our site, the server on which our site is stored or any server, computer
                        or database connected to our site. You must not attack our site via a denial-of-service attack
                        or a distributed denial-of service attack. By breaching this provision, you would commit a
                        criminal offence. We will report any such breach to the relevant law enforcement authorities and
                        we will co-operate with those authorities by disclosing your identity to them. In the event of
                        such a breach, your right to use our site will cease immediately.</p>
                    <h5>13. Third Parties</h5>
                    <p>Terra Virtua may rely on third-party platforms to perform Digital Collectible transactions. Our
                        Site may contain links to third-party websites or applications (collectively, “Third Party
                        Sites”). Terra Virtua does not own or control Third Party Sites. You understand and agree that
                        your use of any Third Party Site is subject to any terms of use and/or privacy policy provided
                        by such Third Party Site. Terra Virtua is not a party to any such agreement. You should review
                        any terms of use and/or privacy policy provided by such Third Party Site and should make
                        whatever investigation you feel necessary or appropriate before proceeding with any transaction
                        with any third party.</p>
                    <p>Terra Virtua provides these Third Party Sites only as a convenience and does not review, approve,
                        monitor, endorse, warrant, or make any representations with respect to Third Party Sites, or
                        their products or services. You use all links in Third Party Sites at your own risk. When you
                        leave our Site, our Agreement and policies no longer govern. We may, but are not obligated to,
                        warn you that you are leaving our Site.</p>
                    <h5>14. Disclaimers</h5>
                    <p>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY TERRA VIRTUA, THE SITE, CONTENT
                        CONTAINED THEREIN, AND DIGITAL COLLECTIBLES LISTED THEREIN ARE PROVIDED ON AN “AS IS” AND “AS
                        AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. TERRA
                        VIRTUA (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL (1) MEET YOUR REQUIREMENTS; (2)
                        BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (3) BE ACCURATE,
                        RELIABLE, COMPLETE, LEGAL, OR SAFE.</p>
                    <p>WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN RELIANCE ON
                        MATERIAL OR INFORMATION, CONTAINED ON THE SITE. TERRA VIRTUA DOES NOT REPRESENT OR WARRANT THAT
                        CONTENT ON THE SITE IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.</p>
                    <p>WHILE TERRA VIRTUA ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SITE AND CONTENT SAFE, TERRA
                        VIRTUA CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SITE, CONTENT, ANY DIGITAL COLLECTIBLES
                        LISTED ON OUR SITE OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT
                        GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY
                        RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US
                        RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.</p>
                    <p>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL
                        NOT BE LIABLE TO YOU FOR, ANY USE OF DIGITAL COLLECTIBLES INCLUDING BUT NOT LIMITED TO ANY
                        LOSSES, DAMAGES OR CLAIMS ARISING FROM: (1) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY
                        CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (2) SERVER FAILURE OR DATA LOSS; (3) CORRUPTED
                        WALLET FILES; (4) UNAUTHORIZED ACCESS TO APPLICATIONS; (5) ANY UNAUTHORIZED THIRD PARTY
                        ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER
                        MEANS OF ATTACK AGAINST THE SITE OR DIGITAL COLLECTIBLES.</p>
                    <p>DIGITAL COLLECTIBLES ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP
                        RECORD MAINTAINED IN THE ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE
                        DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT
                        GUARANTEE THAT TERRA VIRTUA OR ANY TERRA VIRTUA PARTY CAN EFFECT THE TRANSFER OF TITLE OR RIGHT
                        IN ANY DIGITAL COLLECTIBLES.</p>
                    <p>Terra Virtua is not responsible for sustained losses due to vulnerability or any kind of failure,
                        abnormal behavior of software (e.g., wallet, smart contract), blockchains, or any other features
                        of Digital Collectibles. Terra Virtua is not responsible for sustained losses due to late
                        reports by developers or representatives (or no report at all) of any issues with the blockchain
                        supporting Digital Collectibles including forks, technical node issues or any other issues
                        having fund losses as a result.</p>
                    <p>Nothing in this Agreement shall exclude or limit liability of either party for fraud, death or
                        bodily injury caused by negligence, violation of laws, or any other activity that cannot be
                        limited or excluded by legitimate means.</p>
                    <p>TO THE FULLEST EXTENT PROVIDED BY LAW, TERRA VIRTUA HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                        WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES
                        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SITE
                        AND CONTENT CONTAINED THEREIN.</p>
                    <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                        LAW.</p>
                    <h5>15. Limitation of Liability</h5>
                    <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL TERRA VIRTUA BE LIABLE TO YOU OR ANY
                        THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL
                        OR PUNITIVE DAMAGES ARISING FROM THIS AGREEMENT, THE SITE, PRODUCTS OR THIRD PARTY SITES AND
                        PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                        ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT
                        (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF TERRA
                        VIRTUA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITES,
                        PRODUCTS OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
                        SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA
                        RESULTING THEREFROM.</p>
                    <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM
                        AGGREGATE LIABILITY OF TERRA VIRTUA ARISING OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE
                        ACCESS TO AND USE OF THE SITE, CONTENT, DIGITAL COLLECTIBLES, OR ANY PRODUCTS OR SERVICES
                        PURCHASED ON THE SITE EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT YOU HAVE PAID TO TERRA
                        VIRTUA FOR THE SERVICES IN THE LAST SIX MONTHS OUT OF WHICH LIABILITY AROSE. THE FOREGOING
                        LIMITATIONS OF LIABILITY SHALL NOT APPLY TO LIABILITY OF TERRA VIRTUA FOR (A) DEATH OR PERSONAL
                        INJURY CAUSED BY A MEMBER OF TERRA VIRTUA’S NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED BY A MEMBER
                        OF TERRA VIRTUA’S FRAUD OR FRAUDULENT MISREPRESENTATION.</p>
                    <p>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential
                        damages, so the above limitation or exclusion may not apply to you. Some jurisdictions also
                        limit disclaimers or limitations of liability for personal injury from consumer products, so
                        this limitation may not apply to personal injury claims.</p>
                    <h5>16. Indemnification</h5>
                    <p>To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold
                        harmless Terra Virtua, and our respective past, present and future employees, officers,
                        directors, contractors, consultants, equity holders, suppliers, vendors, service providers,
                        parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors
                        and assigns (individually and collectively, the “Terra Virtua Parties”), from and against all
                        actual or alleged third party claims, damages, awards, judgments, losses, liabilities,
                        obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees
                        and expenses) and costs (including, without limitation, court costs, costs of settlement and
                        costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether
                        known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in
                        law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but
                        not limited to, damages to property or personal injury, that are caused by, arise out of or are
                        related to (a) your use or misuse of the Site, Content or Digital Collectibles, (b) any Feedback
                        you provide, (c) your violation of these Terms of Use, and (d) your violation of the rights of a
                        third party, including another user. You agree to promptly notify Terra Virtua of any third
                        party Claims and cooperate with the Terra Virtua Parties in defending such Claims. You further
                        agree that the Terra Virtua Parties shall have control of the defense or settlement of any third
                        party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET
                        FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND TERRA VIRTUA.</p>
                    <h5>17. Governing Law</h5>
                    <p>These Terms of Use, your use of Terra Virtua, your rights and obligations, and all actions
                        contemplated by, arising out of or related to these Terms of Use shall be governed by the laws
                        of the United Kingdom, as if these Terms of Use are a contract wholly entered into and wholly
                        performed within the United Kingdom. YOU UNDERSTAND AND AGREE THAT YOUR USE OF TERRA VIRTUA AS
                        CONTEMPLATED BY THESE TERMS OF USE SHALL BE DEEMED TO HAVE OCCURRED IN THE UNITED KINGDOM AND BE
                        SUBJECT TO THE INTERNAL LAWS OF THAT COUNTRY WITHOUT REGARD TO ITS CONFLICTS OF LAWS
                        PROVISIONS.</p>
                    <h5>18. Disputes</h5>
                    <p>Please read the following agreement to arbitrate (“Arbitration Agreement”) in its entirety. This
                        clause requires you to arbitrate disputes with Terra Virtua and limits the manner in which you
                        can seek relief from us.</p>
                    <p>You agree that any dispute or claim relating in any way to: your access, use, or attempted access
                        or use of the Site; any products sold or distributed through the Site; or any aspect of your
                        relationship with Terra Virtua will be resolved by binding arbitration, except that (1) you may
                        assert claims in small claims court if your claims qualify; and (2) you or Terra Virtua may seek
                        equitable relief in court for infringement or other misuse of intellectual property rights (such
                        as trademarks, trade dress, domain names, trade secrets, copyright, or patent). You agree that
                        any such arbitration shall be settled solely and exclusively by binding arbitration held in the
                        United Kingdom and conducted in English, rather than in court.</p>
                    <p>The arbitrator shall have exclusive authority to (1) determine the scope and enforceability of
                        this Arbitration Agreement; and (2) resolve any dispute related to the interpretation,
                        applicability, enforceability or formation of this Arbitration Agreement, including but not
                        limited to any claim that all or part of this Arbitration Agreement is void or voidable; (3)
                        decide the rights and liabilities, if any, of you and Terra Virtua; (4) grant motions
                        dispositive of all or part of any claim; (5) award monetary damages and grant any non-monetary
                        remedy or relief available to a party under applicable law, arbitration rules, and these Terms
                        of Use (including the Arbitration Agreement). The arbitrator has the same authority to award
                        relief on an individual basis that a judge in a court of law would have. The arbitrator shall
                        issue a written award and statement of decision describing the essential findings and
                        conclusions on which the award is based, including the calculation of any damages awarded. Such
                        an award is final and binding upon you and us.</p>
                    <p>You understand that by agreeing to this Arbitration Agreement, you and Terra Virtua are each
                        waiving their right to trial by jury and to participate in a class action or class
                        arbitration.</p>
                    <p>If any part of this Arbitration Agreement is found to be invalid or unenforceable, then such part
                        shall be of no force and effect and shall be severed and the remainder of the Arbitration
                        Agreement shall continue to in full force and effect.</p>
                    <p>This Arbitration Agreement shall survive the termination of your relationship with Terra
                        Virtua.</p>
                    <h5>19. Termination</h5>
                    <p>We reserve the right, without notice and in our sole discretion, to terminate your license to
                        access or use the Site or Content, at any time and for any reason. You understand and agree that
                        we shall have no liability or obligation to you in such an event.</p>
                    <h5>20. Severability</h5>
                    <p>If any term, clause, or provision of these Terms of Use is held invalid or unenforceable, then
                        that term, clause, or provision shall be severable from these Terms of Use and will not affect
                        the validity or enforceability of any remaining part of that term, cause, or provision, or any
                        other terms, clause, or provision of these Terms of Use.</p>
                    <h5>21. Entire Agreement</h5>
                    <p>These Terms of Use comprise the entire agreement between you and Terra Virtua relating to your
                        access to and use of the Site and Content, and supersede any and all prior discussions
                        agreements, and understandings of any kind (including without limitation prior versions of this
                        User Agreement). Except as otherwise provided herein, these Terms of Use are intended solely for
                        the benefit of the parties and are not intended to confer third party beneficiary rights upon
                        any other person or entity.</p>
                    <h5>22. Survival</h5>
                    <p>You agree and understand that all provisions of these Terms of Use shall survive the termination
                        or expiration of these Terms of Use.</p>
                    <h5>23. Season Rules & Regulations</h5>
                    <p>The following rules apply to all individuals partaking in Virtua’s seasons and their various activities.</p>
                    <p>In case of suspicion of cheating, spoofing, or any other illegal activities, Virtua holds the right to ban and suspend your Virtua account and seize any off-chain rewards and/or XP earned via the aforementioned activities.
                    </p>
                    <p>Virtua holds the right to change any aspect of the season DURING the season which includes but is not limited to:</p>
                    <ul>
                        <li>XP gained by any game within the Virtua ecosystem.</li>
                        <li>Daily XP cap for games within the Virtua ecosystem.</li>
                        <li>Off-chain rewards obtained in the season.</li>
                    </ul>
                    <h5>24. Contact Information</h5>
                    <p>If you have any questions, would like to provide feedback, or would like more information about
                        Terra Virtua, please feel free to email us at <a href="mailto:info@virtua.com"
                            className="link">info@virtua.com</a>. If you
                        would like to lodge a complaint, please contact us at <a href="mailto:help@virtua.com"
                            className="link">help@virtua.com</a> or
                        write to us at:</p>
                    <p>Terra Virtua <br />Kemp House,<br />160 City Road,<br />London EC1V 2NX,<br />United Kingdom</p>
                    <h5>Service changes and limitations:</h5>
                    <p>Our services are evolving, and we may require that you accept or acknowledge updates to the Services as well as to
                        the Terms, Privacy Policy, or other policies. From time to time, we may make you update the game or our software to
                        continue to use our Services. We may perform these updates remotely, including the software residing on your
                        computer or mobile device, without notifying you.</p>
                    <p>We reserve the right to stop offering and/or supporting the services or a particular game or part of the services at
                        any time either permanently or temporarily, at which point your right to use the services or any part of them will
                        be automatically terminated or suspended. If that happens, unless applicable law requires otherwise, the Company is
                        not required to provide refunds, benefits, or other compensation to you in connection with discontinued elements of
                        the services or for virtual items or vCoins or any items previously earned or purchased.</p>
                    <p>THE COMPANY MAY, IN ITS SOLE DISCRETION, LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE
                        SERVICES OR ANY PORTION OF THEM; PROHIBIT ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT, SERVICES, AND TOOLS; OR
                        DELAY OR REMOVE HOSTED CONTENT, AND</p>
                    <p>WE ARE UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY LOSSES OR RESULTS.</p>
                    <p>Deleting your Account:</p>
                    <p>You may stop using our Services and request that we delete your Account at any time. You may WRITE TO US to request
                        deletion of your Account. Unless applicable law requires otherwise, we are not required to provide refunds,
                        benefits, or other compensation if you request deletion of your Account.</p>
                </div>
            </Container>
        </>
    );
};
