import { useEffect, useState } from 'react';
import methods from "../../components/common/api-calls/methods"

const REACT_APP_LEDGER_API = process.env.REACT_APP_LEDGER_API;

export const useLedger = (keyCloakUser) => {
    const [ledgerData, setLedgerData] = useState(null);
    const [error, setError] = useState(null);

    const getLedgerData = async (accessToken) => {
        try {
            const data = await methods.get(`${REACT_APP_LEDGER_API}/balance`, accessToken);
            setLedgerData({
                xp: data?.payload?.xp,
            });
        } catch (error) {
            console.error('Failed to fetch ledger data', error);
            setError(error);
        }
    };

    useEffect(() => {
        if (keyCloakUser?.accessToken) {
            getLedgerData(keyCloakUser.accessToken);
        }
    }, [ keyCloakUser]);

    return { ledgerData, error };
};
