import Button from "../generic/button"
import { CrossIcon } from "../condo-modal/CondoModalStyled"
import { DASHBOARD } from "../../enviroment"
import Spinner from "../core/loader/Spinner"
import { useState } from "react"

const NotificationsItem = ({ index,notifications, header, handleMessageRead, loader,itemDeleted }) => {
  const questStatus = [
    'QUEST_COMPLETED',
    'QUEST_STARTED',
    'QUEST_UPDATED',
    'QUEST_FAILED'
  ]
  const handleMessageReadRedirect = async (id) => {
    await handleMessageRead(id);
    window.open(`${DASHBOARD}claim`, '_blank');
  }
  const key=notifications?.id ?? `_notificationsItem${index}`;
  return (
    <>
      <li className="col" key={key}>
        <div className="list-msg">
          <div>
            <span class={header}></span>
          </div>
          <p dangerouslySetInnerHTML={{ __html: notifications?.description }} />
        </div>
        {!questStatus.includes(notifications.notification_type) ? <Button onClick={() => handleMessageReadRedirect(notifications?.id)} border0 upper secondary>
          {notifications?.cta_title}
        </Button> : <div onClick={() => handleMessageRead(notifications?.id)} className="crossIcon" style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", right: "15px", cursor: "pointer" }}>
          {(itemDeleted?.length>0 && itemDeleted.some(item=>item===key)) ? <div className="loader_noti" style={{ width: "25px", height: "25px" }}><Spinner /></div> : '✖'}
        </div>}

      </li>

    </>
  )
}

export default NotificationsItem;