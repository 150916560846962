import React from "react";
import "./VirtualWorld.scss";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import Button from "../../components/generic/button";

function DashboardBanner({ handleClickGamersLounge }) {

    return (
        <div className="banner dashboard">
            <img
                className="slide-bg web"
                src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gamersloungeBannerWeb.jpg`}
                alt="gamersloungeBannerWeb"
            />
            <img
                className="slide-bg mob"
                src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gamersloungeBannerMob.jpg`}
                alt="gamersloungeBannerMob"
            />
            <BannerText className="text-holder">
                <SectionHeading className="text_title">GAMERS LOUNGE DESKTOP EXPERIENCE</SectionHeading>
                <Para>The Gamers Lounge is your own Virtua space where you can hang out with friends, play games and display all your gaming rewards and trophies.</Para>
                <Button border0 upper secondary btnWidth footerButton target="_blank" as="a" onClick={handleClickGamersLounge} >
                    OPEN GAMERS LOUNGE
                </Button>
            </BannerText>
        </div>
    );
}

export default DashboardBanner;
