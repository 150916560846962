import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./components/layout/Main";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/reset.css";
import KeyCloakProvider from "./keycloak/KeyCloakProvider.tsx";
import ProviderMessagesHandler from "./providers/ProviderMessagesHandler";
import { ProviderAppStatus } from "./providers/ProviderAppStatus";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KeyCloakProvider>
    <ProviderMessagesHandler>
      <ProviderAppStatus>
      <Main />
      </ProviderAppStatus>
    </ProviderMessagesHandler>
  </KeyCloakProvider>
);

reportWebVitals();
