import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
import "./GameSlider.scss";

// import required modules
import { Autoplay, FreeMode, Thumbs, EffectFade } from "swiper";
import { Para, SectionHeading } from "../../../components/styledComponent";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import Button from "../../../components/generic/button";
import { Link } from "react-router-dom";

export default function GameSlider() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="game-slider">
      <div className="content">
        <SectionHeading>Play Games</SectionHeading>
        <Para>
          Welcome to your new gaming home! Play your favourite games or expand
          your gaming experience and find new favourites. Through web3, game
          worlds are more connected than ever.
        </Para>
        <Link to="/games">
          <Button border0 upper secondary>
            Play NOW
          </Button>
        </Link>
      </div>
      <Swiper
        spaceBetween={15}
        thumbs={{ swiper: thumbsSwiper }}
        effect={"fade"}
        modules={[FreeMode, Thumbs, Autoplay, EffectFade]}
        className="mySwiper2"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <img
            className="web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}blast-front-gameSliderImg.webp`}
            alt="Blast front"
          />
          <img
            className="mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}blast-front-gameSliderMobImg.webp`}
            alt="Blast front"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}nitro-gameSliderImg.webp`}
            alt="Nitro"
          />
          <img
            className="mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}nitro-gameSliderMobImg.webp`}
            alt="Nitro"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gladiator-gameSliderImg.webp`}
            alt="Gladiator"
          />
          <img
            className="mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gladiator-gameSliderMobImg.webp`}
            alt="Gladiator"
          />
        </SwiperSlide>
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={15}
        slidesPerView={3}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}blast-front-gameSliderLogo.webp`}
            alt="Blast front"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}nitro-gameSliderLogo.webp`}
            alt="Nitro"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gladiator-gameSliderLogo.webp`}
            alt="Gladiator"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
