import './contentPages.css';
import Container from "../../components/generic/container";

export const PrivacyPolicy = () => {
    return (
        <>
            <Container>
                <div className="privacy-policy content">
                    <h1 className="main-title">Privacy Policy</h1>
                    <h5>WELCOME TO VIRTUA!</h5>
                    <p>TERRA VIRTUA LTD provides a social digital goods platform via the Terra Virtua website (the
                        “Site/website”), the TERRA VIRTUA MOBILE APPLICATION (the “App”) and related Internet services
                        (collectively, the “Service(s)”). The Service is operated by TERRA VIRTUA LTD (the “Company”, “we”
                        or “us”) for users of the Service (“you/user”).</p>
                    <p>TERRA VIRTUA LTD respects your privacy and is committed to protecting your personal data. This
                        privacy policy will inform you as to how we look after your personal data when you visit our website
                        (regardless of where you visit it from) and tell you about your privacy rights and how the law
                        protects you.</p>
                    <p>This privacy policy is provided in a layered format so you can click through to the specific areas
                        set out below. Alternatively, you can download a pdf version of the policy <a className="link"
                            href={process.env.REACT_APP_S3_VIRTUA_POLICY_URL}>here</a>.
                        Please also use the Glossary at the end of this document to understand the meaning of some of the
                        terms used in this privacy policy.</p>
                    <p>Important information and who we are</p>
                    <p>Purpose of this privacy policy</p>
                    <p>This privacy policy aims to give you information on how TERRA VIRTUA LTD collects and processes your
                        personal data through your use of this website, including any data you may provide through this
                        website when you sign up to our newsletter <strong>OR</strong> purchase a product or
                        service <strong>OR</strong> take part in a competition <strong>OR</strong> when you interact with
                        our website in whatsoever manner.</p>
                    <h5>PERSONS NOT OF LEGAL AGE:</h5>
                    <p>When you interact with us through the Services, we may collect information from you, as further
                        described below:</p>
                    <p>This website is not intended for children and we do not knowingly collect data relating to children.
                        Our Sites are not intended for use by persons under 13 years of age ( or of such age considered as
                        CHILD in the jurisdiction that you are accessing this website from) without legal parental or
                        guardian consent. No one under age 13 may provide any personal information to or on our Sites
                        without legal parental or guardian consent. We do not knowingly collect personal information from
                        persons under 13 if such information is provided without legal parental or guardian consent. If you
                        are under 13, do not use or provide any information on our Sites, or on or through any of their
                        features register on the Sites, make any purchases through our Sites, use any of the interactive or
                        public comment features of our Sites or provide any information about yourself to us, including your
                        name, address, telephone number, e-mail address or any screen name or user name you may use without
                        legal parental or guardian consent. If we learn we have collected or received personal information
                        from a person under 13 without verification of parental consent, we will delete that information. If
                        you believe we might have any information from or about a person under 13 for which there is no
                        legal parental or guardian consent, please <a className="link" href="mailto:privacy@virtua.com">contact
                            us</a></p>
                    <p>It is important that you read this privacy policy together with any other privacy policy or fair
                        processing policy we may provide on specific occasions when we are collecting or processing personal
                        data about you so that you are fully aware of how and why we are using your data. This privacy
                        policy supplements other notices and privacy policies and is not intended to override them.</p>
                    <p>Controller</p>
                    <p>TERRA VIRTUA LTD is the controller and responsible for your personal data (collectively referred to
                        as "COMPANY", "we", "us" or "our" in this privacy policy) and responsible for this website.</p>
                    <p>We have an appointed data privacy manager who is responsible for overseeing questions in relation to
                        this privacy policy. If you have any questions about this privacy policy, including any requests to
                        exercise your legal rights, please contact the data privacy manager using the details set out
                        below.</p>
                    <p>Contact details</p>
                    <p>If you have any questions about this privacy policy or our privacy practices, please contact our data
                        privacy manager in the following ways:</p>
                    <p>Attention to: The Data Privacy Manager and team</p>
                    <p>Email address: <a className="link" href="mailto:privacy@virtua.com">privacy@virtua.com</a></p>
                    <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO),
                        the UK regulator for data protection issues (<a className="link" href="https://www.ico.org.uk"
                            target="_blank" rel="noopener">www.ico.org.uk</a>
                        ). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so
                        please contact us in the first instance.</p>
                    <p>Changes to the privacy policy and your duty to inform us of changes</p>
                    <p>We keep our privacy policy under regular review. Historic versions can be obtained by <a
                        className="link" href="mailto:privacy@virtua.com" contacting="" us="" a=""></a></p>
                    <p>It is important that the personal data we hold about you is accurate and current. Please keep us
                        informed if your personal data changes during your relationship with us.</p>
                    <p>REQUESTS MADE AS PER THE EU DATA PROTECTION LAW PRIOR TO 25 MAY 2018</p>
                    <p>The data protection law in the European Union ("EU") will change on 25 May 2018. Although this
                        Privacy Policy sets out most of your rights under the new laws applicable to you if you are resident
                        of a country where such laws apply, we may not yet be able to respond to some of your requests (for
                        example, a request for the transfer of your personal data) until 25 May 2018 as we are still working
                        towards getting our systems ready for some of these changes. From 25 May 2018 onwards however any
                        requests made by you under the data protection law applicable to us where you reside will be
                        attended to by us in accordance with this Privacy Policy and the said data protection law.</p>
                    <p>EU-U.S. PRIVACY SHIELD AND SWISS-U.S. PRIVACY SHIELD</p>
                    <p>Terra Virtua complies with the EU-U.S. Privacy Shield Framework and the Swiss – U.S. Privacy Shield
                        Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and
                        retention of personal information transferred from the European Union and Switzerland to the United
                        States, respectively. Terra Virtua Limited has certified to the Department of Commerce that it
                        adheres to the Privacy Shield Principles. If there is any conflict between the terms in this privacy
                        policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more
                        about the Privacy Shield program, and to view our certification, please visit <a
                            href="https://www.privacyshield.gov/" className="link" target="_blank"
                            rel="noopener">https://www.privacyshield.gov/</a>.</p>
                    <p>Terra Virtua Limited is potentially liable for onward transfers to third parties of personal data of
                        EU or Swiss individuals received pursuant to Privacy Shield. Terra Virtua Limited is subject to
                        oversight by the U.S. FTC. JAMS is the US-based alternative dispute resolution provider responsible
                        for reviewing and resolving complaints about Terra Virtua Limited’s Privacy Shield compliance. We
                        ask that you first submit any complaints to us at <a className="link"
                            href="mailto:privacy@virtua.com">privacy@virtua.com</a>.
                        If you aren't satisfied with our response, you can then contact JAMS at <a className="link"
                            href="https://www.jamsadr.com/eu-us-privacy-shield"
                            target="_blank"
                            rel="noopener">https://www.jamsadr.com/eu-us-privacy-shield</a>.
                        In the event your concern still isn't addressed by JAMS, you may be entitled to a binding
                        arbitration under Privacy Shield and its principles.</p>
                    <p>Third-party links</p>
                    <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those
                        links or enabling those connections may allow third parties to collect or share data about you. We
                        do not control these third-party websites and are not responsible for their privacy statements. When
                        you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
                    <h5>The data we collect about you</h5>
                    <p>Personal data, or personal information, means any information about an individual from which that
                        person can be identified. It does not include data where the identity has been removed (anonymous
                        data).</p>
                    <p>We may collect, use, store and transfer different kinds of personal data about you which we have
                        grouped together as follows:</p>
                    <p> <strong>Identity Data includes  </strong>
                        first name, last name, address, username or similar
                        identifier, title, date of birth and gender.</p>
                    <strong>Contact Data </strong>
                    <p> • includes billing address, delivery address, location, email address
                        and/or telephone numbers.</p>
                    <p>
                        <financial data="" strong=""> • includes bank account and payment card details, payment history.
                        </financial>
                    </p>
                    <p><strong>Transaction Data</strong> includes details about payments to and from you and other details
                        of products and services you have purchased from us.</p>
                    <p><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser
                        type and version, make and model (mobile phones only), operating system, hardware version, platform,
                        device settings and other technology identification on the devices used to access our Website, file
                        and software names and types, device identifiers, time zone setting and location, device locations
                        such as through GPS, Bluetooth or WiFi signals, browser plug-in types and versions, operating system
                        and platform, connection information such as the name of your mobile operator or ISP, browser type,
                        language and time zone, mobile phone number and IP address.Usage statistics about your interactions with the Services, including the links, objects, products and benefits you view, click or otherwise interact with (also known as “Clickstream Data”). Our emails may also contain tracking pixels that identify if and when you have opened an email that we have sent you, how many times you have read it and whether you have clicked on any links in that email. In connection with your use of certain Services, we will create and assign to your device an identifier that is similar to an account number. In addition, in certain circumstances, we may collect the name you have associated with your device, device type, telephone number, country, and any other information you choose to provide, such as username, character name, or e-mail address. We may also infer your approximate geographic location based on your IP address</p>
                    <p><strong>Profile Data</strong> includes your username and password, purchases or orders made by you,
                        your interests, preferences, feedback and survey responses.</p>
                    <p><strong>Usage Data</strong> includes information about how you use our Website, products and services
                        including purchase of our products, how you use your devices to access our Website, including the
                        screens you visit and searches you make.</p>
                    <p><strong>Info through other Services:</strong> You may give us permission to collect your information
                        in other services. For example, you may connect a social networking service ("SNS") such as Facebook
                        or Twitter to your Terra Virtua account. When you do this, it allows us to obtain information from
                        those accounts (for example, your friends or contacts)</p>
                    <p><strong>Other Data</strong> includes:</p>
                    <p> • photos or other data uploaded onto any of our website by you or shared with other users of our
                        Website by you via our Website;</p>
                    <p> • communications with us via social media platforms, email, electronic messages and other electronic
                        and non-electronic communications;</p>
                    <p> • third party partner information related to how you use our third party partners, such as your reviews
                        of our partners or other service providers.</p>
                    <p>  • your networks and connections made available to us, depending on the permissions you have granted,
                        from your mobile and desktop devices' address book contacts, and other social media such as
                        Facebook, Instagram, Pinterest and Twitter.</p>
                    <p><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing
                        from us and our third parties and your communication preferences</p>
                    <p>We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic
                        data for any purpose. Aggregated Data could be derived from your personal data but is not considered
                        personal data in law as this data will not directly or indirectly reveal your identity. For example,
                        we may aggregate your Usage Data to calculate the percentage of users accessing a specific website
                        feature. However, if we combine or connect Aggregated Data with your personal data so that it can
                        directly or indirectly identify you, we treat the combined data as personal data which will be used
                        in accordance with this privacy policy. We may also disclose aggregated user statistics in order to
                        describe our services to current and prospective business partners, and to other third parties for
                        other lawful purposes.</p>
                    <p>We do not collect any <strong>Special Categories of Personal Data</strong> about you (this includes
                        details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                        orientation, political opinions, trade union membership, information about your health, and genetic
                        and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
                    <p>Surveys, User Research Projects, Competitions and Promotional Events</p>
                    <p>If you choose to participate in, or fill out any forms relating to surveys, user research projects, competitions
                        (contests/sweepstakes), promotional events, or similar activities, we will collect your contact information (such as
                        your name, email, and phone number, address and postal code) to enable you to participate in the activity and to
                        allow us to communicate with you, as well as any other information that we request that you provide for purposes of
                        the activities, including audio or video recordings, photos, and/or screen captures. We may also collect your age,
                        gender, opinions, responses to certain research questions, prompts, or surveys, and other personal information,
                        consistent with the purposes of the Studio survey or user research project. </p>
                    <p> On occasion, we may also collect your shipping and billing information to send you certain rewards or incentives, if
                        you are a competition winner, or if you purchase our products or services. In addition, if you are a part of our
                        event or promotion partner, we may also collect your personal information including your name, company email, and
                        company address.</p>
                    <h5>Information We Collect from Other Sources</h5>
                    <p>We may collect or receive information from the following other sources (i) other Service users who choose to upload
                        their email contacts; (ii) third party information providers, such as digital advertising companies; and (iii)
                        social networking sites. We may combine this information with the information we collect from an individual
                        directly.</p>
                    <p>Sensitive or Special Categories of Information</p>
                    <p>We typically do not request or seek to elicit sensitive or special categories of information, but you may choose to
                        provide this information to us in connection with certain of the interactions described above, including when you
                        ask for assistance from our support team. The meaning of sensitive or special categories of information varies by
                        jurisdiction and it could include, for example, health, genetic or biometric data, or precise location information.
                    </p>
                    <p> Information we collect when you download, install and access our Apps</p>
                    <p> - we collect information that help us identify your device, such as:</p>
                    <p>- the technical features of your terminal equipment and software, such as the browser settings, the installed
                        plug-ins, the time zone, the screen size of your device, unique device ID, Advertising ID, Device Identifier, the
                        type and version of your operating system (“OS”), and your general (city/country) location (based on your IP
                        address);</p>
                    <p>- Gameplay data - session time, details of your in-app purchases (excluding your payment or credit card information),
                        in-app events such as spending virtual currencies, progresses in the App, timestamp, etc.;</p>
                    <p>- We may occasionally send you push notifications through our mobile applications to send you game updates, high
                        scores and other service-related notifications that may be of importance to you. You may at any time opt-out from
                        receiving these types of communications by turning them off at the device level through your settings or by going to
                        the settings for the app itself.</p>
                    <p>Advertising ID means Apple Identifier for Advertisers (“IDFA”) on Apple and Google Advertising Identity (“GAID”).
                        These are unique identifiers for mobile devices that advertisers use for personalized advertising. They are
                        consistent across all mobile applications and thus allow cross-app tracking.</p>
                    <p>Device identifier / information means device type, device model, device maker, device operating system and its
                        language or other technical data like screen size or processor, or combination of this data available from a device
                        for a developer for checking the device compatibility with a particular application.</p>
                    <p> On Apple, you can opt out of interest-based advertising by selecting “limit ad tracking” option in your privacy
                        settings. On Google Android, you can opt out of these ads by choosing “Opt-out of personalized ads”. Advertising IDs
                        are non-permanent, non-personal identifiers, which are uniquely associated with your device</p>
                    <p><strong>FACE TRACKING: The app (iOS) uses TrueDepth APIs and collects the user’s face angle and
                        facial expression information. No other data is collected from face tracking and this data is not
                        stored. </strong></p>
                    <p><strong>The user can load face filters inside the app. Those face filters stay within the app and we
                        do not have access to them.</strong></p>
                    <p><strong>The user may use built-in screen record functionality within the app. This allows the user to
                        record the screen and microphone audio and save/share the video/photo afterwards. It works in the
                        same way as the default iPhone screen recorder and permission is explicitly requested each time a
                        recording is started.</strong></p>
                    <p><strong>Finally, the App collects logs unless they are turned off. Logs are saved in the app’s data
                        directory and are simple text files. We do not have remote access to those logs. If you run into
                        issues while using the app, we may ask you to send us those logs but you would have to do so
                        manually. The logs do not contain any sensitive information and you can also access them at any time
                        from within the app. The face tracking data is not stored on your device and is not shared with us
                        or any third party</strong></p>
                    <p>For more details on the data storage related to iOS Apps, please refer to Apple’s Data Collection and
                        Storage Guidelines: <a className="link"
                            href="https://developer.apple.com/app-store/review/guidelines/#data-collection-and-storage"
                            target="_blank"
                            rel="noopener">https://developer.apple.com/app-store/review/guidelines/#data-collection-and-storage</a>
                    </p>
                    <p><strong>If you fail to provide personal data</strong></p>
                    <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and
                        you fail to provide that data when requested, we may not be able to perform the contract we have or
                        are trying to enter into with you (for example, to provide you with goods or services). In this
                        case, we may have to cancel a product or service you have with us but we will notify you if this is
                        the case at the time.</p>
                    <p><strong>How is your personal data collected?</strong></p>
                    <p><strong>We use different methods to collect data from and about you including through:</strong></p>
                    <p><strong>Direct interactions.</strong> You may give us your Identity, Contact and Financial Data by
                        filling in forms or by corresponding with us by post, phone, email or otherwise. This includes
                        personal data you provide when you:</p>
                    <p><strong>1.</strong> apply for our products or services;</p>
                    <p><strong>2.</strong> create an account on our website;</p>
                    <p><strong>3.</strong> subscribe to our service or publications;</p>
                    <p><strong>4.</strong> request marketing to be sent to you;</p>
                    <p><strong>5.</strong> enter a competition, promotion or survey;</p>
                    <p><strong>6.</strong> when you make purchases on our website; or </p>
                    <p><strong>7.</strong> give us feedback or contact us.</p>
                    <p><strong>Automated technologies or interactions.</strong> As you interact with our website, we will
                        automatically collect Technical Data about your equipment, browsing actions and patterns. We collect
                        this personal data by using cookies, server logs and other similar technologies. We may also receive
                        Technical Data about you if you visit other websites employing our cookies. Please see our cookie
                        policy below for further details.</p>
                    <p><strong>Cookies:</strong> We automatically capture some information through the use of cookies. A
                        cookie is a small file that is placed onto your desktop or mobile device when you visit our Sites in
                        order to enable certain features and functionality of our Sites. Our cookies can be categorized into
                        four types, each of which is outlined below</p>
                    <p><strong>Category 1:</strong> Strictly necessary cookies. These cookies are essential for enabling our
                        Sites to provide certain of your requested services such as retaining your shopping bag items.</p>
                    <p><strong>Category 2:</strong> Performance cookies. Performance cookies collect anonymous information
                        on the users of our Sites to enable us to improve the operation of our Sites. For example, we
                        utilise cookies to help us understand how customers arrive at and browse or use our Sites, and
                        highlight improvement areas such as navigation, shopping experience and marketing. The data stored
                        by these cookies does not include personal details from which an individual's identity can be
                        established.</p>
                    <p><strong>Category 3:</strong> Functionality cookies. These cookies retain choices you make such as
                        member ID, email ID and other preferences in order to help you avoid having to re-enter the
                        information as you navigate through our Sites, including the shopping cart.</p>
                    <p><strong>Category 4:</strong> Targeting or advertising cookies. These cookies collect information
                        about your browsing habits to allow us to provide advertising relevant to you and your interests.
                        The cookies retain information about the websites you have visited. This information is shared with
                        other parties such as advertisers on an anonymous basis. Your personally identifiable information
                        such as your name and address is not shared with such third parties. For example, we use third party
                        companies to provide you with more personalised advertisements when you visit certain third party
                        websites.</p>
                    <p>Cookies can be ;Persistent; or ;Session; Cookies. Persistent Cookies remain on Your personal computer
                        or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your
                        web browser.</p>
                    <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                    <p><strong>Necessary / Essential Cookies</strong></p>
                    <p><strong>Type: Session Cookies</strong></p>
                    <p><strong>Administered by: Us</strong></p>
                    <p>Purpose: These Cookies are essential to provide You with services available through the Website and
                        to enable You to use some of its features. They help to authenticate users and prevent fraudulent
                        use of user accounts. Without these Cookies, the services that You have asked for cannot be
                        provided, and We only use these Cookies to provide You with those services.</p>
                    <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                    <p><strong>Type: Persistent Cookies</strong></p>
                    <p><strong>Administered by: Us</strong></p>
                    <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                    <p><strong>Functionality Cookies</strong></p>
                    <p><strong>Type: Persistent Cookies</strong></p>
                    <p><strong>Administered by: Us</strong></p>
                    <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                        remembering your login details or language preference. The purpose of these Cookies is to provide
                        You with a more personal experience and to avoid You having to re-enter your preferences every time
                        You use the Website.</p>
                    <p><strong>Tracking and Performance Cookies</strong></p>
                    <p><strong>Type: Persistent Cookies</strong></p>
                    <p><strong>Administered by: Third-Parties</strong></p>
                    <p>Purpose: These Cookies are used to track information about traffic to the Website and how users use
                        the Website. The information gathered via these Cookies may directly or indirectly identify you as
                        an individual visitor. This is because the information collected is typically linked to a
                        pseudonymous identifier associated with the device you use to access the Website. We may also use
                        these Cookies to test new pages, features or new functionality of the Website to see how our users
                        react to them</p>
                    <p><strong>Web beacons:</strong> Pages of our Sites and our e-mails may contain small electronic files
                        known as web beacons (also referred to as clear gifs, pixel tags and single-pixel gifs) that permit
                        the Company, for example, to count users who have visited those pages or opened an e-mail and
                        collect other related website and app statistics (for example, recording the popularity of certain
                        app content and verifying system and server integrity).</p>
                    <p>We do not collect personal information automatically, but we may tie this information to personal
                        information about you that we collect from other sources or you provide to us.</p>
                    <p><strong>Log files:</strong> We may use log files to capture your activities on our apps as they
                        interact with our Sites. Information captured in such log files may include the types of content you
                        view or engage with and the frequency or duration of your activities.</p>
                    <p><strong>Advertisements:</strong> You may see our Service advertised in other applications or
                        websites. After clicking on one of these advertisements and installing our Service, you will become
                        a user of the Service. Advertising platforms, which include Twitter and Facebook (and whose SDKs are
                        integrated within our Service), may collect information for optimizing advertising campaigns outside
                        of the Service.</p>
                    <p>If you do not wish to receive personalized advertising that is delivered by third parties outside of
                        the Terra Virtua Service, you may be able to exercise that choice through opt-out programs that are
                        administered by third parties, including the Network Advertising Initiative (NAI), the Digital
                        Advertising Alliance (DAA). Our Services currently do not respond to “Do Not Track” (DNT) signals
                        and operate as described in this Privacy Policy whether or not a DNT signal is received, as there is
                        no consistent industry standard for compliance</p>
                    <p><strong>Third parties or publicly available sources.</strong> We may receive personal data about you
                        from various third parties and public sources as set out below:</p>
                    <p><strong>Technical Data from the following parties:</strong></p>
                    <p>analytics providers based either inside or outside the EU and United Kingdom (UK);</p>
                    <p>advertising networks based either inside or outside the EU and UK;</p>
                    <p>search information providers based either inside or outside the EU and UK; or</p>
                    <p>third parties we contract with and/or their subsidiaries and affiliates to provide you a service
                        based either inside or outside the EU and UK</p>
                    <p>Contact, Financial and Transaction Data from providers of technical, payment and delivery services
                        based either inside or outside the EU and UK.</p>
                    <p>Identity and Contact Data from data brokers or aggregators based either inside or outside the EU and
                        UK.</p>
                    <p>Identity and Contact Data from publicly available sources based either inside or outside the EU and
                        UK.</p>
                    <p><strong>How we use your personal data</strong></p>
                    <p>• Provide our Services and create accounts in those Services</p>
                    <p> • Improve, optimize and personalize our Services and our players’ experiences</p>
                    <p> • Help you to connect with other people you know and other third-party platforms you use, including communicating
                        with other players</p>
                    <p> • Communicate with you about the Services you are using or we think you will like, including in-game updates, new
                        Services, pre-registrations and promotional marketing offers</p>
                    <p>• Customer service and managing player communications, including technical support</p>
                    <p>• Marketing, research, analytics and delivering contextual and behavioral advertising</p>
                    <p> • Administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed
                        by us or our business partners</p>
                    <p> • Protect the safety and well-being of our players and others</p>
                    <p>• Where you provide sensitive or special category personal information to us</p>
                    <p>• Maintain our business operations, including any business transition, like a merger, acquisition by another company,
                        or sale of all or part of our assets</p>
                    <p>• For security purposes and to prevent fraud or potentially illegal activities, and to enforce the applicable Terms
                        of Service</p>
                    <p> • Cooperate with public authorities and law enforcement where lawfully permitted or required</p>
                    <p>• Protect our rights, including compliance with applicable legal obligations, resolving any disputes we may have, and
                        to administer our agreements with third parties</p>
                    <p>Our legal grounds for collecting and using the information described above depends on what the information is, the
                        context in which we collect it and applicable privacy laws. Those grounds are as follows:</p>
                    <p>• Establish and perform our contract with you (i.e., the applicable Terms of Service)</p>
                    <p> • For our or our partners’ legitimate interests</p>
                    <p>• Consent (where applicable)</p>
                    <p>• Compliance with a legal obligation</p>
                    <p> • Protect your vital interests or those of another person (for example, another player)</p>
                    <p>• While we typically do not collect sensitive or special category personal information, where we do this it will be
                        on the ground of consent or other applicable lawful basis</p>
                    <p> If you have questions, need further information about the legal grounds for processing your personal information, or
                        want to withdraw your consent please see the Contact Us section belowWhere we need to perform the contract we are
                        about to enter into or have entered into with you.</p>
                    <p>Generally, we do not rely on consent as a legal basis for processing your personal data although we
                        will get your consent before sending third party direct marketing communications to you via email or
                        text message. You have the right to withdraw consent to marketing at any time by <a className="link"
                            href="mailto:privacy@virtua.com">contacting
                            us</a>.</p>
                    <h5>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</h5>
                    <p>• To make the Service work.</p>
                    <p> • To perform the contract, we process data necessary to</p>
                    <p> • Create accounts and allow you to play our games and use our Service</p>
                    <p> • Operate the Service</p>
                    <p>• Verify and confirm payments</p>
                    <p>• Provide and deliver products and services you request</p>
                    <p>• Send you Service-related communications</p>
                    <p> • To make the Service more suitable for our players.</p>
                    <p> • To provide a great Service to our players, we have a legitimate interest to collect and process necessary data to
                        update and develop player profiles</p>
                    <p> • Develop and improve the Service and player experience.</p>
                    <p> • Manage our relationship with you</p>
                    <p> • Provide social features as part of the Service</p>
                    <p>• Customize your Service experience</p>
                    <p> • Respond to your comments and questions and provide player support</p>
                    <p>• Provide you offers in the Service as well as in other websites and services (including by email)</p>
                    <p> • Send you related information, such as updates, security alerts, and support messages</p>
                    <p> • Enable you to communicate with other users</p>
                    <p>We have set out below, in a table format, a description of all the ways we plan to use your personal
                        data, and which of the legal bases we rely on to do so. We have also identified what our legitimate
                        interests are where appropriate.</p>
                    <p>Note that we may process your personal data for more than one lawful ground depending on the specific
                        purpose for which we are using your data. Please contact us if you need details about the specific
                        legal ground we are relying on to process your personal data where more than one ground has been set
                        out in the table below.</p>
                    <div className="table-responsive">
                        <table className="table">
                            <thead className='text-left'>
                                <tr>
                                    <th scope="col">Purpose/Activity</th>
                                    <th scope="col">Type of data</th>
                                    <th scope="col">Lawful basis for processing including basis of legitimate interest</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">To register you as a new customer</td>
                                    <td>
                                        <div>(a) Identity
                                            <div>
                                                <div>(b) Contact</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Performance of a contract with you</td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <div>To process and deliver your order including:</div>
                                        <div>(a) Manage payments, fees and charges</div>
                                        <div>(b) Collect and recover money owed to us</div>
                                    </td>
                                    <td>
                                        <div>(a) Identity</div>
                                        <div>(b) Contact</div>
                                        <div>(c) Financial</div>
                                        <div>(d) Transaction</div>
                                        <div>(e) Marketing and Communications</div>
                                    </td>
                                    <td>
                                        <div>(a) Performance of a contract with you</div>
                                        <div>(b) Necessary for our legitimate interests (to recover debts due to us)</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <div>To manage our relationship with you which will include:</div>
                                        <div>(a) Notifying you about changes to our terms or privacy policy</div>
                                        <div>(b) Asking you to leave a review or take a survey</div>
                                    </td>
                                    <td>
                                        <div>(a) Identity</div>
                                        <div>(b) Contact</div>
                                        <div>(c) Profile</div>
                                        <div>(d) Marketing and Communications</div>
                                    </td>
                                    <td>
                                        <div>(a) Performance of a contract with you</div>
                                        <div>(b) Necessary to comply with a legal obligation</div>
                                        <div>(c) Necessary for our legitimate interests (to keep our records updated and to
                                            study how customers use our products/services)
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">To enable you to partake in a prize draw, competition or complete a survey
                                    </td>
                                    <td>
                                        <div>(a) Identity</div>
                                        <div>(b) Contact</div>
                                        <div>(c) Profile</div>
                                        <div>(c) Usage</div>
                                        <div>(c) Marketing and Communications</div>
                                    </td>
                                    <td>
                                        <div>(a) Performance of a contract with you</div>
                                        <div>(b) Necessary for our legitimate interests (to study how customers use our
                                            products/services, to develop them and grow our business)
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">To administer and protect our business and this website (including
                                        troubleshooting, data analysis, testing, system maintenance, support, reporting and
                                        hosting of data)
                                    </td>
                                    <td>
                                        <div>(a) Identity</div>
                                        <div>(b) Contact</div>
                                        <div>(c) Technical</div>
                                    </td>
                                    <td>
                                        <div>(a) Necessary for our legitimate interests (for running our business, provision of
                                            administration and IT services, network security, to prevent fraud and in the
                                            context of a business reorganisation or group restructuring exercise)
                                        </div>
                                        <div>(b) Necessary to comply with a legal obligation</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">To deliver relevant website content and advertisements to you and measure or
                                        understand the effectiveness of the advertising we serve to you
                                    </td>
                                    <td>
                                        <div>(a) Identity</div>
                                        <div>(b) Contact</div>
                                        <div>(c) Profile</div>
                                        <div>(d) Usage</div>
                                        <div>(e) Marketing and Communications</div>
                                        <div>(f) Technical</div>
                                    </td>
                                    <td>Necessary for our legitimate interests (to study how customers use our
                                        products/services, to develop them, to grow our business and to inform our marketing
                                        strategy)
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">To use data analytics to improve our website, products/services, marketing,
                                        customer relationships and experiences
                                    </td>
                                    <td>
                                        <div>(a) Technical</div>
                                        <div>(b) Usage</div>
                                    </td>
                                    <td>Necessary for our legitimate interests (to define types of customers for our products
                                        and services, to keep our website updated and relevant, to develop our business and to
                                        inform our marketing strategy)
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">To make suggestions and recommendations to you about goods or services that
                                        may be of interest to you
                                    </td>
                                    <td>
                                        <div>(a) Identity</div>
                                        <div>(b) Contact</div>
                                        <div>(c) Technical</div>
                                        <div>(e) Profile</div>
                                        <div>(b) Usage</div>
                                        <div>(f) Marketing and Communications</div>
                                    </td>
                                    <td>Necessary for our legitimate interests (to develop our products/services and grow our
                                        business)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h5>MARKETING</h5>
                    <p>We strive to provide you with choices regarding certain personal data uses, particularly around
                        marketing and advertising.</p>
                    <p><strong>Direct Marketing by the Company</strong> If you do not wish to have your personal data used
                        by the Company to promote our own or third parties' products or services, you can opt-out by <a
                            className="link" href="mailto:privacy@virtua.com">contacting us</a>. If we have sent you a
                        promotional e-mail, you may send us a return e-mail asking to be omitted from future e-mail
                        distributions. This opt out does not apply to information provided to the Company as a result of a
                        product or service purchase, product service experience or other transactions.</p>
                    <p><strong>Targeted Advertising.</strong> If you do not want us to use information that we collect or
                        that you provide to us to deliver advertisements according to our advertisers' target-audience
                        preferences, you can opt-out by <a className="link" href="mailto:privacy@virtua.com">contacting
                            us</a>.</p>
                    <h5>Promotional offers from us</h5>
                    <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think
                        you may want or need, or what may be of interest to you. This is how we decide which products,
                        services and offers may be relevant for you (we call this marketing).</p>
                    <p>You will receive marketing communications from us if you have requested information from us or
                        purchased goods or services from us and you have not opted out of receiving that marketing.</p>
                    <h5>Third-party marketing</h5>
                    <p>We will get your express opt-in consent before we share your personal data with any third party for
                        marketing purposes.</p>
                    <h5>Opting out</h5>
                    <p>You can ask us or third parties to stop sending you marketing messages at any time by logging into
                        the website and checking or unchecking relevant boxes to adjust your marketing
                        preferences <strong>OR</strong> by following the opt-out links on any marketing message sent to
                        you <strong>OR</strong> by <a className="link" href="mailto:privacy@virtua.com">contacting us</a> at
                        any time.</p>
                    <p>Where you opt out of receiving these marketing messages, this will not apply to personal data
                        provided to us as a result of a product/service purchase, warranty registration, product/service
                        experience or other transactions.</p>
                    <h5>Cookies</h5>
                    <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or
                        access cookies. If you disable or refuse cookies, please note that some parts of this website may
                        become inaccessible or not function properly. For more information about the cookies we use, please
                        see the above para on HOW YOUR DATA IS COLLECTED: Cookies.</p>
                    <h5>Change of purpose</h5>
                    <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably
                        consider that we need to use it for another reason and that reason is compatible with the original
                        purpose. If you wish to get an explanation as to how the processing for the new purpose is
                        compatible with the original purpose, please <a className="link" href="mailto:privacy@virtua.com">contact us</a>.</p>
                    <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain
                        the legal basis which allows us to do so.</p>
                    <p>Please note that we may process your personal data without your knowledge or consent, in compliance
                        with the above rules, where this is required or permitted by law.</p>
                    <h5>Disclosures of your personal data</h5>
                    <p>We may share your personal data with the parties set out below for the purposes set out in the table
                        :Purposes for which we will use your personal data, above.</p>
                    <p>Internal Third Parties as set out in the Glossary.</p>
                    <p>External Third Parties as set out in the Glossary.</p>
                    <p>Specific third parties as listed in the table :Purposes for which we will use your personal data
                        above.</p>
                    <p>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets.
                        Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to
                        our business, then the new owners may use your personal data in the same way as set out in this
                        privacy policy.</p>
                    <p>We require all third parties to respect the security of your personal data and to treat it in
                        accordance with the law. We do not allow our third-party service providers to use your personal data
                        for their own purposes and only permit them to process your personal data for specified purposes and
                        in accordance with our instructions.</p>
                    <h5>International transfers</h5>
                    <p>We share your personal data within the Company. This will involve transferring your data outside the
                        European Economic Area (EEA).</p>
                    <p>We ensure your personal data is protected by requiring all our group companies to follow the same
                        rules when processing your personal data.</p>
                    <p>Many of our external third parties are based outside the European Economic Area (EEA) so their
                        processing of your personal data will involve a transfer of data outside the EEA.</p>
                    <p>Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is
                        afforded to it by ensuring at least one of the following safeguards is implemented:</p>
                    <p><strong>•</strong> We will only transfer your personal data to countries that have been deemed to
                        provide an adequate level of protection for personal data by the European Commission.</p>
                    <p><strong>•</strong> Where we use certain service providers, we may use specific contracts which give
                        personal data the same protection it has in Europe.</p>
                    <p><strong>•</strong> Where we use providers based in the US, we may transfer data to them if they are
                        part of the Privacy shield which requires them to provide similar protection to personal data shared
                        between Europe and the US.</p>
                    <p>Please <a className="link" href="mailto:privacy@virtua.com">contact us</a> if you want further
                        information on the specific mechanism used by us when transferring your personal data out of the
                        EEA.</p>
                    <h5>Data security</h5>
                    <p>We have put in place appropriate security measures to prevent your personal data from being
                        accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we
                        limit access to your personal data to those employees, agents, contractors and other third parties
                        who have a business need to know. They will only process your personal data on our instructions and
                        they are subject to a duty of confidentiality.</p>
                    <p>We have put in place procedures to deal with any suspected personal data breach and will notify you
                        and any applicable regulator of a breach where we are legally required to do so.</p>
                    <h5>Data retention</h5>
                    <h5>How long will you use my personal data for?</h5>
                    <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we
                        collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or
                        reporting requirements. We may retain your personal data for a longer period in the event of a
                        complaint or if we reasonably believe there is a prospect of litigation in respect to our
                        relationship with you.</p>
                    <p>To determine the appropriate retention period for personal data, we consider the amount, nature and
                        sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of
                        your personal data, the purposes for which we process your personal data and whether we can achieve
                        those purposes through other means, and the applicable legal, regulatory, tax, accounting or other
                        requirements.</p>
                    <p>In some circumstances you can ask us to delete your data: see your legal rights below for further
                        information.</p>
                    <p>In some circumstances we will anonymise your personal data (so that it can no longer be associated
                        with you) for research or statistical purposes, in which case we may use this information
                        indefinitely without further notice to you.</p>
                    <h5>Your legal rights</h5>
                    <p>Under certain circumstances, you have rights under data protection laws in relation to your personal
                        data:</p>
                    <p><em>Request access to your personal data.</em></p>
                    <p><em>Request correction of your personal data.</em></p>
                    <p><em>Request erasure of your personal data.</em></p>
                    <p><em>Object to processing of your personal data.</em></p>
                    <p><em>Request restriction of processing your personal data.</em></p>
                    <p><em>Request transfer of your personal data.</em></p>
                    <p><em>Right to withdraw consent.</em></p>
                    <p>If you wish to exercise any of the rights set out above, please contact us as follows: <a
                        className="link" href="mailto:privacy@virtua.com">privacy@virtua.com</a>.</p>
                    <p>No fee usually required</p>
                    <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights).
                        However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or
                        excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
                    <p>What we may need from you</p>
                    <p>We may need to request specific information from you to help us confirm your identity and ensure your
                        right to access your personal data (or to exercise any of your other rights). This is a security
                        measure to ensure that personal data is not disclosed to any person who has no right to receive it.
                        We may also contact you to ask you for further information in relation to your request to speed up
                        our response.</p>
                    <p>Time limit to respond</p>
                    <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer
                        than a month if your request is particularly complex or you have made a number of requests. In this
                        case, we will notify you and keep you updated.</p>
                    <h5>Glossary</h5>
                    <h5>LAWFUL BASIS</h5>
                    <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing
                        our business to enable us to give you the best service/product and the best and most secure
                        experience. We make sure we consider and balance any potential impact on you (both positive and
                        negative) and your rights before we process your personal data for our legitimate interests. We do
                        not use your personal data for activities where our interests are overridden by the impact on you
                        (unless we have your consent or are otherwise required or permitted to by law). You can obtain
                        further information about how we assess our legitimate interests against any potential impact on you
                        in respect of specific activities by contacting us.</p>
                    <p><strong>Performance of Contract</strong> means processing your data where it is necessary for the
                        performance of a contract to which you are a party or to take steps at your request before entering
                        into such a contract.</p>
                    <p><strong>Comply with a legal obligation</strong> means processing your personal data where it is
                        necessary for compliance with a legal obligation that we are subject to.</p>
                    <h5>THIRD PARTIES</h5>
                    <p>Internal Third Parties</p>
                    <p>Other companies in the TERRA VIRTUA LTD Group acting as joint controllers or processors and who
                        provide IT and system administration services and undertake leadership reporting.</p>
                    <p>External Third Parties</p>
                    <p>Service providers acting as processors who provide IT and system administration services.</p>
                    <p>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors
                        and insurers who provide consultancy, banking, legal, insurance and accounting services.</p>
                    <p>HM Revenue &amp; Customs, regulators and other authorities acting as processors or joint controllers
                        who require reporting of processing activities in certain circumstances.</p>
                    <p>Professional market researchers, price comparison sites acting as processors or joint controllers who
                        require reporting of processing activities in certain circumstances</p>
                    <h5>YOUR LEGAL RIGHTS</h5>
                    <p>You have the right to:</p>
                    <p><strong>Request access</strong> to your personal data (commonly known as a "data subject access
                        request"). This enables you to receive a copy of the personal data we hold about you and to check
                        that we are lawfully processing it.</p>
                    <p><strong>Request correction</strong> of the personal data that we hold about you. This enables you to
                        have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the
                        accuracy of the new data you provide to us.</p>
                    <p><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or
                        remove personal data where there is no good reason for us continuing to process it. You also have
                        the right to ask us to delete or remove your personal data where you have successfully exercised
                        your right to object to processing (see below), where we may have processed your information
                        unlawfully or where we are required to erase your personal data to comply with local law. Note,
                        however, that we may not always be able to comply with your request of erasure for specific legal
                        reasons which will be notified to you, if applicable, at the time of your request.</p>
                    <p><strong>Object to processing</strong> of your personal data where we are relying on a legitimate
                        interest (or those of a third party) and there is something about your particular situation which
                        makes you want to object to processing on this ground as you feel it impacts on your fundamental
                        rights and freedoms. You also have the right to object where we are processing your personal data
                        for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate
                        grounds to process your information which override your rights and freedoms.</p>
                    <p><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us
                        to suspend the processing of your personal data in the following scenarios:</p>
                    <p>If you want us to establish the data's accuracy.</p>
                    <p>Where our use of the data is unlawful but you do not want us to erase it.</p>
                    <p>Where you need us to hold the data even if we no longer require it as you need it to establish,
                        exercise or defend legal claims.</p>
                    <p>You have objected to our use of your data but we need to verify whether we have overriding legitimate
                        grounds to use it.</p>
                    <p><strong>Request the transfer</strong> of your personal data to you or to a third party. We will
                        provide to you, or a third party you have chosen, your personal data in a structured, commonly used,
                        machine-readable format. Note that this right only applies to automated information which you
                        initially provided consent for us to use or where we used the information to perform a contract with
                        you.</p>
                    <p><strong>Withdraw consent at any time</strong> where we are relying on consent to process your
                        personal data. However, this will not affect the lawfulness of any processing carried out before you
                        withdraw your consent. If you withdraw your consent, we may not be able to provide certain products
                        or services to you. We will advise you if this is the case at the time you withdraw your consent.
                    </p>
                    <p><strong>Details of the third-party Service providers to monitor and analyze the use of our
                        Service.</strong></p>
                    <h5>Information from Third-Party Social Media Services</h5>
                    <p>The Company allows You to create an account and log in to use the Service through the following
                        Third-party Social Media Services:</p>
                    <p><strong>•</strong> Google</p>
                    <p><strong>•</strong> Facebook</p>
                    <p><strong>•</strong> Twitter</p>
                    <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service,
                        We may collect Personal data that is already associated with Your Third-Party Social Media Service's
                        account, such as Your name, Your email address, Your activities or Your contact list associated with
                        that account.</p>
                    <p>You may also have the option of sharing additional information with the Company through Your
                        Third-Party Social Media Service's account. If You choose to provide such information and Personal
                        Data, during registration or otherwise, You are giving the Company permission to use, share, and
                        store it in a manner consistent with this Privacy Policy.</p>
                    <h5>Google Analytics</h5>
                    <p>Google Analytics is a web analytics service offered by Google that tracks and reports website
                        traffic. Google uses the data collected to track and monitor the use of our Service. This data is
                        shared with other Google services. Google may use the collected data to contextualize and
                        personalize the ads of its own advertising network.</p>
                    <p>You can opt-out of having made your activity on the Service available to Google Analytics by
                        installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics
                        JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about
                        visits activity.</p>
                    <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms
                        web page: <a className="link" href="https://policies.google.com/privacy" target="_blank"
                            rel="noopener"><em>https://policies.google.com/privacy</em></a></p>
                    <h5>Behavioral Remarketing</h5>
                    <p>The Company uses remarketing services to advertise to You after You accessed or visited our Service.
                        We and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your
                        Device and understand how You use our Service so that We can improve our Service to reflect Your
                        interests and serve You advertisements that are likely to be of more interest to You.</p>
                    <p>These third-party vendors collect, store, use, process and transfer information about Your activity
                        on Our Service in accordance with their Privacy Policies and to enable Us to:</p>
                    <p><strong>•</strong> Measure and analyze traffic and browsing activity on Our Service</p>
                    <p><strong>•</strong> Show advertisements for our products and/or services to You on third-party
                        websites or apps</p>
                    <p><strong>•</strong> Measure and analyze the performance of Our advertising campaigns</p>
                    <p>Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser
                        settings that block cookies. Your browser may not permit You to block such technologies. You can use
                        the following third-party tools to decline the collection and use of information for the purpose of
                        serving You interest-based advertising:</p>
                    <p>The NAI's opt-out platform: <a className="link" href="http://www.networkadvertising.org/choices/"
                        target="_blank"
                        rel="noopener"><em>http://www.networkadvertising.org/choices/</em></a> The
                        EDAA's opt-out platform: <a className="link" href="http://www.youronlinechoices.com/"
                            target="_blank"
                            rel="noopener"><em>http://www.youronlinechoices.com/</em></a> The DAA's
                        opt-out platform: <a className="link" href="http://optout.aboutads.info/?c=2&amp;lang=EN"
                            target="_blank"
                            rel="noopener"><em>http://optout.aboutads.info/?c=2&amp;lang=EN</em></a></p>
                    <p>You may opt-out of all personalized advertising by enabling privacy features on Your mobile device
                        such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device
                        Help system for more information.</p>
                    <p>We may share information, such as hashed email addresses (if available) or other online identifiers
                        collected on Our Service with these third-party vendors. This allows Our third-party vendors to
                        recognize and deliver You ads across devices and browsers. To read more about the technologies used
                        by these third-party vendors and their cross-device capabilities please refer to the Privacy Policy
                        of each vendor listed below.</p>
                    <h5>The third-party vendors We use are:</h5>
                    <h5>Google Ads (AdWords)</h5>
                    <p>Google Ads (AdWords) remarketing service is provided by Google Inc. You can opt-out of Google
                        Analytics for Display Advertising and customise the Google Display Network ads by visiting the
                        Google Ads Settings page: <a className="link" href="http://www.google.com/settings/ads"
                            target="_blank"
                            rel="noopener"><em>http://www.google.com/settings/ads</em></a> Google
                        also recommends installing the Google Analytics Opt-out Browser Add-on <a className="link"
                            href="https://tools.google.com/dlpage/gaoptout"
                            target="_blank"
                            rel="noopener"><em>https://tools.google.com/dlpage/gaoptout</em></a> -
                        for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to
                        prevent their data from being collected and used by Google Analytics. For more information on the
                        privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a
                            className="link" href="https://policies.google.com/privacy" target="_blank"
                            rel="noopener"><em>https://policies.google.com/privacy</em></a></p>
                    <h5>Bing Ads Remarketing</h5>
                    <p>Bing Ads remarketing service is provided by Microsoft Inc. You can opt-out of Bing Ads interest-based
                        ads by following their instructions: <a className="link"
                            href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
                            target="_blank"
                            rel="noopener"><em>https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</em></a> You
                        can learn more about the privacy practices and policies of Microsoft by visiting their Privacy
                        Policy page: <a className="link" href="https://privacy.microsoft.com/en-us/PrivacyStatement"
                            target="_blank"
                            rel="noopener"><em>https://privacy.microsoft.com/en-us/PrivacyStatement</em></a></p>
                    <h5>Twitter</h5>
                    <p>Twitter remarketing service is provided by Twitter Inc. You can opt-out from Twitter's interest-based
                        ads by following their instructions: <a className="link"
                            href="https://support.twitter.com/articles/20170405"
                            target="_blank"
                            rel="noopener"><em>https://support.twitter.com/articles/20170405</em></a> You
                        can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy
                        page: <a className="link" href="https://twitter.com/privacy" target="_blank"
                            rel="noopener"><em>https://twitter.com/privacy</em></a></p>
                    <h5>Facebook</h5>
                    <p>Facebook remarketing service is provided by Facebook Inc. You can learn more about interest-based
                        advertising from Facebook by visiting this page: <a className="link"
                            href="https://www.facebook.com/help/164968693837950"
                            target="_blank"
                            rel="noopener"><em>https://www.facebook.com/help/164968693837950</em></a> To
                        opt-out from Facebook's interest-based ads, follow these instructions from Facebook: <a
                            className="link" href="https://www.facebook.com/help/568137493302217" target="_blank"
                            rel="noopener"><em>https://www.facebook.com/help/568137493302217</em></a> Facebook adheres to
                        the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital
                        Advertising Alliance. You can also opt-out from Facebook and other participating companies through
                        the Digital Advertising Alliance in the USA <a className="link"
                            href="http://www.aboutads.info/choices/"
                            target="_blank"
                            rel="noopener"><em>http://www.aboutads.info/choices/</em></a>,
                        the Digital Advertising Alliance of Canada in Canada <a className="link"
                            href="http://youradchoices.ca/"
                            target="_blank"
                            rel="noopener"><em>http://youradchoices.ca/</em></a> or
                        the European Interactive Digital Advertising Alliance in Europe <a className="link"
                            href="http://www.youronlinechoices.eu/"
                            target="_blank"
                            rel="noopener"><em>http://www.youronlinechoices.eu/</em></a> ,
                        or opt-out using your mobile device settings. For more information on the privacy practices of
                        Facebook, please visit Facebook's Data Policy: <a className="link"
                            href="https://www.facebook.com/privacy/explanation"
                            target="_blank"
                            rel="noopener"><em>https://www.facebook.com/privacy/explanation</em></a>
                    </p>
                    <h5>Pinterest</h5>
                    <p>Pinterest remarketing service is provided by Pinterest Inc. You can opt-out from Pinterest's
                        interest-based ads by enabling the ;Do Not Track; functionality of your web browser or by following
                        Pinterest instructions:
                        <a className="link"
                            href="http://help.pinterest.com/en/articles/personalization-and-data"
                            target="_blank"
                            rel="noopener"><em>http://help.pinterest.com/en/articles/personalization-and-data &nbsp;</em> </a>
                        You can learn more about the privacy practices and policies of Pinterest by visiting their Privacy
                        Policy page: <a className="link" href="https://about.pinterest.com/en/privacy-policy"
                            target="_blank"
                            rel="noopener"><em>https://about.pinterest.com/en/privacy-policy</em></a></p>
                    <p> - Game analytics -
                        <a className="link" href="https://gameanalytics.com/privacy;"
                            target="_blank"
                            rel="noopener"><em>https://gameanalytics.com/privacy</em>
                        </a>
                    </p>
                    <p> - Google analytics -
                        <a className="link" href="https://www.google.com/analytics/terms/us.html"
                            target="_blank"
                            rel="noopener"><em>https://www.google.com/analytics/terms/us.html</em>
                        </a>
                    </p>
                    <p> - Google partners -
                        <a className="link" href="http://www.google.com/intl/en/policies/privacy/partners/"
                            target="_blank"
                            rel="noopener"><em>http://www.google.com/intl/en/policies/privacy/partners/</em>
                        </a>
                    </p>
                    <p> - Firebase -
                        <a className="link" href="https://firebase.google.com/support/privacy/"
                            target="_blank"
                            rel="noopener"><em>https://firebase.google.com/support/privacy/</em>
                        </a>
                    </p>
                    <p> - Admob -
                        <a className="link" href="https://support.google.com/admob/answer/7665968"
                            target="_blank"
                            rel="noopener"><em>https://support.google.com/admob/answer/7665968</em>
                        </a>
                    </p>
                    <p> - Admob ad technology providers -
                        <a className="link" href="https://support.google.com/dfp_premium/answer/9012903"
                            target="_blank"
                            rel="noopener"><em>https://support.google.com/dfp_premium/answer/9012903</em>
                        </a>
                    </p>
                    <h5> HOW DO WE PROTECT YOUR DATA</h5>
                    <p> • Security Safeguards.</p>
                    <p> In order to help ensure a secure and safe player experience, we are continuously developing and implementing
                        administrative, technical and physical security measures to protect your data from unauthorized access or against
                        loss, misuse or alteration.</p>
                    <p> • Data retention.</p>
                    <p>We retain your data for as long as your account is active or as needed to provide you the Service. We may for example
                        periodically de-identify unused game accounts, and we may regularly review and de-identify unnecessary data.</p>
                    <p>Note that if you ask us to remove your personal data, we will retain your data as necessary for our legitimate
                        business interests, such as to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                    <p> • CHILDREN</p>
                    <p>Protecting children’s privacy online is very important to us. Generally, our Services are not directed to children
                        under 13 (or other age as required by local law), and the Services do not knowingly collect personal data from
                        children, except as described in the following paragraph.</p>
                    <p>For games that are not directed at children but nonetheless may appeal to them, we take additional steps to limit
                        access to these Services based on the player’s age. For these Services, when a player indicates they are under 13
                        (or the applicable age in their territory), we will limit their access to certain features, such as social media and
                        chat features, as well as certain types of notification alerts. We also limit the processing of personal data to
                        only what is necessary to support the internal operations of our Services.</p>
                    <p> Please note that for some of our Services we may use a higher age limit (such as 16) than described in the above two
                        paragraphs. We do this to provide a safe game experience for our players and to protect young players.</p>
                    <p> If you are a parent or guardian and wish to review personal data collected from your child, or have that information
                        modified or deleted, you may contact us as described above. If we become aware that a child has provided us with
                        personal data contrary to the above, we will delete any personal data we have collected, unless we have a legal
                        obligation to keep it, and terminate the child’s account and/or revert them to the underage experience, as
                        applicable</p>
                </div>
            </Container>
        </>
    );
};
