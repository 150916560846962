import "./collection.scss"
import React from "react";
import Button from "../../components/generic/button";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { Para, SubTitle } from "../../components/styledComponent";
import styled from "styled-components";
const GradientEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  transform: rotate(180deg);
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;

const Collection = () => {
  return (
    <>
      <section className="relative collection">
        <img
          className="bg-img web"
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}collection-bg.jpg`}
          alt="collection" />
        <div className="main-holder">
          <div className="content">
            <SubTitle left upper gYN>
              Our Collections
            </SubTitle>
          </div>
          <div className="cardlisting">
            <div className="d-grid">
              <div className="custom-col-width">
                <div className="card">
                  <div className="card-img">
                    <img
                      className="img"
                      src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}landbot.png`}
                      alt="landbot" />
                  </div>
                  <div className="card-content">
                    <div className="wrap">
                      <SubTitle>Cardano Island Landbots</SubTitle>
                      <Para>Land Bots are native to Virtua, and share a deep connection to our metaverse and enable holders to harness resources.</Para>
                    </div>
                    <Button
                      secondary
                      as="a"
                      target="_blank"
                      href="https://www.jpg.store/collection/virtuacardanoislandminerbots?tab=items"
                    >
                      Visit Collection Now
                    </Button>
                  </div>
                </div>
              </div>
              <div className="custom-col-width">
                <div className="card">
                  <div className="card-img">
                    <img
                      className="img"
                      src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vflect.png`}
                      alt="vflect" />
                  </div>
                  <div className="card-content">
                    <div className="wrap">
                      <SubTitle>Cardano Island vFlects</SubTitle>
                      <Para>Unveiling the Cardano vFlects, the first of their kind to land on Cardano Island and explore the metaverse.</Para>
                    </div>
                    <Button
                      secondary
                      as="a"
                      target="_blank"
                      href="https://www.jpg.store/collection/virtuacardanoislandvflects?tab=items"
                    >
                      Visit Collection Now
                    </Button>
                  </div>
                </div>
              </div>
              <div className="custom-col-width">
                <div className="card">
                  <div className="card-img">
                    <img
                      className="img"
                      src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vehicles.png`}
                      alt="vehicles" />
                  </div>
                  <div className="card-content">
                    <div className="wrap">
                      <SubTitle>Cardano Island Vehicles</SubTitle>
                      <Para>With the rise of the first crypto island on Virtua Prime, the community will get their hands on exclusive vehicles!</Para>
                    </div>
                    <Button
                      secondary
                      as="a"
                      target="_blank"
                      href="https://www.jpg.store/collection/virtuacardanoislandvehicles?tab=items"
                    >
                      Visit Collection Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Collection;