import "./VirtuaGamesNetwork.scss"
import React from "react";
import { Video } from "../hero-section/HeroSectionStyled";
import { Para, SectionHeading, SubTitle } from "../../../components/styledComponent";
import Button from "../../../components/generic/button";
import styled from "styled-components";
import { REACT_APP_S3_CLOUDFLARE_ASSETS_URL, REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import { VGN } from "../../../enviroment";

const GradientEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  transform: rotate(180deg);
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;

const VirtuaGamesNetwork = () => {
  return (
    <>
      <section className="relative virtua-games-network gradient-effect" id="virtua-games-network">
        {/* <GradientEffect /> */}
        <img
          className="bg-img web"
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtuagamesWeb.jpg`}
          alt="virtuagamesWeb" />
        <img
          className="bg-img mob"
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtuagamesMob.jpg`}
          alt="virtuagamesMob" />
        <div className="main-content">
          <SectionHeading>Virtua Games Network</SectionHeading>
          <Para maxWidth>Welcome to the new world of gaming! Join the Virtua Games Network to play and experience our ever-growing roster of fun games.</Para>
          <Button upper secondary target="_blank" as="a" href={VGN} >
            Start Playing Now!
          </Button>
        </div>
      </section>
    </>
  );
};

export default VirtuaGamesNetwork;