import React from "react";

export default function Viewer({ id }) {
  const makeMobileUrlFrom = (id) => {
    let url =
      "https://sketchfab.com/models/" +
      id +
      "/embed?autostart=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_annotations=0&orbit_constraint_zoom_out=2";
    return url;
  };

  return (
    <div
      className="sketchfab-embed-wrapper"
      style={{ height: "100%", width: "100%" }}
    >
      <iframe
        id="player"
        className="sketchfab-holder"
        src={makeMobileUrlFrom(id)}
        style={{ height: "100vh", width: "100%" }}
        frameborder="0"
        allowFullScreen
        mozallowfullscreen
        webkitallowfullscreen
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
        allow="autoplay; fullscreen; xr-spatial-tracking"
        xr-spatial-tracking="true"
        execution-while-out-of-viewport="true"
        execution-while-not-rendered="true"
        web-share="true"
      ></iframe>
    </div>
  );
}
