import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import "./VoteTvk.scss";
import Button from "../../components/generic/button";
import { STAKE } from "../../enviroment";
import { useLocation } from "react-router";
import { REACT_APP_S3_VOTE_IMAGE_URL, REACT_APP_VIDEO_ASSETS_URL } from "../../constants/constant";
function VoteTvk() {
  const [activeId, setActiveiD] = useState('')
  const { hash } = useLocation()
  useEffect(() => {
    if (hash) {
      setActiveiD(hash === '#faq38' ? 'faq_38' : 'faq_1')
    }
    else { setActiveiD('faq_1') }
  }, [hash])
  return (
    <>
      <Helmet>
        <title>Virtua - TVK Vote</title>
        <meta name="description" content="All you need to know to start voting for the all new L1 Blockchain." />
        <meta name="keywords" content="blockchain, crypto, currency, block, chain, trading, online, metaverse, virtua, NFT, network, TVK, Stake, Staking" />
      </Helmet>
      <div className="vote-tvk">
        <div className="mainBanner">
          <div className="relative">
            <div className="container">
              <video poster={`${REACT_APP_S3_VOTE_IMAGE_URL}/voting-banner-bg.png`} autoPlay loop muted playsInline>
                <source
                  src={`${REACT_APP_VIDEO_ASSETS_URL}voting-banner.mp4`}
                  type="video/mp4"
                />
              </video>
              <div className="txt-holder">
                <h2 className="section-title">
                  Virtua Community Vote
                </h2>
                <div className="btns">
                  <a href={STAKE} target="_self" className="custom-btn">Unstake Your Tokens</a>
                  <a href={process.env.REACT_APP_VANARCHAIN_URL} target="_blank" rel="noreferrer" className="custom-btn dark">Explore VANAR</a>
                </div>
                {/* <p className="section-detail">
                  Vote has now closed - Result will be announced on <a className="link" href="https://twitter.com/VirtuaMetaverse" target="_blank" rel="noreferrer">Twitter</a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="voting-content">
          <div className="text">
            <h1 className="title">Overview</h1>
            <p>Since 2018 Virtua has established itself as a key player in the web3 entertainment space. We have pioneered metaverse adoption, mixed reality, brand NFTs and partnerships with brands including Paramount, Legendary, Kevin Hart, CAA and many gaming brands. We have also been at the forefront of developing new products and tools which are already used within Virtua. We have created and pioneered metaverse spaces, XR, publishing tools, mobile apps, web3 games and marketplaces with TVK serving as a utility token.</p>
            <p>The limitation of TVK has always been the growth of the metaverse sector, NFT adoption in general and the limitations of other chains. </p>
            <p>Many of our partners and entertainment brands we have discussed with over the years want to create their own bespoke experiences, control their own customer journeys and then add metaverse and other upsells in a way that works for them, outside of being part of the Virtua platform. Similarly for our game partners, they are being pushed to use chains that don't necessarily work for them and deal with the associated friction. We want to address this with all the technologies we have developed.</p>
            <p>Through our working with brands over many years, we have seen numerous issues and we want to address the challenges web3 adoption in entertainment faces. Our learnings working with brands over the last 5 years have shown us what are key friction points holding back adoption. Concerns are not limited to but include</p>
            <ul className="list-none">
              <li>- Confusing tools</li>
              <li>- High transaction costs</li>
              <li>- Fragmented marketplaces and brand journeys</li>
              <li>- Friction using wallets </li>
              <li>- Environmental concerns </li>
            </ul>
          </div>
          <div className="text">
            <h1 className="title">Our Proposal</h1>
            <p className="fw-600">We are seeking to create a L1 blockchain that is ultra low cost, which supports microtransactions, focused on billions of entertainment consumers. A blockchain designed by us from the ground up, bringing together all the technologies we have built,  to make sense for real world adoption. We will  base our technology approach on our years of experience working with Games, entertainment and brands. Our focus is on bringing billions of consumers to web3.</p>
            <p>This will massively expand and upgrade the use of TVK to a native blockchain, with a much wider-encompassing mission. TVK will evolve to a new token with a new branded blockchain. We have already discussed the proposed changes with our existing and new brand, corporate and gaming  partners and other key stakeholders - they are excited and ready to participate. </p>
            <p>The wider web3 space is hugely focused on infrastructure products. TVK has the resources to compete and position against most of the top 100 assets, which are chains who are slowly developing. Virtua already has tooling, and a product suite suitable for its core audience and a large development team. Being an L1 chain is a key means to position what Virtua is for a more mainstream audience.</p>
            <p className="fw-600">Our TVK community is the beating heart of Virtua - and this proposal seeks the community's support for this exciting new direction. This proposal is open for a vote for holders of TVK.</p>
            <p>The following sections expand key elements of this proposal.</p>
          </div>
          <div className="text">
            <h1 className="title">The New Blockchain</h1>
            <h2 className="heading">A New Blockchain Technology Approach</h2>
            <p>We are proposing to create a L1 blockchain that is ultra low cost, focused on billions of entertainment consumers. Based on our years of experience working with Games, entertainment and brands, our technology approach focuses on bringing mainstream consumers to web3.</p>
            <p>Current blockchain solutions address certain elements of the solution but not in a holistic way. We want to change the technology approach where there is not only a single point of infrastructure but also brand solutions, while also addressing the key concerns outlined earlier in this overview.</p>
            <p>We have also already discussed with numerous corporations that work in publishing, finance, and enterprise sectors with over 300 million consumers globally. They are all interested in this new model and the technology approach proposed. </p>
            <p>Virtua has the advantage of an amazing track record working with major brands which has cultivated their trust. The Virtua team has already transformed and helped mainstream a niche sector into something valued by the mainstream - this will be further enhanced by becoming a Layer 1 blockchain which is a more fundamental and evergreen building block of web3.</p>
            <p className="fw-600">The ethos of this new chain would be - Consumer Ready. Developer Friendly. Trusted by Brands</p>
            <h2 className="heading">New Blockchain Features</h2>
            <p>The features we are proposing for the L1</p>
            <ul className="list-none">
              <li>-  Fixed fees</li>
              <li>-  Zero transaction cost option</li>
              <li>-  EVM compatible</li>
              <li>-  Dapps as a service ecosystem </li>
              <li>-  API Integrations for many platforms</li>
              <li>-  A complete ecosystem spanning Metaverse, Gaming, Brands, AI and XR</li>
              <li>-  Tools for 3rd party integrations with a number of partners</li>
            </ul>
          </div>
          <div className="text">
            <h1 className="title">THE CORE TEAM</h1>
            <div>
              <div className="profile">
                <div className="profile-img">
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/jawadAshraf.png`}
                    alt="jawadAshraf" />
                </div>
                <div className="profile-text">
                  <p className="user-name">Jawad Ashraf</p>
                  <p className="designation">CEO</p>
                </div>
              </div>
              <p>A technology innovation focused entrepreneur, over a period of 30 years. Led the technical transformation of the Entertainer Dubai, leading to a 100m USD exit. He has founded multiple businesses and has successfully exited each one. He has always worked on leading (bleeding) edge technology solutions, including Kidnap and Ransom, Anti Terrorism, Energy Trading, Mobile Gaming and Virtual Reality. Jawad has deep experience in mobile gaming and VR, AR and blockchain technologies. He is an award-winning speaker on web3 adoption and the metaverse. Co-founder of Virtua, one of the top gaming metaverse and NFT marketplaces in web3.</p>
            </div>
            <div>
              <div className="profile">
                <div className="profile-img">
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/garyBracey.png`}
                    alt="garyBracey" />
                </div>
                <div className="profile-text">
                  <p className="user-name">Gary Bracey</p>
                  <p className="designation">Chairman</p>
                </div>
              </div>
              <p>A BAFTA-nominated Games industry veteran for 35+ years. From Head of Development of Ocean Software to the Founder of Digimask, Gary has an ability to identify and bring digital products to life. Having been involved in every transition of the videogames cycle, from home computer to console to mobile to VR, Bracey has demonstrated an ability to identify and bring to market successful games, not only for the Western territories but Asia also. Co-founder of Virtua, one of the top gaming metaverse and NFT marketplaces in web3.</p>
            </div>
            <div>
              <div className="profile">
                <div className="profile-img">
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/dougDyer.png`}
                    alt="dougDyer" />
                </div>
                <div className="profile-text">
                  <p className="user-name">Doug Dyer</p>
                  <p className="designation">COO</p>
                </div>
              </div>
              <p>Doug has funded, started, and built successful companies, and been involved in the development and launch of over 100 games over his 25+ year career. He has also worked with the world's largest IPs and brand owners including Age of Empires, WWE, Warner Mobile and THQ. He has a broad background in digital, social, and mobile game publishing, business development, product development, blockchain/NFT development, licensing, and more.</p>
            </div>
            <div>
              <div className="profile">
                <div className="profile-img">
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/ashMohammed.png`}
                    alt="ashMohammed" />
                </div>
                <div className="profile-text">
                  <p className="user-name">Ash Mohammed</p>
                  <p className="designation">CSO</p>
                </div>
              </div>
              <p>Ash is a strategist with more than two decades of expertise with Fortune 500 companies.  He is widely recognized for his proficiency in educating web2 brands about the opportunities of the metaverse and formulating their initiatives in web3 and has led many brand partnerships into web3 across sports, entertainment and music. Notable partnerships have included Kevin Hart, Shelby American, Paramount pictures, Legendary entertainment and more.</p>
            </div>
            <div>
              <div className="profile">
                <div className="profile-img">
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/anisChohan.png`}
                    alt="anisChohan" />
                </div>
                <div className="profile-text">
                  <p className="user-name">Anis Chohan</p>
                  <p className="designation">CTO</p>
                </div>
              </div>
              <p>Anis Chohan is a seasoned Enterprise Solution Architect with a distinguished background in Digital Experience Platforms (DXP), notably Sitecore. His profound expertise has benefited global brands like HSBC, Stanley Black & Decker, Wacom, and ASOS. Beyond his work with these marquee names, Anis has also co-founded and helped launch several blockchain projects. He has since dedicated his full-time efforts to Virtua, where he serves as the Chief Technology Officer, steering the company's technological direction with precision and vision.</p>
            </div>
            <div>
              <div className="profile">
                <div className="profile-img">
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/davidAtkinson.png`}
                    alt="davidAtkinson" />
                </div>
                <div className="profile-text">
                  <p className="user-name">David Atkinson</p>
                  <p className="designation">Ecosystem Development</p>
                </div>
              </div>
              <p>David is a renowned blockchain economy expert and has been an investor and founder with multiple exits. He is also known for being commercial director and one of the earliest team members of  Holochain. David is also ex-COO of Mind Gym, a behavioral change consultancy with a market cap of £150m, helped it jump to a 3x revenue in his 4 years. Advised more than 50 businesses since 2017 in both western and eastern markets including DONG energy.</p>
            </div>
          </div>
          <div className="text">
            <h1 className="title">New Ecosystem Mechanics</h1>
            <h2 className="heading-2">What Are The Benefits For TVK Holders?</h2>
            <p>Having a new layer 1 chain brings a host of benefits for TVK holders - it will move TVK from a metaverse utility token to a token which powers an entire blockchain with the Virtua metaverse, VGN games and our brand partners being our first adopters. This also positions TVK to be able to compete with web3’s top 100 which are all predominantly layer 1 technologies. </p>
            <p className="under-line">This means that TVK (or the new L1 token) is no longer limited to people who only want to use Virtua products, they can build their own products with the technologies we have developed.</p>
            <h2 className="heading-2">What Additional Features Would A New L1 Token Have Over TVK? </h2>
            <p>TVK holders will be able to use the new token to;</p>
            <ul className="list-none">
              <li>-  Become a gas token, allowing TVK to reach mass distribution as all end-point corporate users would need some TVK at minimum to host nodes</li>
              <li>-  Get validator rewards</li>
              <li>-  Participate in votes</li>
              <li>-  Get staking rewards</li>
              <li>-  Core component for dApps</li>
              <li>-  Underpin the Virtua metaverse for trading, resource, crafting</li>
              <li>-  Liquidity Asset: trading of assets with the new token</li>
            </ul>
            <p className="under-line">So rather than TVK being a utility token for Virtua, the new token becomes the engine for an entire blockchain ecosystem.</p>
            <h2 className="heading-2">Will There Be A Supply Increase?</h2>
            <p>There will be new tokens issued because a blockchain ecosystem is much wider. The new supply will be for validator rewards, development rewards and airdrops. There are no new team tokens.  Additional tokens will essentially be distributed to holders as they will be offered as validator rewards.</p>
            <p className="under-line">We plan to expand token supply from the current 1.2 billion to 2.4 billion over a 20 year period to support this ecosystem growth. The additional supply would be unlocked over a 20-year period, with the supply of tokens growing at an average of 3.5% per year. This average rate is significantly lower than the inflation rate of many assets in the market.</p>
            <p>The 20 year time horizon ensures there is huge room for growth in users to offset any supply pressures. These changes will support the long-term sustainability and growth of the ecosystem and the much broader mission. Distribution of tokens will be allocated as follows:</p>
            <ul className="list-none">
              <li>-  83% of the new tokens will be dedicated to validator rewards</li>
              <li>-  13% to development rewards</li>
              <li>-  4% to airdrops and other community incentives.</li>
              <li>-  No team tokens will be allocated</li>
            </ul>
            <h2 className="heading-2">How Would A Token Swap Work?</h2>
            <p>The token swap would be 1 to 1 with $TVK to a new token. So if you hold 10,000 TVK you will receive 10,000 of the new token.</p>
            <p>The token upgrade details, including supporting platforms and mechanics, will be shared if the proposal passes. TVK token holders will have ample time and options to upgrade their TVK if the vote passes.</p>
          </div>
          <div className="text">
            <h1 className="title">VIRTUA AND BRAND SUPPORT</h1>
            <h2 className="heading-2">Are Any Of Your Brand Partners Supporting This Change?</h2>
            <p>Yes, all our brand partners are excited to be able to build their own ecosystems and create new activations with our approach. </p>
            <h2 className="heading-2">Why are Brands Supporting This Direction?</h2>
            <p>An analogy of the current market situations for brands is like Nike launching their own sneakers but only selling them on eBay, or being forced to another platform for any type of web3 experience - they want to be able to control it. We want to change this, allowing entertainment to connect with their audience in a way that works for them.</p>
            <p>A blockchain which is also linked to a suite of solutions, provides a single point that deals with the multiple problems entertainment brands currently face. We have already discussed with many Fortune 500 corporations that are keen for the chain structure we aim to build, which is the first of its kind with ease of use, low cost and green technology. Our approach is genuinely relevant to the needs of corporations with millions of customers each.</p>
            <h2 className="heading-2">Does Anything Change In Virtua?</h2>
            <p>Virtua’s growth will become hugely accelerated!</p>
            <p>Virtua running on the new chain will allow hyper low fees supporting microtransactions in games and resources. This will increase the growth of VGN (Virtua Games Network)  and games acquisition. Virtua features such as resources and crafting will also move to being on-chain creating new exciting opportunities for trading.</p>
            <p>Virtua will continue to expand its metaverse and gaming offerings under the umbrella of the new chain. Virtua will also act as a showcase for what can be done in the space with immersive technologies such as metaverse and will demonstrate how entertainment brands and games can reach new users.</p>
          </div>
          <div className="text">
            <h1 className="title">Next Steps</h1>
            <p>We want our community to be involved in this and to support the new direction. All TVK holders who stake a minimum of 50,000 TVK can submit their vote on the voting portal when voting is live.</p>
            <p>For the proposal to pass, the majority of the total votes cast by must be in favour of this proposal.</p>
            <p>The voting process for this proposal is scheduled to open on 9th October and will run until 20th October. The more TVK you stake, the more votes you receive.</p>
            <p>Regarding the token migration, more information will be shared about the specific details closer to the migration date</p>
            <p>We’ll be active within our Telegram and Discord communities leading up to vote. We are looking forward to the additional level of engagement in the community and are excited to see your ideas come to life within the new ecosystem!</p>
          </div>
        </div>
        <div className="faqs" id="faqs-voting">
          <h2 className="section-title">
            FAQ’s
          </h2>
          <Accordion preExpanded={[activeId]}>
            <AccordionItem uuid="faq_1">
              <AccordionItemHeading>
                <AccordionItemButton>
                  1. What is Virtua known for?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Since 2018, Virtua has been a key player in the web3 entertainment space, pioneering metaverse adoption, mixed reality, brand NFTs, and forming partnerships with renowned brands such as Paramount, Legendary, Kevin Hart, and Shelby American.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  2. What challenges has Virtua identified in the web3 entertainment space?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Challenges include confusing tools, high transaction costs, fragmented marketplaces and brand journeys, friction in using wallets, and environmental concerns.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  3. What are the intentions of Virtua's new proposal?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Virtua aims to develop an L1 blockchain designed from their years of experience to be ultra-low cost, supporting microtransactions, and focusing on attracting billions of entertainment consumers.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  4. How will this proposal impact TVK?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  TVK will evolve and expand from being a utility token to becoming the native token of a new branded blockchain.&nbsp;
                  <span className="under-line">This means that TVK (or the new L1 token) is no longer limited to people who only want to use Virtua products, they can build their own products with the technologies we have developed.
                  </span>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  5. What is the feedback from Virtua's partners on this proposal?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Existing and new brand, corporate, and gaming partners, as well as other key stakeholders, have shown enthusiasm and are ready to participate.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  6.   Why does Virtua believe they can successfully implement an L1 blockchain?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Virtua has the resources to compete and position against most of the top 100 assets, which are chains who are slowly developing. TVK already has tooling, and a product suite suitable for its core audience and a large development team. Being an L1 chain is a key means to position what Virtua is for a more mainstream audience.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  7. What's the role of the TVK community in this proposal?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  The TVK community is central to Virtua, and their support is sought for this new direction. TVK holders have the chance to vote on this proposal.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  8.  Why a new chain - can’t you use existing solutions?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Virtua aims to introduce a specialized L1 blockchain tailored for the entertainment sector, with the ambitious goal of ushering the next 3 billion consumers into web3. While current blockchain solutions touch on various aspects of the challenge, they often don't offer a comprehensive solution. Our intention is to revolutionize this by providing a unified infrastructure and brand solutions. This will enable brands to deploy technology tailored to their unique needs without the hassle of seeking multiple vendors or accommodating technological constraints. Through this integrated approach, we aim to deliver greater value to our existing community and maximize the potential of the tools within our ecosystem.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  9.  What partnerships and support does Virtua have for this new blockchain?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Numerous corporations in publishing, finance, and enterprise sectors (cumulatively reaching over 300 million consumers globally) have shown interest in Virtua's new model and technology approach.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  10.    What is the ethos of the new blockchain?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>The new chain will be "Consumer Ready. Developer Friendly. Trusted by Brands."
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  11.    What are some of the features planned for the new L1 blockchain?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  The features include fixed fees, zero transaction cost options, EVM compatibility, Dapps as a service ecosystem, API integrations, an ecosystem covering Metaverse, Gaming, Brands, AI, and XR, and tools for 3rd party integrations.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  12.     How would TVK holders benefit from the new L1 chain?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  TVK will evolve from a metaverse utility token to one that powers an entire blockchain, with Virtua metaverse, VGN games, and brand partners being the primary adopters. This allows TVK to compete with web3’s top 100, most of which are L1 technologies. Additionally, others can build products using Virtua's technologies.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  13.     How does the proposal benefit the community?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>TVK holders will be able to use the new token for:
                </p>
                <ul>
                  <li>Gas transactions</li>
                  <li>Receiving validator rewards</li>
                  <li>Participating in votes</li>
                  <li>Acquiring staking rewards</li>
                  <li>Serving as a core component for dApps</li>
                  <li>Underpinning the Virtua metaverse for various functions</li>
                  <li>Liquidity Asset: trading of assets with the new token</li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  14.        Will there be an increase in the token supply?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes, the token supply will expand from 1.2 billion to 2.4 billion over 20 years to support this ecosystem growth. This new supply will be used for validator rewards, development rewards, and airdrops. Notably, no new team tokens will be issued. The annual growth rate of tokens will average 3.5%.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  15.       How will the additional tokens be distributed?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul className="list-none">
                  <li>83% to validator rewards</li>
                  <li>13% for development rewards</li>
                  <li>4% for airdrops and other community incentives</li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  16.        How would a token swap function?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>The token swap will be at a 1:1 ratio. If someone holds 10,000 TVK, they would receive 10,000 of the new token. More details will be shared post-proposal approval.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  17.        Do Virtua's brand partners support this change?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes, all brand partners are eager to build their own ecosystems using Virtua's approach. The new blockchain and suite of solutions offer brands a more controlled, comprehensive, and effective approach to connect with audiences.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  18.        What changes can we expect in Virtua?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Virtua will experience accelerated growth, with hyper-low fees supporting microtransactions in games and resources. Features like resources and crafting will move on-chain, and Virtua will continue expanding its metaverse and gaming offerings under the new chain.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  19.        Who can vote on this proposal?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="mb-5">Anyone who holds a minimum required amount of TVK tokens 50,000 can participate to vote on this proposal. All they need to do is to stake their TVK tokens in the staking contract when staking is open before voting period begins to be eligible
                </p>
                <p>Prestige members who are staking in our prestige contract, can also vote with no minimum cap imposed..</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  20.         Can Prestige members vote?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes Prestige members are included in the voting and they don’t need to do any additional actions. All they need to do is to go on the voting page when the voting is open, connect wallet and cast their vote.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  21.       Can Prestige members stake more tokens to get more voting power?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes Prestige members can stake their additional TVK into the staking contract on Ethereum or Polygon network to get more voting power.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  22.        How can TVK holders participate in voting on this proposal?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>TVK holders who stake a minimum of 50,000 TVK can submit their votes on the voting portal. The proposal requires a majority approval to pass. This threshold does not apply to our Prestige community who are already staking. Voting details, such as dates and durations, will be announced soon.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  23.        How are votes weighted?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>1 TVK is equal to 1 vote. So if you stake 200,000 tokens your vote power will be 200,000 votes. </p>
                <p>For each TVK you stake you get 1 vote. (E.g. 275,000 TVK staked equals 275,000 votes)</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  24.      If I staked the minimum amount, can I stake more later to increase my voting power?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes, once you have staked the minimum 50,000 or any number above this you will be able to top up your staking to increase voting power from the same wallet. (E.g. If I initially staked 75,000, I can later add another 10,000 for a total of 85,000 from the same wallet).
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  25.        When and where can we find more information about the token migration?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>More details will be provided closer to the migration date. In the meantime, TVK stakeholders can connect on <a className="link" href="https://t.me/virtuametaverse" target="_blank" rel="noreferrer">Telegram</a> and <a className="link" href="https://discord.com/invite/virtuaofficial" target="_blank"rel="noreferrer">Discord</a> for real-time updates.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  26.       How can I get more involved or voice my opinions on this proposal?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>The Virtua team will be active within <a className="link" href="https://t.me/virtuametaverse" target="_blank" rel="noreferrer">Telegram</a> and <a className="link" href="https://discord.com/invite/virtuaofficial" target="_blank" rel="noreferrer">Discord</a> communities leading up to the vote. We encourage engagement and are keen to see the community's ideas realized within the new ecosystem.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  27.       What networks are supported for the staking dApp?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Both Ethereum and Polygon networks will be supported when staking your TVK on their respective chains.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  28.      How do I stake my TVK tokens to be able to participate in the vote?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Once the window for stake to vote is open, those interested in participating can access the staking screen. Here, they can connect their wallets and stake tokens (a minimum of 50,000 TVK is required). The more tokens you stake, the more voting power you'll have to influence the decision.
                </p>
                {/* <img className="faqs-img" src={image8} />
                <p>Staking involves two steps. First, you must "Approve" to grant the contract permission to access the amount of TVK tokens you wish to stake. Second, you need to confirm the amount you want to stake.</p>
                <ul>
                  <li>Visit the staking platform. The link will be provided at virtua.com/vote. Only use official links shared on virtua.com and Virtua official social channels to avoid scams.</li>
                  <li>Connect Wallet.
                    <img className="faqs-img" src={image10} />
                  </li>
                  <li>Approve the desired staking amount.
                    <img className="faqs-img" src={image1} />
                  </li>
                  <li>Confirm the amount you intend to stake.
                    <img className="faqs-img" src={image11} />
                  </li>
                  <li>Add more to stake, or wait for the voting window to open.</li>
                </ul>
                <p>Please note that both the "Approval" and "Confirm" steps will incur network gas fees.</p> */}
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  29.      How long are my tokens locked into the staking contract?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Once staked, the tokens will be locked in the staking contract until Friday 20th October 23:59 UTC when the voting period ends. When unstaking there is a 7 day cooldown period that will need to be triggered before you can claim your staked tokens.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  30.       When does the stake to vote period begin and end?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Monday 9th October 2023 00:00 UTC - Sunday 15th October 2023 23:59 UTC - Stake to Vote window closes and snapshot will be taken
                </p>
                {/* <p>Sunday 15th October 2023 23:59 UTC - Stake to Vote window closes</p>
                <p>Monday 16th October 2023 00:00-01:00 UTC - Snapshot to be taken of stakers</p> */}
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  31.       When does the voting period begin and end?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Monday 16th October 2023 01:00 UTC - Voting goes live on <a className="link" href="https://snapshot.org/" target="_blank" rel="noreferrer">snapshot.org</a>
                </p>
                <p>Friday 20th October 2023 23:59 UTC - Voting ends on <a className="link" href="https://snapshot.org/" target="_blank" rel="noreferrer">snapshot.org</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  32.      How do I vote once I have staked my tokens?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Go to <a className="link" href="/vote">virtua.com/vote</a> and click the link to the proposal page on snapshot.org. Once you are on the proposal page it will allow you to cast your vote while voting is open if you staked during staking period or are a Virtua prestige staker. All you need to do is to use the correct wallet that you used to stake your TVK tokens for voting or in case you are a Prestige member then just make sure you are connected with the right wallet that you used to stake your TVK tokens into Prestige.
                </p>
                {/* <img className="faqs-img" src={image5} />
                <img className="faqs-img" src={image9} />
                <p>Once connected it will allow you to choose your vote. You just need to select your option and click on the ‘Vote’ button. The platform will calculate your voting power and will let you cast your vote.</p>
                <img className="faqs-img" src={image4} />
                <img className="faqs-img" src={image3} />
                <p>Once you click on ‘Confirm’ to cast your vote it will ask you to confirm it using your connected wallet. It will ask you to confirm the signatures only (and this will not cost any gas to you - signature validation is used to make sure that the connected wallet has the ownership of the private key). It will show a message as below (in case of MetaMask chrome extension). All you need to do is sign the message.</p>
                <img className="faqs-img" src={image2} />
                <p>Once the vote is successfully casted it will show you the success message like this.</p>
                <img className="faqs-img" src={image7} />
                <p>You will see the following error message when you don’t have the voting power, this occurs when you have not staked your tokens during the staking phase and you are not a Prestige member either.</p>
                <img className="faqs-img" src={image6} /> */}
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Can I change my vote?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes you can change your vote whilst voting period is open. In case you change your mind or have chosen the incorrect wrong option.
                </p>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  33.        When can I unstake and reclaim my tokens?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Saturday 21st October 2023 00:00 UTC - Unstaking period begins (7 day cooldown to unstake)
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  34.        Is there a cooldown period to unstake tokens after voting?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes, there is a 7 day cool-down period. You will need to make sure you go and request to unstake for the 7 day cooldown period to begin. The cooldown period is the same for all the users and it starts for each user as soon as they unstake their tokens. Once the cooldown period is over then the user can claim their unstaked tokens into their wallet.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  35.        What is the process to unstake my tokens after I have voted?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>You can unstake your staked TVK tokens once the voting period is over. You can go to the same platform where you staked the tokens for voting. Once you have decided to unstake there will be a 7 day cool down period before you can claim your tokens. You need to go through the following steps to unstake and claim your tokens.
                </p>
                {/* <ul>
                  <li>Unstake
                    <p>First, utilize the dApp to initiate the unstaking of your TVK tokens after voting period has completed.This will require confirming the transaction and it will cost you gas to do this. Make sure that the right network is selected (either Ethereum or Polygon), the same one you used to stake your TVK tokens. Once you are on the unstaking interface, it will show you the amount available to unstake and the cool down period before you can claim your tokens back. To begin the cooldown period click “Confirm to unstake”.
                    </p>
                    <img className="faqs-img" src={image12} />
                    <p>Once you confirm to unstake your tokens, it will show you exactly when you can claim your tokens and this is when the cooldown period will be over to claim your unstaked tokens. See below.</p>
                    <img className="faqs-img" src={image13} />
                  </li>
                  <li>Claim
                    <p>Once the cooldown period is over you will be able to claim your unstaked tokens. The dApp will allow you to claim your tokens by confirming a transaction that will cost you gas as network fees.
                    </p>
                    <img className="faqs-img" src={image14} />
                    <p>Once the transaction is confirmed by the network you will see a confirmation message showing that the tokens have now been unstaked and transferred to your wallet.</p>
                    <img className="faqs-img" src={image15} />
                  </li>
                </ul> */}
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  36.        What are the timelines for staking, voting and when will the final result be shared?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul className="m-0">
                  <li className="fw-600">Announcement<p>Wednesday 4th October 2023  - Proposal Announcement</p></li>
                  <li className="fw-600">Staking<p>Monday 9th October 2023 00:00 UTC - Stake to Vote window opens</p><p>Sunday 15th October 2023 23:59 UTC - Stake to Vote window closes</p></li>
                  <li className="fw-600">Voting<p>Monday 16th October 2023 01:00 UTC - Voting goes live on snapshot.org</p><p>Friday 20th October 2023 23:59 UTC - Voting ends on snapshot.org</p></li>
                  <li className="fw-600">Unstaking / Cooldown<p>Saturday 21st October 2023 00:00 UTC - Unstaking period begins (7 day cooldown to unstake)</p></li>
                  <li className="fw-600">Final Results Announcement<p>Saturday 21st October 2023 - Vote Results Published</p></li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  37.       Is it possible to stake tokens using my mobile device?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Yes. On mobile platforms, we offer support for the MetaMask browser, found in the MetaMask mobile app. By navigating to <a className="link" href={STAKE} target="_blank" rel="noreferrer">stake.virtua.com</a> through the MetaMask browser, you can seamlessly link your wallet to the application and proceed with staking directly from your mobile device. </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="faq_38" id="faq38">
              <AccordionItemHeading>
                <AccordionItemButton>
                  38. I'm getting an error when confirming staking on MetaMask mobile. Why?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>When staking with MetaMask on mobile, ensure you approve the exact number of tokens you want to stake. There's a known issue where MetaMask might slightly reduce the approved amount. Always double-check the approval matches your intended stake.</p>
                <p>Example: If you plan to stake 50,000 tokens, make sure you've approved 50,000 tokens in MetaMask, not 49,999.95 or any other reduced number.</p>
                <p>If you already approved the wrong amount via metamask by mistake, when confirming lessen the amount you are looking to confirm to stake. Usually it’ll be based on a fraction so reducing by 1 TVK should work.</p>
                <div className="faqs-img">
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/MetaMask01.png`}
                    alt="MetaMask01" />
                  <img
                    className=""
                    src={`${REACT_APP_S3_VOTE_IMAGE_URL}/MetaMask02.jpg`}
                    alt="MetaMask02" />
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <div className="text-center">
            <Button upper secondary className="voting-btn" as="a" target="_blank" rel="noreferrer" href="https://virtua.gitbook.io/virtua-wiki/~/changes/FjKrUM25PRaFCx3YNVSc/tvk-community-voting-guide">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12ZM13.3438 8.625C13.3438 9.09099 12.966 9.46875 12.5 9.46875C12.034 9.46875 11.6562 9.09099 11.6562 8.625C11.6562 8.15901 12.034 7.78125 12.5 7.78125C12.966 7.78125 13.3438 8.15901 13.3438 8.625ZM12.5 10.605C12.966 10.605 13.3438 10.9828 13.3438 11.4488V15.3863C13.3438 15.8523 12.966 16.23 12.5 16.23C12.034 16.23 11.6562 15.8523 11.6562 15.3863V11.4488C11.6562 10.9828 12.034 10.605 12.5 10.605Z" fill="white" />
              </svg>
              Voting Guide
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default VoteTvk;
