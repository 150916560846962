import React, { useEffect, useState } from "react";
import "./GameDetailPage.scss";
import { Link, useLocation } from "react-router-dom";
import DetailPageSlider from "./DetailPageSlider";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import gameData from "../games.json";
import { PlayingWebGlModal } from "../../modal/PlayingWebGlModal";
import { useModal } from "../../custom-hooks/useModal";
import useKeycloakFeatures from "../../../keycloak/useKeycloakFeatures";
function GameDetailPage() {
  const { keyCloakUser } = useKeycloakFeatures();
  const location = useLocation();
  const { isShowing, toggle } = useModal();
  const [saveURL, setSaveURL] = useState("");
  const selectedGame = location?.pathname?.split("/")[2];
  const selectedGameData = gameData?.games?.find(
    (data) => data?.game_route === selectedGame
  );
  const appendBaseUrl = (baseUrl, array) => {
    return array.map((item) => `${baseUrl}${item}`);
  };

  const updatedSlides = appendBaseUrl(
    process.env.REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
    selectedGameData?.slides
  );

  const convertDate = (inputDate) => {
    const [day, month, year] = inputDate.split("-");

    const date = new Date(year, month - 1, day);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const yearFull = date.getFullYear();
    const monthName = monthNames[date.getMonth()];
    return `${monthName} ${yearFull}`;
  };

  const handleButtonClick = (val) => {
    toggle();
    setSaveURL(val);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    const localState=localStorage.getItem("redirect_to_game");
    if(localState && keyCloakUser!==null && keyCloakUser!==undefined && keyCloakUser?.attributes?.has_passcode[0]==='true')
    {
      window.open(localState,'_blank');
      localStorage.removeItem("redirect_to_game");
    }
  },[keyCloakUser])

  return (
    <>
      <div className="game-detail-page">
        <div className="container">
          <div className="top-heading">
            <Link to="/games" className="arrow-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
              >
                <path
                  d="M8.16096 15.7102C8.23997 15.7962 8.33786 15.8663 8.44839 15.9161C8.55891 15.9658 8.67964 15.9941 8.80289 15.9992C8.92614 16.0042 9.04919 15.9859 9.16421 15.9454C9.27923 15.9049 9.38368 15.8431 9.47092 15.7639C9.55816 15.6848 9.62626 15.59 9.67089 15.4856C9.71552 15.3812 9.73569 15.2695 9.73012 15.1577C9.72455 15.0458 9.69336 14.9362 9.63854 14.8359C9.58373 14.7356 9.50648 14.6468 9.41173 14.5751L3.03027 8.77662L19.1566 8.77662C19.3843 8.76565 19.5987 8.67584 19.7556 8.52568C19.9125 8.37552 20 8.17646 20 7.96951C20 7.76257 19.9125 7.56351 19.7556 7.41335C19.5987 7.26319 19.3843 7.17338 19.1566 7.16241L3.03027 7.16241L9.42174 1.37079C9.58261 1.21789 9.67237 1.01461 9.67237 0.803203C9.67237 0.591795 9.58261 0.388518 9.42174 0.235621C9.3397 0.160935 9.24223 0.101677 9.13491 0.0612438C9.0276 0.0208109 8.91254 -1.11276e-06 8.79635 -1.10444e-06C8.68015 -1.09611e-06 8.5651 0.0208109 8.45778 0.0612439C8.35047 0.101677 8.25299 0.160935 8.17096 0.235621L0.266059 7.40988C0.181924 7.48338 0.114993 7.57153 0.0692778 7.66906C0.0235625 7.76658 1.04048e-06 7.87148 1.05179e-06 7.97746C1.06309e-06 8.08345 0.0235626 8.18834 0.0692779 8.28587C0.114993 8.38339 0.181924 8.47154 0.266059 8.54504L8.16096 15.7102Z"
                  fill="#9247FF"
                />
              </svg>
            </Link>
            <h2 className="title">{selectedGameData?.name}</h2>
          </div>
          <div className="flex">
            <div className="left-side">
              <DetailPageSlider slides={updatedSlides} />
            </div>
            <div className="right-side">
              <div className="detail-section">
                <div className="top">
                  <h4>Description</h4>
                  <p>{selectedGameData?.description}</p>
                </div>
                <div className="detail">
                  <h4>Details</h4>
                  <div className="list">
                    <div className="list-col">
                      <ul className="list-none">
                        {Object.entries(selectedGameData?.details).map(
                          ([key, value]) => (
                            <li key={key}>
                              {key}:{" "}
                              <span>
                                {key === "Released"
                                  ? value.includes("-") ? convertDate(value) : value
                                  : value}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {selectedGameData?.store_urls && (
                  <div className="buttons w-100">
                    {Object.entries(selectedGameData?.store_urls).map(
                      ([key, value]) => (
                        <a
                          href={value}
                          target="_blank"
                          className="btn"
                          rel="noreferrer"
                        >
                          <span className="icon">
                            <img
                              src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}${key}-dark.svg`}
                              alt="ios"
                            />
                          </span>
                          {key === "ios" ? "APP STORE" : "GOOGLE PLAY"}
                        </a>
                      )
                    )}
                  </div>
                )}

                {selectedGameData?.web?.includes("http") && (
                  <div className="w-100">
                    {keyCloakUser == null || keyCloakUser === "undefined" ? (
                      <a
                        className="btn"
                        rel="noreferrer"
                        onClick={() => handleButtonClick(selectedGameData.web)}
                      >
                        <span className="icon">
                          <img
                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}play-dark.svg`}
                            alt="play"
                          />
                        </span>
                        play
                      </a>
                    ) : keyCloakUser !== null &&
                      keyCloakUser !== "undefined" ? (
                      <a
                        href={selectedGameData.web}
                        className="btn"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="icon">
                          <img
                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}play-dark.svg`}
                            alt="play"
                          />
                        </span>
                        play
                      </a>
                    ) : null}
                  </div>
                )}

                {!selectedGameData?.store_urls &&
                  !selectedGameData?.web?.includes("http") && (
                    <div className="w-100">
                      <a href="/" target="_blank" className="btn disabled">
                        {selectedGameData?.web}
                      </a>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowing && <PlayingWebGlModal hide={toggle} value={saveURL} />}
    </>
  );
}

export default GameDetailPage;
