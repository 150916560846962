import styled from "styled-components";
export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  z-index: 4000;
  padding-top: 70px;
  &.active {
    display: block;
  }
`;
export const ModalDialog = styled.div`
  min-height: calc(100% - 3.5rem);
  max-width: ${(props) => (props.slider ? "774px" : "600px")};
  margin: 1.75rem auto;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  @media (max-width: 774px) {
    min-height: ${(props) => (props.slider ? "auto" : "calc(100% - 3.5rem)")};
  }
`;
export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  img {
    max-width: 60px;
  }
`;
export const ModalHolder = styled.div`
  background: rgba(12, 3, 26, 0.94);
  border: 1px solid rgba(146, 71, 255, 0.32);
  backdrop-filter: blur(10px);
  width: 100%;
  border-radius: 20px;
  min-height: ${(props) => (props.slider ? "427px" : "auto")};
  @media (max-width: 600px) {
    width: 92vw;
    margin: 0 auto;
    &:before {
      filter: blur(15px);
    }
  }
`;
export const CrossIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
`;
