import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import { Autoplay, Navigation, Thumbs } from "swiper";
import "./GameDetailPage.scss";
const DetailPageSlider = ({ slides }) => {
    const [imagesNavSlider, setImagesNavSlider] = useState(null);
    return (
        <div className="slider-flex">
            <Swiper
                onSwiper={setImagesNavSlider}
                direction="horizontal"
                spaceBetween={16}
                slidesPerView={5}
                allowTouchMove={false}
                className="swiper-container1"
                breakpoints={{
                    0: {
                        direction: "horizontal",
                        spaceBetween: 12,
                        slidesPerView: 2,
                        allowTouchMove:true
                    },
                    768: {
                        direction: "horizontal",
                        slidesPerView: 2.9,
                    },
                    990: {
                        slidesPerView: 4,
                    },
                }}
            >
                {slides.map((slide, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className="thumb-holder"> <img src={slide} alt="" /></div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <Swiper
                thumbs={{ swiper: imagesNavSlider }}
                direction="horizontal"
                slidesPerView={1}
                spaceBetween={0}
                mousewheel={true}
                navigation={{
                    nextEl: ".slider__next",
                    prevEl: ".slider__prev",
                }}
                breakpoints={{
                    0: {
                        direction: "horizontal",
                    },
                    768: {
                        direction: "horizontal",
                    },
                }}
                className="swiper-container2"
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation, Thumbs]}
            >
                {slides.map((slide, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <img src={slide} alt="" />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default DetailPageSlider;
