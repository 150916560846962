import './virtuaPrime.scss';
import { Para, SubTitle } from "../../../components/styledComponent";
import Container from "../../../components/generic/container";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from '../../../constants/constant';


const VirtuaPrime = () => {
    return (
        <>
            <section className="virtua-prime">
                <Container>
                    <div className="main-title">
                        <SubTitle>BUY VIRTUA PRIME LAND DEEDS</SubTitle>
                        <Para>Land Deed NFTs entitles you to claim a Land Plot or Condo on a specific island (or in a specific Zone). If you already have a Land Deed, you can claim your Land Plot through the Claim Portal here.</Para>
                    </div>
                    <div className="flex items-center main-plot">
                        <div className="card">
                            <div className="image-holder">
                                <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}small-plot.png`} alt="small-plot" />
                            </div>
                            <div className="title-holder">
                                <h5>Small <span>Plots</span></h5>
                            </div>
                            <div className="meta-info flex">
                                <div>No.of Plots<span>3000</span>
                                </div>
                                <div>Price (ETH)<span>01</span>
                                </div>
                                <div>Amount (ETH)<span>3000</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="image-holder">
                                <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}medium-plot.png`} alt="small-plot" />
                            </div>
                            <div className="title-holder">
                                <h5>Medium <span>Plots</span></h5>
                            </div>
                            <div className="meta-info flex">
                                <div>No.of Plots<span>3000</span>
                                </div>
                                <div>Price (ETH)<span>01</span>
                                </div>
                                <div>Amount (ETH)<span>3000</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="image-holder">
                                <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}large-plot.png`} alt="small-plot" />
                            </div>
                            <div className="title-holder">
                                <h5>Large <span>Plots</span></h5>
                            </div>
                            <div className="meta-info flex">
                                <div>No.of Plots<span>3000</span>
                                </div>
                                <div>Price (ETH)<span>01</span>
                                </div>
                                <div>Amount (ETH)<span>3000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="community flex">
                        <div>
                            <SubTitle hero left>Monster Community</SubTitle>
                        </div>
                        <div className="flex main-plot items-center">
                            <div className="card">
                                Amount (ETH)<span>6500</span>
                            </div>
                            <div className="card">
                                No. of Plots<span>5000</span>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default VirtuaPrime;
