import styled from "styled-components";

const Container = styled.div`
  max-width: ${(props) => (props.container ? "1620px" : "1360px")};
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: 1600px) {
    max-width: 1320px;
  }
`;

export default Container;
