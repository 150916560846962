import "./VirtuaFeature.scss"
import React from "react";
import { Video } from "../hero-section/HeroSectionStyled";
import { Para, SubTitle } from "../../../components/styledComponent";
import Button from "../../../components/generic/button";
import styled from "styled-components";
import { REACT_APP_S3_CLOUDFLARE_ASSETS_URL, REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";

const GradientEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  transform: rotate(180deg);
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;

const VirtuaFeature = () => {
  return (
    <>
      <section className="virtua-feature">
        <div className="main-content">
          <SubTitle>Virtua Features</SubTitle>
        </div>
        <div className="cardlisting">
          <div className="d-grid">
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vflectCard.png`}
                    alt="vflectCard" />
                </div>
                <div className="content">
                  <SubTitle>Play Games</SubTitle>
                  <Para>Experience fun & exciting games where you are</Para>
                </div>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}xpCard.png`}
                    alt="xpCard" />
                </div>
                <div className="content">
                  <SubTitle>Earn XP</SubTitle>
                  <Para>Play games to earn XP and level up throughout the season</Para>
                </div>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}collectiblesCard.png`}
                    alt="collectibleCard" />
                </div>
                <div className="content">
                  <SubTitle>Digital Collectibles</SubTitle>
                  <Para>Earn & claim FREE digital collectibles, NFTs and merch each season</Para>
                </div>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gamersloungeCard.png`}
                    alt="gamersloungeCard" />
                </div>
                <div className="content">
                  <SubTitle>Gamers Lounge</SubTitle>
                  <Para>Showcase your collections and rewards in a FREE 3D environment</Para>
                </div>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}landCard.png`}
                    alt="landCard" />
                </div>
                <div className="content">
                  <SubTitle>Own Land</SubTitle>
                  <Para>Land emits resources and grants your own customizable 3D & VR space</Para>
                </div>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}craftCard.png`}
                    alt="craftCard" />
                </div>
                <div className="content">
                  <SubTitle>Crafting</SubTitle>
                  <Para>Use resources you collect from land to craft new digital collectibles</Para>
                </div>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}questCard.png`}
                    alt="questCard" />
                </div>
                <div className="content">
                  <SubTitle>Questing</SubTitle>
                  <Para>Participate in and complete quests to earn FREE rewards and XP</Para>
                </div>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}marketplaceCard.png`}
                    alt="marketplaceCard" />
                </div>
                <div className="content">
                  <SubTitle>Marketplace</SubTitle>
                  <Para>Buy, sell or trade your collections, resources or crafted items</Para>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VirtuaFeature;