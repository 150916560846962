import "./VirtuaTab.scss"
import React from "react";
import { Para, SubTitle } from "../../../components/styledComponent";
import Button from "../../../components/generic/button";
import styled from "styled-components";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import { MARKETPLACE_URL } from "../../../enviroment";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "./TabSelector";

const GradientEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  transform: rotate(180deg);
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;

const VirtuaTab = () => {
  const [selectedTab, setSelectedTab] = useTabs([
    "marketplace",
    "collection",
  ]);
  return (
    <>
      <section className="relative virtua-tab gradient-effect">
        <GradientEffect />
        <div className="filters-holder filter-main-bg width-filter-1">
          <TabSelector
            isActive={selectedTab === "marketplace"}
            onClick={() => setSelectedTab("marketplace")}
          >
            Marketplace
          </TabSelector>
          <TabSelector
            isActive={selectedTab === "collection"}
            onClick={() => setSelectedTab("collection")}
          >
            Collection
          </TabSelector>
          {/* <button className="active">Marketplace</button>
          <button className="">Collections</button> */}
        </div>
        <TabPanel hidden={selectedTab !== "marketplace"}>
          <div className="marketplace">
            <img
              className="bg-img web"
              src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}marketplace-bg.jpg`}
              alt="marketplace" />
            <div className="overlay">
              <img
                className="bg-img mob"
                src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}marketplace-mob-bg.jpg`}
                alt="marketplace" />
            </div>
            <div className="section-content">
              <SubTitle left upper gYN>
                Marketplace
              </SubTitle>
              <Para>
                Explore our marketplace where you can buy, collect, sell and trade Metaverse-ready digital assets.
              </Para>
              <Button
                upper
                secondary
                as="a"
                href={MARKETPLACE_URL}
              >
                Explore Marketplace
              </Button>
              <div className="download-buttons">
                <SubTitle left small mbl upper>
                  Download Mobile App
                </SubTitle>
                <div className="col text-right flex">
                  {/* <Button
                    transparent
                    white
                    borderRadius
                    as="a"
                    target="blank"
                    href="https://apps.apple.com/pk/app/virtua-metaverse/id1597744060"
                  >
                    <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}ios.svg`} alt="ios" />
                    Apple App Store
                  </Button>
                  <Button
                    transparent
                    white
                    borderRadius
                    as="a"
                    target="blank"
                    href="https://play.google.com/store/apps/details?id=com.terravirtua.prod"
                  >
                    <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android.svg`} alt="android" />
                    Google Play Store
                  </Button> */}
                  <a
                    href="https://apps.apple.com/pk/app/virtua-metaverse/id1597744060"
                    target="_blank"
                    className="btn"
                  >
                    <span className="icon">
                      <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}ios.svg`} alt="ios" />
                    </span>
                    Apple App Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.terravirtua.prod"
                    target="_blank"
                    className="btn"
                  >
                    <span className="icon">
                      <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android.svg`} alt="android" />
                    </span>
                    Google Play Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel hidden={selectedTab !== "collection"}>
          <div className="collection">
            <img
              className="bg-img web"
              src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}collection-bg.jpg`}
              alt="collection" />
            <div className="main-holder">
              <div className="content">
                <SubTitle left upper gYN>
                  Our Collections
                </SubTitle>
              </div>
              <div className="cardlisting">
                <div className="d-grid">
                  <div className="custom-col-width">
                    <div className="card">
                      <div className="card-img">
                        <img
                          className="img"
                          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}landbot.png`}
                          alt="landbot" />
                      </div>
                      <div className="card-content">
                        <div className="wrap">
                          <SubTitle>Cardano Island Landbots</SubTitle>
                          <Para>Land Bots are native to Virtua, and share a deep connection to our metaverse and enable holders to harness resources.</Para>
                        </div>
                        <Button
                          secondary
                          as="a"
                          target="_blank"
                          href="https://www.jpg.store/collection/virtuacardanoislandminerbots?tab=items"
                        >
                          Visit Collection Now
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="custom-col-width">
                    <div className="card">
                      <div className="card-img">
                        <img
                          className="img"
                          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vflect.png`}
                          alt="vflect" />
                      </div>
                      <div className="card-content">
                        <div className="wrap">
                          <SubTitle>Cardano Island vFlects</SubTitle>
                          <Para>Unveiling the Cardano vFlects, the first of their kind to land on Cardano Island and explore the metaverse.</Para>
                        </div>
                        <Button
                          secondary
                          as="a"
                          target="_blank"
                          href="https://www.jpg.store/collection/virtuacardanoislandvflects?tab=items"
                        >
                          Visit Collection Now
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="custom-col-width">
                    <div className="card">
                      <div className="card-img">
                        <img
                          className="img"
                          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vehicles.png`}
                          alt="vehicles" />
                      </div>
                      <div className="card-content">
                        <div className="wrap">
                          <SubTitle>Cardano Island Vehicles</SubTitle>
                          <Para>With the rise of the first crypto island on Virtua Prime, the community will get their hands on exclusive vehicles!</Para>
                        </div>
                        <Button
                          secondary
                          as="a"
                          target="_blank"
                          href="https://www.jpg.store/collection/virtuacardanoislandvehicles?tab=items"
                        >
                          Visit Collection Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </section>
    </>
  );
};

export default VirtuaTab;