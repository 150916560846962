import React from "react";
import styled from "styled-components";
import Button from "../../../components/generic/button";
import { Para, SubTitle } from "../../../components/styledComponent";
import { REACT_APP_S3_CLOUDFLARE_ASSETS_URL } from "../../../constants/constant";
import { Video } from "../hero-section/HeroSectionStyled";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.5) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  /* transform: rotate(180deg); */
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;
const MonsterZone = () => {
  return (
    <>
      <section className="relative experience-section">
        <GradientEffect />
        <Video poster={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}monster.jpg`} autoPlay loop muted playsInline>
          <source
            src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}monster_zone_bg.mp4`}
            type="video/mp4"
          />
        </Video>
        <div className="main-content">
          <SubTitle>The Monster Zone</SubTitle>
          <Para>
            Buy Land or a Condo in the Virtua metaverse - and make it yours
          </Para>
          <Button upper secondary as="a" href="https://island.virtua.com/monsterzone">
            BUY LAND
          </Button>
        </div>
      </section>
    </>
  );
};

export default MonsterZone;
