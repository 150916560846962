import "./VirtuaSdk.scss"
import React from "react";
import { CardTitle, Para, SectionHeading, SectionTitle } from "../../../components/styledComponent";

const VirtuaSdk = () => {
    return (
        <>
            <section className="sdk-section">
                <div className="main-content">
                    <SectionHeading>VIrtua SDK</SectionHeading>
                    <Para>To achieve this, Virtua offers a powerful SDK with various levels of integration, enabling you to tailor the experience for your players.</Para>
                </div>
                <div className="cardlisting">
                    <div className="d-grid">
                        <div className="custom-col-width">
                            <div className="card">
                                <SectionTitle>Basic
                                    <br />
                                    Integration</SectionTitle>
                                <Para className="mb-30">Essential features to get started:</Para>
                                <CardTitle>Shared Login</CardTitle>
                                <Para cardWeight sdkCardDetail>
                                    Centralized login system for players.
                                </Para>
                                <Para cardWeight sdkCardDetail className="mb-44">
                                    Single sign-on (SSO) across all games and services.
                                </Para>
                                <CardTitle>Quests</CardTitle>
                                <Para cardWeight sdkCardDetail>
                                    Basic quest management.
                                </Para>
                                <Para cardWeight sdkCardDetail>
                                    Ability to create, manage, and track quests.
                                </Para>
                            </div>
                        </div>
                        <div className="custom-col-width">
                            <div className="card dark">
                                <SectionTitle>Advanced
                                    <br />
                                    Integration</SectionTitle>
                                <Para className="mb-30">Comprehensive features for a full-fledged ecosystem:</Para>
                                <CardTitle>Social Spaces</CardTitle>
                                <Para cardWeight sdkCardDetail>
                                    In-game social interactions.
                                </Para>
                                <Para cardWeight sdkCardDetail className="mb-44">
                                    Customizable social hubs and player interactions.
                                </Para>
                                <CardTitle>Marketplace</CardTitle>
                                <Para cardWeight sdkCardDetail>
                                    In-game marketplace for buying and selling.
                                </Para>
                                <Para sdkCardDetail>
                                    Integration with virtual currencies and real money transactions.
                                </Para>
                                <CardTitle>Game Assets</CardTitle>
                                <Para cardWeight sdkCardDetail>
                                    Management of in-game assets.
                                </Para>
                                <Para cardWeight sdkCardDetail>
                                    Support for asset creation, trading, and customization.
                                </Para>
                            </div>
                        </div>
                        <div className="custom-col-width">
                            <div className="card">
                                <SectionTitle>Intermediate
                                    <br />
                                    Integration</SectionTitle>
                                <Para  className="mb-30">Enhanced features for deeper engagement:</Para>
                                <CardTitle>Leaderboards</CardTitle>
                                <Para cardWeight sdkCardDetail>
                                    Global and game-specific leaderboards.
                                </Para>
                                <Para cardWeight sdkCardDetail className="mb-44">
                                    Real-time updates and ranking.
                                </Para>
                                <CardTitle>Tournaments</CardTitle>
                                <Para cardWeight sdkCardDetail>
                                    Setup and manage tournaments.
                                </Para>
                                <Para cardWeight sdkCardDetail>
                                    Support for different tournament formats and scheduling.
                                </Para>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VirtuaSdk;