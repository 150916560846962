import React, { useState, useEffect } from "react";
import LoginFormPresentation from "../views/developers/login-form/LoginFormPresentation";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_SITE_KEY } from "../constants/constant";
import { setDocument } from "../utils/helpers/asyncfunctions";
import { validateEmail } from "../utils/helpers/functions";
import { maxLength, minLength } from "../utils/helpers/functions";
const LoginFormContainer = () => {
  const [loader, setloader] = useState(false);
  const [captchavalue, setCaptchavalue] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    studio: "",
    description: "",
  });
  const [formmessage, setFormmessage] = useState("submitting");

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if ((/\s/.test(value) && name==='name') || ((/\s/.test(value) && name==='email'))) {
      return;
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setloader(true);
    const regex = /^[A-Z]+$/i;
    if (!formData.name) {
      setloader(false);
      setFormmessage(`Name must be at least 6 - 20 letters`);
      return;
    } else if (!minLength(formData.name, 5)) {
      setloader(false);
      setFormmessage(`Name must be at least 6 - 20 letters`);
      return;
    } else if (!maxLength(formData.name, 19)) {
      setloader(false);
      setFormmessage(`Name must be at least 6 - 20 letters`);
      return;
    } 
    // else if (!String(formData.name).match(regex)) {
    //   setloader(false);
    //   setFormmessage(`Name must be at least 6 - 20 letters`);
    //   return;
    // } 
    else if (!formData.email) {
      setloader(false);
      setFormmessage(`Please enter a valid email address`);
      return;
    } else if (!minLength(formData.email, 9)) {
      setloader(false);
      setFormmessage(`Please enter a valid email address`);
      return;
    } else if (!maxLength(formData.email, 253)) {
      setloader(false);
      setFormmessage(`Please enter a valid email address`);
      return;
    } else if (!validateEmail(formData?.email)) {
      setloader(false);
      setFormmessage(`Please enter a valid email address`);
      return;
    } else if (!captchavalue) {
      setloader(false);
      setFormmessage("Captcha verification failed. Please try again.");
      return;
    } else {
      await setDocument("join_virtua", null, formData);
      setFormmessage(
        "Your information has been successfully submitted. Thank you"
      );
      // Add your form submission logic here
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: "",
        email: "",
        studio: "",
        description: "",
      }));
      setloader(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchavalue(value);
  };

  const removeMsg = () => {
    setFormmessage("submitting");
  };

  useEffect(() => {
    let interval = setInterval(removeMsg, 3000);
    return () => clearInterval(interval);
  }, [formmessage]);
  const formObject = {
    formData,
    formmessage,
    handleChange,
    handleSubmit,
    captchavalue,
    loader,
  };
  return (
    <LoginFormPresentation formObject={formObject}>
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // Adjust height as necessary
        }}
      >
        <ReCAPTCHA
          sitekey={REACT_APP_SITE_KEY}
          onChange={handleCaptchaChange}
          className="recaptcha"
        />
      </div>
    </LoginFormPresentation>
  );
};

export default LoginFormContainer;
