import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import { useRef } from "react";
import { Heading, Para, SubTitle } from "../../../components/styledComponent";
import "./Slider.scss";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";

const SliderCard = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  padding: 3px;
  padding-bottom: 25px;
  z-index: 9;
`;

const SliderCardTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 54px;
  @media (max-width: 992px) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    min-height: auto;
  }
`;
const SliderCardText = styled.a`
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.03em;
  text-decoration-line: underline;
  color: #9247ff;
  margin: 0;
`;

const Slider = () => {
  const swiperRef = useRef();
  return (
    <>
      <section className="whats-new">
        <div className="slider-heading">
          <Heading>
            <SubTitle left>What’s New</SubTitle>
            <Para>Sneak peek of the latest happenings in Virtua.</Para>
          </Heading>
        </div>
        <Swiper
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Autoplay]}
          autoplay={true}
          initialSlide={0}
          slidesPerView={3.6}
          spaceBetween={50}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 10,
              centeredSlides: true,
            },
            640: {
              slidesPerView: 2,
              autoplay: { delay: 2000, disableOnInteraction: false },
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
              autoplay: false,
            },
            992: {
              slidesPerView: 3.2,
              spaceBetween: 30,
              autoplay: false,
            },
            1440: {
              slidesPerView: 4.2,
              autoplay: false,
            },
            1920: {
              sliderPerView: 5.2,
              autoplay: false,
            },
          }}
        >
          <SwiperSlide>
            <SliderCard>
              <img className="w-100" src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}marketplace-new.jpg`} alt="slide-img" />
              <SliderCardTitle>
                The Revamped Virtua Marketplace. Sleeker, smoother, down right better.
              </SliderCardTitle>
              <SliderCardText href="https://marketplace.virtua.com/">
                Go To Marketplace
              </SliderCardText>
            </SliderCard>
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard>
              <img className="w-100" src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}whats-new-mosnter-zp.jpg`} alt="slide-img" />
              <SliderCardTitle>
                The Rewards Portal is now live!
              </SliderCardTitle>
              <SliderCardText href="https://dashboard.virtua.com/" target="_blank">
                Start Claiming
              </SliderCardText>
            </SliderCard>
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard>
              <img className="w-100" src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}monster-zone.jpg`} alt="slide-img" />
              <SliderCardTitle>
                Buy Land now on Monster Zone located on Virtua Island!
              </SliderCardTitle>
              <SliderCardText href="https://island.virtua.com/monsterzone" target="_blank">
                Explore More
              </SliderCardText>
            </SliderCard>
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard>
              <img className="w-100" src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}cardano_new.jpg`} alt="slide-img" />
              <SliderCardTitle>
              Claim your Cardano Crib and enjoy your Metaverse Experience!
              </SliderCardTitle>
              <SliderCardText href="https://claim.virtua.com/" target="_blank">
                Explore More
              </SliderCardText>
            </SliderCard>
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard>
              <img
                className="w-100"
                src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtua-landbots.jpg`}
                alt="slide-img"
              />
              <SliderCardTitle>Virtua Land Bots launched!</SliderCardTitle>
              <SliderCardText
                href="https://landbots.virtua.com/"
                target="_blank"
              >
                Explore More
              </SliderCardText>
            </SliderCard>
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard>
              <img className="w-100" src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtua-goes-mbl.jpg`} alt="slide-img" />
              <SliderCardTitle>Virtua goes mobile</SliderCardTitle>
              <SliderCardText
                href="https://blog.virtua.com/virtua-news/virtua-new-mobile-app-apple-google-play-nfts-metaverse-drop/"
                target="_blank"
              >
                Explore More
              </SliderCardText>
            </SliderCard>
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard>
              <img className="w-100" src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}shelby-enters.jpg`} alt="slide-img" />
              <SliderCardTitle>Shelby Enters the Metaverse</SliderCardTitle>
              <SliderCardText target="blank" href="https://shelby.virtua.com/">
                Explore More
              </SliderCardText>
            </SliderCard>
          </SwiperSlide>
          <div
            className="prev-navigation"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}swiper-arrow.svg`} alt="swiper-arrow" />
          </div>
          <div
            className="next-navigation"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}swiper-arrow.svg`} alt="swiper-arrow" />
          </div>
        </Swiper>
      </section>
    </>
  );
};

export default Slider;
