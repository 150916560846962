
import React from "react";
import {
    hideChar, inviteVistorCounter,
} from "../../../utils/helpers/asyncfunctions";
import Button from "../button";
import './style.scss'
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";


const CribCard = ({ crib, onImageError, handleClickToLikeCrib, cribIndex, experienceLink, copyLink, loader, setCopyLink, handleClick }) => {

    return (

        <div className="crib">
            <div className="img-holder">
                <img
                    className={`${crib?.thumbnail_url ? "img" : "img placeholder"
                        }`}
                    src={
                        crib?.thumbnail_url
                            ? crib?.thumbnail_url
                            : `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}logo_round_wht.svg`
                    }
                    alt={crib?.experience_name}
                    onError={onImageError}
                />
                <div className="card-actions">
                    <div className="card-views">
                        <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}view-icon.svg`} alt="view-icon" />
                        <span>{crib.invite_visitor_counter}</span>
                    </div>
                    <div className="card-likes">
                        <img src={crib.is_liked ? `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}filled-like-icon.svg` : `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}like-icon.svg`} alt="like-icon" onClick={(e) => {
                            e.preventDefault();
                            handleClickToLikeCrib(crib, cribIndex)
                        }} />
                        <span>{crib.favorite_count > 0 ? crib.favorite_count : ""}</span>
                    </div>
                </div>
                <div className="options">
                    <Button
                        upper
                        secondary
                        className="btn"
                        as="a"
                        href={`${experienceLink}?invite_id=${crib?.document_id}`}
                        target="_blank"
                        onClick={() => { inviteVistorCounter(crib?.document_id) }}
                    >
                        {crib.experience === "condo" ? "Enter condo" : "Enter crib"}
                    </Button>

                    <div
                        className="copied-text"
                        data-title={copyLink ? "Copied" : null}
                        style={{
                            display:
                                copyLink &&
                                    copyLink ===
                                    `${experienceLink}?invite_id=${crib?.document_id}`
                                    ? "block"
                                    : "none",
                        }}
                    >
                        {copyLink ? "Copied" : null}
                    </div>
                    <Button
                        upper
                        secondary
                        className="btn link"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${experienceLink}?invite_id=${crib?.document_id}`
                            );
                            setCopyLink(
                                `${experienceLink}?invite_id=${crib?.document_id}`
                            );
                        }}
                    >
                        <img src="https://assets-cdn.virtua.com/images/Virtua_home/crib-link-icon.svg" />
                    </Button>
                    <Button
                        upper
                        secondary
                        className="btn link flag"
                        onClick={handleClick}
                    >
                        <img src="https://assets-cdn.virtua.com/images/Virtua_home/flagicon.svg" />
                    </Button>
                </div>
            </div>
            <div className="bottom">
                <h5 className="title">
                    {crib.experience_name ? crib.experience_name : ""}
                </h5>
                <div className="meta">
                    <div className="owner">
                        {crib?.wallet_address
                            ? hideChar(crib?.wallet_address)
                            : ""}
                    </div>
                    <div>
                        <div className="pin inline-flex mr-10">
                            <span>
                                <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}pin.svg`} alt="" />
                            </span>
                            C Zone
                        </div>
                        <div className="tag inline-flex">Cardano</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CribCard;