import React from 'react';
// import './styles.scss';
import Button from '../../generic/button';
const ModalCommonMessage = (props) => {
  const { handleClose, show, message, title, btnTitle } = props;
  if (!show) return null;
  return (
    <>
  <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 5000, /* Ensure this z-index is higher than the z-index of Modal */
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div className='whyModal-error w-100'>
      <div className="modal-dialog modalBg relative">
              {/* <div className="crossIcon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                  >
                    <path
                      d="M31.2765 15.1433C31.9448 14.4751 31.9448 13.3916 31.2765 12.7234C30.6083 12.0551 29.5248 12.0551 28.8565 12.7234L21.9999 19.58L15.1434 12.7234C14.4751 12.0551 13.3916 12.0551 12.7234 12.7234C12.0551 13.3916 12.0551 14.4751 12.7234 15.1433L19.58 21.9999L12.7234 28.8566C12.0551 29.5248 12.0551 30.6083 12.7234 31.2765C13.3916 31.9448 14.4751 31.9448 15.1433 31.2765L21.9999 24.4199L28.8566 31.2765C29.5248 31.9448 30.6083 31.9448 31.2765 31.2765C31.9448 30.6083 31.9448 29.5248 31.2765 28.8566L24.4199 21.9999L31.2765 15.1433Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div> */}
        <div className="inner-content-1 error-modal">
          <div className="modal-title text-center">
            <p
              className='title m-0 mb-30'>
             Passcode set successfully
            </p>
          </div>
          <div className="modal-btn">
            <Button
            border0 upper secondary
              className='m-auto
              mt-3'
              onClick={() => {
                handleClose?.(true);
              }}>
              {btnTitle}
            </Button>
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default ModalCommonMessage;
