import { useEffect, useState } from "react";
import {
  getNotificatonsApi,
  updateMessageStatusApi,
} from "../../components/common/api-calls/Common";

export const useNotifications = (
  accessToken,
  notifications,
  setNotifications,
  setLoader,
  setMessageReadId,
  messageReadId,
  setPaginatedContent,
  setHasMore,
  setItemDeleted,
  setIsDeleting
) => {
  const getNotifications = async () => {
    try {
      const res = await getNotificatonsApi(accessToken);
      setNotifications(res);
      if (res?.length > 9) {
        setPaginatedContent(res.slice(0, 10));
      } else {
        setPaginatedContent(res);
        setHasMore(false);
      }
      setLoader("loaded");
    } catch (error) {
      console.error("Error fetching notifications", error);
      setNotifications([]);
      setLoader("loaded");
    }
  };

  const putMessageRead = async () => {
    setMessageReadId(messageReadId);
    try {
      const res = await updateMessageStatusApi(accessToken, messageReadId);
      await getNotifications();
      // Remove the item with the current messageReadId from itemDeleted
      setItemDeleted((prevItemDeleted) =>
        prevItemDeleted.filter((item) => item !== messageReadId)
      );
      setIsDeleting(false);
    } catch (error) {
      console.error("Error Updating notifications", error);
      setLoader("loaded");
    }
  };

  useEffect(() => {
    if (accessToken) {
      getNotifications();
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && messageReadId) {
      putMessageRead();
    }
  }, [messageReadId]);

  return { notifications };
};
