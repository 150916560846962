import React, { useEffect } from "react";
import "./VirtualWorld.scss";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import DashboardBanner from "./DashboardBanner";
import VirtualWorldSlider from "./virtual-world-slider/VirtualWorldSlider";
import styled from "styled-components";
import { DASHBOARD, GAMER_LOUNGE } from "../../enviroment";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
import { useMyContext } from "../../providers/ProviderAppStatus";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
`;

function VirtualWorld() {
  const {state}=useMyContext();

  const { isAuthenticated,doLogin} = useKeycloakFeatures();

  const handleClickGamersLounge=()=>
  {
      if(isAuthenticated && state===false){
        window.open(`${DASHBOARD}claim`, "_blank");
      }else if(isAuthenticated && state===true){
        window.open(GAMER_LOUNGE, "_blank");
      }
      else
      {
        localStorage?.setItem("isLoginInFromWorld", true)
        doLogin();
      }
  }
  useEffect(() => {
    const isLoggedIn = JSON.parse(localStorage?.getItem("isLoginInFromWorld"))
    if (isLoggedIn===true && isAuthenticated && state===true) {
        localStorage?.setItem("isLoginInFromWorld", false)
        window.open(GAMER_LOUNGE, "_blank");
    }
    else if(isLoggedIn===true && isAuthenticated && state===false){
      localStorage?.setItem("isLoginInFromWorld", false)
      window.open(`${DASHBOARD}claim`, "_blank");
    }
}, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="virtual-wrold">
        <div className="banner">
          <GradientEffect />
          <img
            className="slide-bg"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtual-world.webp`}
            alt="virtual world"
          />
          <BannerText className="text-holder one">
            <SectionHeading>Gamers Lounge Mobile App</SectionHeading>
            <Para>
              Now available on mobile, take your virtual home with you wherever
              you are. You'll be able to access all your favorite features on
              the go.
            </Para>
            <div className="buttons w-100">
              <a
                href="https://play.google.com/store/apps/details?id=com.virtua.gamers.lounge"
                target="_blank"
                rel="noreferrer" 
                className="btn"
              >
                <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android-dark.svg`}
                    alt="android"
                  />
                </span>
                Google Play
              </a>
            </div>
          </BannerText>
        </div>
        <DashboardBanner handleClickGamersLounge={handleClickGamersLounge}/>
        <VirtualWorldSlider />
      </div>
  );
}

export default VirtualWorld;
