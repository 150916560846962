import styled from "styled-components";

const Video = styled.video`
  min-height: ${(props)=> (props.cribVideo ? "" : "100vh")};
  height: ${(props)=> (props.cribVideo ? "100%" : "")};
  position: ${(props)=> (props.cribVideo ? "absolute" : "")};
  width: 100%;
  object-fit: cover;
  top: 0;
`;
const BannerText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 100;
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const BannerImage = styled.img`
  min-height: 100vh;
  width: 100%;
  height: ${(props) => (props.absolute ? "100%" : "")};
  object-fit: ${(props) => (props.monster ? "" : "cover")};
  top: 0;
  position: ${(props) => (props.absolute ? "absolute" : "")};
  z-index: 1;
`;

export { Video, BannerText, BannerImage };
