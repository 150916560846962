import { Input } from "../../../components/form/input";
import { Para, SectionHeading } from "../../../components/styledComponent";
import "./JoinVirtua.scss";
import React, { useEffect, useState } from "react";
import { apiBaseuUrl } from "../../../utils/base-url";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import { validateEmail } from "../../../utils/helpers/functions";
import { REACT_APP_SITE_KEY } from "../../../constants/constant";
import { maxLength, minLength } from "../../../utils/helpers/functions";
import Button from "../../../components/generic/button";
import useKeycloakFeatures from "../../../keycloak/useKeycloakFeatures";
import { Link } from "react-router-dom";

const JoinVirtua = () => {
  const { keyCloakUser } = useKeycloakFeatures();
  const [emailError, setEmailError] = useState("");
  const [emailSuccessMsg, setEmailSuccessMsg] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchavalue, setCaptchavalue] = useState(false);
  const removeMsg = () => {
    emailError && setEmailError("");
    emailSuccessMsg && setEmailSuccessMsg("");
  };

  const handleClick = () => {
    if (!minLength(emailVal, 9)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (!maxLength(emailVal, 253)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (!emailVal) {
      setEmailError("Please enter a valid email address");
    } else if (!validateEmail(emailVal)) {
      setEmailError("Please enter a valid email address");
    } else if (!captchavalue) {
      setEmailError("Captcha failed");
    } else {
      setEmailError("");
      setLoading(true);
      axios
        .post(apiBaseuUrl, {
          email: emailVal.toLocaleLowerCase(),
          project_identifier: "jetpack",
        })
        .then((res) => {
          if ([200, 201].includes(res.status)) {
            setEmailVal("");
            setLoading(false);
            setEmailSuccessMsg(res.data.message);
          }
        })
        .catch((res) => {
          setLoading(false);
          setEmailError(res.response.data.message);
        });
    }
  };
  const handleCaptchaChange = (value) => {
    setCaptchavalue(value);
  };

  useEffect(() => {
    let interval = setInterval(removeMsg, 3000);
    return () => clearInterval(interval);
  }, [emailError, emailSuccessMsg]);
  return (
    <>
      <section className="join-virtua">
        {keyCloakUser === null || keyCloakUser === "undefined" ? (
          <SectionHeading>Join Virtua</SectionHeading>
        ) : (
          <SectionHeading>Grow the Value of your Game</SectionHeading>
        )}
        <Para>
          We want to work together. Learn how Virtua can help grow the value of
          your game.
        </Para>
        {/* <Button border0 upper secondary>
          Join Virtua
        </Button> */}
        {keyCloakUser === null || keyCloakUser === "undefined" ? (
          <div className="w-100">
            {/* <ReCAPTCHA
            sitekey={REACT_APP_SITE_KEY}
            onChange={handleCaptchaChange}
            className="recaptcha"
          /> */}
            <div className="input-holder flex-text-center">
              {/* <div>
              <Input
                value={emailVal}
                type="email"
                className={
                  `${emailError ? "red" : ""}` ||
                  `${emailSuccessMsg ? "green" : " "}`
                }
                placeholder="Enter your email here"
                onChange={(e) => setEmailVal(e.target.value)}
              />
              <span className="error_msg">
                {emailError && (
                  <small className="infoTxt error">{emailError}</small>
                )}
                {emailSuccessMsg && (
                  <small className="infoTxt success">{emailSuccessMsg}</small>
                )}
              </span>
            </div> */}
              {/* <button
                onClick={handleClick}
                style={{ pointerEvents: loading ? "none" : "auto" }}
                disabled={!captchavalue}
              >
                {!loading ? "Join Now" : "loading..."}
              </button> */}
              <Link to="/developers">
                <Button border0 upper secondary>
                  Join Now
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <Link to="/developers">
            <Button
              border0
              upper
              secondary
              // onClick={() => {
              //   window.location.href = window.location.origin + "/developers";
              // }}
            >
              Join Virtua
            </Button>
          </Link>
        )}
      </section>
    </>
  );
};

export default JoinVirtua;
