import React, { useEffect, useState,useRef } from "react";
import { isMobile } from 'react-device-detect';
import {
  getPublicCribsListing,
  fetchMetaverseConfig,
  handleLikeDislike,
  inviteLikeCounter,
} from "../../utils/helpers/asyncfunctions";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import "./crib.scss";
import { useModal } from "../custom-hooks/useModal";
import { hideBodyOverflow, REACT_APP_S3_CLOUDFLARE_IMAGE_URL, REACT_APP_VIRTUA_BACKEND_BASE_URL } from "../../constants/constant";
import { CondoModal } from "../../components/condo-modal/CondoModal";
import axios from "axios";
import CribCard from "../../components/generic/cribCard/CribCard";
import PopNotification from "../../components/generic/PopNotification";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
import methods from "../../components/common/api-calls/methods";
import { TitleHeading } from "../../components/styledComponent";
import InfiniteScroll from "react-infinite-scroll-component";



const Listing = () => {
  const [allCribsCondos, setAllCribsCondos] = useState([]);
  const [value, setValue] = useState([])
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [experienceLink, setExperienceLink] = useState("");
  const [copyLink, setCopyLink] = useState("");
  const { isShowing, toggle } = useModal();
  const [ip, setIP] = useState('');
  const [selectedCrib, setSelectedCrib] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState("Sort")
  const [sortFilter, setSortFilter] = useState("")
  const [hasMore,setHasMore]=useState(true);
  const node=useRef(null);
  const { keyCloakUser } = useKeycloakFeatures()

  const sortTypes = {
    MOST_VIEW: {
      key: "most_view",
      value: "Most Viewed"
    },
    MOST_LIKE: {
      key: "most_like",
      value: "Most Liked"
    },
  }

  useEffect(() => {
    fetchConfigurations();
  }, []);

  useEffect(() => {
    fetchCondoCrib();
  }, [page, activeTab, selectedFilter, sortFilter]);

  useEffect(() => {
    if (copyLink) {
      setTimeout(() => {
        setCopyLink(null);
      }, 2000);
    }
  }, [copyLink]);

  useEffect(() => {
    getUserIpAddress();
  }, [])

  useEffect(() => {
    getcribs()
  }, [value])

  const getcribs = () => {
    if (total > allCribsCondos?.length && !isLoading) {
      setPage(page + 1)
      setLoading(true);
    }
  }
  const fetchCondoCrib = async () => {
    try {
      setLoading(true)
      const response = await getPublicCribsListing(page, activeTab, selectedFilter, value, true, sortFilter);
      const data = response;
      if(data?.length===0)
      {
        setHasMore(false);
      }
      if (page > 1) {
        setAllCribsCondos([...allCribsCondos, ...data.data]);
      }
      else {
        setAllCribsCondos([...data.data]);
      }
      setTotal(data?.count)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setHasMore(false)
      setLoading(false);
    }
  }

  const getSearchResults = async (e) => {
    e.preventDefault();
    fetchCondoCrib();
    setPage(1);
  };

  const onImageError = (e) => {
    e.target.src = REACT_APP_S3_CLOUDFLARE_IMAGE_URL + "logo_round_wht.svg";
    e.target.classList.add("placeholder");
  };

  const fetchConfigurations = async () => {
    await fetchMetaverseConfig((snapshot) => {
      setExperienceLink(snapshot.data()?.metaverse_url);
    });
  };

  const handleClickToLikeCrib = async (crib, cribIndex) => {
    const tempList = [...allCribsCondos]
    const currentCrib = tempList[cribIndex]
    currentCrib.is_liked = !(currentCrib.is_liked ?? false)
    if (currentCrib.favorite_count == undefined) currentCrib.favorite_count = 0
    currentCrib.favorite_count = currentCrib.is_liked == true ? currentCrib.favorite_count + 1 : currentCrib.favorite_count == 0 ? 0 : currentCrib.favorite_count - 1
    await inviteLikeCounter(currentCrib, ip)
    setAllCribsCondos(tempList)
  }

  const handleClick = (id) => {
    setSelectedCrib(id)
    toggle();
    hideBodyOverflow();
  };

  const extractIp = (ip) => {
    if (ip.startsWith('::ffff:')) {
      return ip.split(':').pop();
    }
    return ip;
  };

  const getUserIpAddress = async () => {
    const res = await methods.get(`${REACT_APP_VIRTUA_BACKEND_BASE_URL}/auth/client-ip`, keyCloakUser?.accessToken);
    setIP(extractIp(res.ip))
  }
  const handleActiveSort = async (item) => {
    if (item === sortTypes.MOST_VIEW.value) {
      setSortFilter("invite_visitor_counter")
      setPage(1)
    }
    else if (item === sortTypes.MOST_LIKE.value) {
      setSortFilter("favorite_count")
      setPage(1)
    }
    setSelectedDropdown(item)
  }

  const handleActiveState = async (item) => {
    setActiveTab(item)
    setPage(1)
    setSelectedFilter(false)
  }

  const handleSelectedSize = (select) => {
    if (select === "condo" || select === "all") {
      setSelectedFilter(false)
    } else {
      setSelectedFilter(select)
      setPage(1);
    }
  }

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown)
    if (!isMobile) {
      if (selectedDropdown === "Most Viewed" || selectedDropdown === "Most Liked") {
        setOpenDropdown(openDropdown)
      }
    }
  }

  const clearSortingFIlter = () => {
    setSortFilter("")
    setSelectedDropdown(false)
    setOpenDropdown(false)
  }

 const handleSelectedDropDown=(event)=>
 {
  if(openDropdown && node.current &&
    !node.current.contains(event.target))
  {
       setOpenDropdown(false);
  }
  else
  {

  }
 }
  return (
    <>
      {/* <BottomScrollListener onBottom={getcribs}> */}
        {isShowing && (
          <CondoModal hide={toggle} cribDoc={selectedCrib} />
        )}
        <div className="cribs-listing crib-condos order_condo" onClick={(e)=>handleSelectedDropDown(e)}>
          <div className="container">
            <div className="forehead">
              <div className="title-holder">
                {/* <h4 className="title"></h4> */}
                <TitleHeading>Cribs & Condos</TitleHeading>
              </div>
              <div className="right-content">
                <form onSubmit={(e) => getSearchResults(e)}>
                  <div className="search-holder">
                    <input
                      className="text"
                      type="text"
                      placeholder="Search"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <input className="submit" type="submit" />
                  </div>
                </form>
                <div className="sort-dropdown pointer" onClick={handleOpenDropdown}>
                  <div className="web-view">
                    <p>{selectedDropdown ? selectedDropdown : 'Sort'}</p>
                    {(selectedDropdown === sortTypes.MOST_VIEW.value) || (selectedDropdown === sortTypes.MOST_LIKE.value) ?
                      <span onClick={clearSortingFIlter} className="cross">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.70719 6.99996L13.6037 1.10347C13.799 0.908159 13.799 0.591675 13.6037 0.396362C13.4084 0.20105 13.0919 0.20105 12.8966 0.396362L7.00008 6.29285L1.10359 0.39644C0.908281 0.201128 0.591797 0.201128 0.396484 0.39644C0.201172 0.591753 0.201172 0.908237 0.396484 1.10355L6.29297 6.99996L0.396484 12.8964C0.201172 13.0918 0.201172 13.4082 0.396484 13.6035C0.494141 13.7012 0.62211 13.75 0.75 13.75C0.877891 13.75 1.00586 13.7012 1.10352 13.6035L7.00008 7.70707L12.8966 13.6035C12.9942 13.7012 13.1222 13.75 13.2501 13.75C13.378 13.75 13.5059 13.7012 13.6036 13.6035C13.7989 13.4082 13.7989 13.0918 13.6036 12.8964L7.70719 6.99996Z" fill="white" />
                        </svg>
                      </span>
                      :
                      <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}dropdown-icon.svg`} alt="dropDownIcon" />
                    }
                  </div>
                  <div className="mobile-view">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.99609 15.5V2.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12.5 7L8 2.5L3.5 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M21 17L16.5 21.5L12 17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M16.4961 8.5V21.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  {openDropdown ?
                    <div className="open-dropdown" ref={node}>
                      <p onClick={() => handleActiveSort(sortTypes.MOST_VIEW.value)}>
                        {sortTypes.MOST_VIEW.value}
                        <span>
                          {selectedDropdown === sortTypes.MOST_VIEW.value ?
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="9" cy="9" r="9" fill="#9247FF" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M14.2103 5.5861C14.4634 5.8342 14.4634 6.23644 14.2103 6.48454L8.1623 12.414C7.90924 12.662 7.49895 12.662 7.24589 12.414L3.78989 9.02572C3.53683 8.77762 3.53683 8.37537 3.78989 8.12727C4.04295 7.87918 4.45324 7.87918 4.7063 8.12727L7.7041 11.0663L13.2939 5.5861C13.547 5.338 13.9572 5.338 14.2103 5.5861Z" fill="white" />
                            </svg>
                            : ''
                          }
                        </span>
                      </p>
                      <p onClick={() => handleActiveSort(sortTypes.MOST_LIKE.value)}>
                        {sortTypes.MOST_LIKE.value}
                        <span>
                          {selectedDropdown === sortTypes.MOST_LIKE.value ?
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="9" cy="9" r="9" fill="#9247FF" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M14.2103 5.5861C14.4634 5.8342 14.4634 6.23644 14.2103 6.48454L8.1623 12.414C7.90924 12.662 7.49895 12.662 7.24589 12.414L3.78989 9.02572C3.53683 8.77762 3.53683 8.37537 3.78989 8.12727C4.04295 7.87918 4.45324 7.87918 4.7063 8.12727L7.7041 11.0663L13.2939 5.5861C13.547 5.338 13.9572 5.338 14.2103 5.5861Z" fill="white" />
                            </svg>
                            : ''
                          }
                        </span>
                      </p>
                    </div>
                    : ''
                  }
                </div>
              </div>
            </div>

            <div className="cribs-condos-buttons">
              <div className="cribs-filter-buttons">
                <button className={`${activeTab === "all" ? "active" : "not-active"}`} onClick={() => handleActiveState("all")}>All</button>
                <button className={`${activeTab === "crib" ? "active" : "not-active"}`} onClick={() => handleActiveState("crib")}>Cribs</button>
                <button className={`${activeTab === "condo" ? "active" : "not-active"}`} onClick={() => handleActiveState("condo")}>Condos</button>
              </div>

              {activeTab !== "condo" && activeTab !== "all" &&
                <>
                  <span className="bar"></span>
                  <div className="cribs-sizes-buttons">

                    <button className={`${selectedFilter === "Small" ? "selected" : "not-selected"}`} onClick={() => handleSelectedSize("Small")}>
                      {selectedFilter === "Small" ? <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}check-mark.png`} alt="checkmark" /> : ''}
                      Small
                    </button>
                    <button className={`${selectedFilter === "Medium" ? "selected" : "not-selected"}`} onClick={() => handleSelectedSize("Medium")}>
                      {selectedFilter === "Medium" ? <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}check-mark.png`} alt="checkmark" /> : ''}
                      Medium
                    </button>
                    <button className={`${selectedFilter === "Large" ? "selected" : "not-selected"}`} onClick={() => handleSelectedSize("Large")}>
                      {selectedFilter === "Large" ? <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}check-mark.png`} alt="checkmark" /> : ''}
                      Large
                    </button>
                  </div>
                </>
              }
            </div>

            {isLoading && page === 1 ? (
              <div className="loader-container">
                <div className="img_loader">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}tv_loader.gif`} alt="loader" />
                </div>
              </div>
            ) : (
              <>
                <div className="listing-holder">
                  <div className="row">
                    {allCribsCondos?.length > 0 ? (
                      allCribsCondos?.map((crib, cribIndex) => (
                        <div className="custom-col" key={crib.id}>
                           <CribCard
                            crib={crib}
                            onImageError={onImageError}
                            handleClickToLikeCrib={() => {
                              handleLikeDislike(ip, crib.document_id, crib.is_liked, () => {
                                handleClickToLikeCrib(crib, cribIndex)
                              })
                            }}
                            experienceLink={experienceLink}
                            copyLink={copyLink}
                            setCopyLink={setCopyLink}
                            handleClick={() => handleClick(crib.document_id)}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="loader-container">
                        <p>No Records Found!</p>
                      </div>
                    )}
                    <InfiniteScroll
                     dataLength={allCribsCondos.length}
                     next={getcribs}
                     hasMore={hasMore}
                     scrollableTarget="scrollableDiv"/>
                  </div>
                </div>
                {isLoading && (
                  <div className="loader-container">
                    <div className="img_loader">
                      <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}tv_loader.gif`} alt="loader" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      {/* </BottomScrollListener> */}
      <PopNotification />
    </>
  );
};

export default Listing;


