import Button from "../generic/button";
import { DASHBOARD,GAMER_LOUNGE } from "../../enviroment";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
import { useEffect,useRef,useState } from "react";
import { useMyContext } from "../../providers/ProviderAppStatus";
const ProfileDropDown = ({ signOut,handleShowNofitications,handleOutsideClick , xp}) => {
  const {state}=useMyContext();
  const {keyCloakUser} =
    useKeycloakFeatures();
  
  const handleClickGamersLounge=()=>
  {
    if(state===true)
    {
      window.open(GAMER_LOUNGE,'_blank');
    }
    else
    {
      window.open(`${DASHBOARD}claim`, "_blank");
    }
  }


  return (
    <div className="user-profile-dropdown">
      <div className="text-holder">
        <div class="user-img">
          <img
            src={keyCloakUser?.attributes?.profile_image ? keyCloakUser?.attributes?.profile_image[0]: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtua-user-dp.jpg`}
            alt="user-dp"
          />
        </div>
        <h2>{keyCloakUser?.username}</h2>
        <p className="xp-point">
          <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}xp.svg`} alt="xp" />
          <span className="point">{xp?.toLocaleString() ?? 0}&nbsp;</span>
        </p>
      </div>
      <a
        href={DASHBOARD}
        target="_blank"
        className="w-100 dash-link"
        rel="noreferrer"
      >
        <Button className="w-100" upper primary bprimary clashDisplay href={DASHBOARD}>
          Dashboard
        </Button>
      </a>
      <ul className="list-none">
        <li>
          <a className="pointer" onClick={handleClickGamersLounge} rel="noreferrer" target='_blank'>
            Lounge
          </a>
        </li>
        <li className="pointer" onClick={handleShowNofitications}>
            <span>Message Center</span>
        </li>
      </ul>
      <div className="user-logout">
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.65812 15.5906C7.65812 15.163 7.98874 14.8164 8.39658 14.8164H14.3043C14.4402 14.8164 14.5504 14.7008 14.5504 14.5583L14.5504 2.1712C14.5504 2.02867 14.4402 1.91313 14.3043 1.91313L8.39658 1.91313C7.98874 1.91313 7.65812 1.56652 7.65812 1.13894C7.65812 0.711366 7.98874 0.364746 8.39658 0.364746H14.3043C15.2559 0.364746 16.0273 1.17352 16.0273 2.1712V14.5583C16.0273 15.556 15.2559 16.3647 14.3043 16.3647H8.39658C7.98874 16.3647 7.65812 16.0181 7.65812 15.5906Z"
            fill="#475467"
          ></path>
          <path
            d="M11.9526 9.51594C11.9526 10.086 11.5118 10.5482 10.968 10.5482H6.18672C6.16415 10.9151 6.13588 11.2817 6.10194 11.6479L6.07273 11.9629C6.02524 12.4751 5.50639 12.7819 5.06369 12.5595C3.26389 11.6552 1.63457 10.4178 0.254083 8.90668L0.224586 8.8744C-0.0384038 8.58653 -0.0384037 8.13255 0.224586 7.84468L0.254084 7.81239C1.63457 6.3013 3.26389 5.06386 5.06369 4.15958C5.50639 3.93715 6.02524 4.24393 6.07273 4.75618L6.10194 5.07122C6.13588 5.4374 6.16415 5.804 6.18673 6.17089L10.968 6.17089C11.5118 6.17089 11.9526 6.63305 11.9526 7.20315V9.51594Z"
            fill="#475467"
          ></path>
        </svg>
        <p onClick={signOut}>Sign out</p>
      </div>
    </div>
  );
};
export default ProfileDropDown;