import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import {Navigation, Thumbs } from "swiper";
import "./VirtualWorldSlider.scss";
import {
  Para,
  SectionHeading,
} from "../../../components/styledComponent";
import sliderContent from "./sliderContent.json";
import { ISLAND_URL } from "../../../enviroment";
const slides = sliderContent.slides;


const VirtualWorldSlider = () => {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  const navigate=useNavigate();


  const handleCLick = (url) => {
    if (url) {
      window.open(url, '_self');
      // navigate(url);
    } else {
      return;
    }
  };
  return (
    <div className="virtual-world-slider">
      <div className="slider__flex">
        <div className="slider__col">
          <div className="slider__thumbs">
            <Swiper
              direction="vertical"
              spaceBetween={24}
              slidesPerView={3}
              className="swiper-container1"
              breakpoints={{
                0: {
                  direction: "horizontal",
                  spaceBetween: 12,
                  slidesPerView: 2.3,
                },
                768: {
                  direction: "horizontal",
                  slidesPerView: 3,
                },
                1025: {
                  direction: "vertical",
                },
              }}
            >
              {slides.map((slide, index) => (
                <>
                  {index > 0 && (
                    <SwiperSlide key={index}>
                      <div
                        className="slider__image"
                        style={
                          slide?.title?.includes("Island")
                            ? { cursor: "auto" }
                            : {}
                        }
                        onClick={() => {
                          handleCLick(
                            index === 1
                              ? slide.url
                              : slide.url?.length > 0
                              ? ISLAND_URL + slide.url
                              : ""
                          );
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_S3_CLOUDFLARE_IMAGE_URL +
                            slide.img
                          }
                          alt=""
                        />
                        <span className="title">{slide.title}</span>
                        {!slide?.title.includes("Island") && (
                          <a
                            href={
                              index === 1
                                ? slide.url
                                : slide.url?.length > 0
                                ? ISLAND_URL + slide.url
                                : ISLAND_URL
                            }
                            className="arrow-icon"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="66"
                              height="66"
                              viewBox="0 0 66 66"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_11979_7945)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M33 0L27.225 5.775L50.325 28.875H0V37.125H50.325L27.225 60.225L33 66L66 33L33 0Z"
                                  fill="White"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_11979_7945">
                                  <rect
                                    width="66"
                                    height="66"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        )}
                      </div>
                    </SwiperSlide>
                  )}
                </>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="slider__images">
          <Swiper
            thumbs={{ swiper: imagesNavSlider }}
            direction="horizontal"
            slidesPerView={1}
            spaceBetween={0}
            mousewheel={true}
            navigation={{
              nextEl: ".slider__next",
              prevEl: ".slider__prev",
            }}
            breakpoints={{
              0: {
                direction: "horizontal",
              },
              768: {
                direction: "horizontal",
              },
            }}
            className="swiper-container2"
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Thumbs]}
          >
            {slides.map((slide, index) => (
              <>
                {index === 0 && (
                  <SwiperSlide key={index}>
                    <div className="slider__image slider__hover">
                      <div class="content-overlay"></div>
                      <img
                        src={
                          process.env.REACT_APP_S3_CLOUDFLARE_IMAGE_URL +
                          slide.img
                        }
                        alt=""
                      />
                      <div className="inner-connect">
                        <SectionHeading>{slide.title}</SectionHeading>
                        <Para>{slide.description}</Para>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default VirtualWorldSlider;
