import "./landSalePage.scss";
import { Para, SubTitle } from "../../components/styledComponent";
import Button from "../../components/generic/button";
import VirtuaPrime from "./virtua-prime/VirtuaPrime";
import { VirtuaPrimeSlider } from "./virtua-prime-slider/VirtuaPrimeSlider";
import { SpaceSlider } from "./space-slider/SpaceSlider";
import {
  BannerImage,
  BannerText,
} from "../home/hero-section/HeroSectionStyled";
import {useEffect} from "react";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";

const LandSalePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="main-banner">
        <BannerImage src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}landsale.png`} alt="landSale" />
        <BannerText className="banner-section banner-title">
          <SubTitle bold hero>
            BUILD YOUR HOME IN THE METAVERS
          </SubTitle>
          <Para>
            From huge Gigaplots to affordable Condos, you’re free to carve out
            and customise your own personal space in the metaverse! Mint a Land
            Deed NFT, use it to claim a Land Plot or Condo, and see where your
            adventures lead you!
          </Para>
          <Para>Keep scrolling to learn more…</Para>
          <div className="hero-buttons">
            <Button secondary>Buy land</Button>
          </div>
        </BannerText>
      </section>
      <div className="land-sale-page relative">
        <SpaceSlider />
        <VirtuaPrimeSlider />
        <VirtuaPrime />
      </div>
    </>
  );
};

export default LandSalePage;
