import React from "react";
import styled from "styled-components";
import Button from "../../components/generic/button";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { Video } from "../home/hero-section/HeroSectionStyled";
import "./metaverseHomePage.scss";
import Listing from "./Listing";
import VirtuaFeature from "../home/virtua-feature/VirtuaFeature";
import Collection from "./Collection";
import MetaverseGames from "./MetaverseGames";
import { CARDANO, ISLAND_URL } from "../../enviroment";
import { Para, SectionHeading } from "../../components/styledComponent";

const GradientEffect = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  background: linear-gradient( 180deg, rgba(12,3,26,0) 0%, rgba(12,3,26,0.3) 48.54%, #0c031a 100% );
  width: 100%;
  z-index: 2;
  height: 322px;
`;

const Metaverse = () => {
  return (<>
    <div className="metaverse-page">
      <div className="top-banner">
        {/* <GradientEffect /> */}
        <Video poster={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}video-poster.jpg`} cribVideo autoPlay loop muted playsInline>
          <source
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtua-metaverse-bg.mp4`}
            type="video/mp4"
          />
        </Video>
        <div className="container">
        <SectionHeading headingMargin>Explore Virtua</SectionHeading>
          <Para>Come and explore virtual 3D spaces made by our community, or create your own and invite your friends! Get ready for a world of limitless possibilities!</Para>
          {/* <div className="btns">
            <Button
              cribButton
              upper
              secondary
              as="a"
              href={ISLAND_URL}
            >
              Monster Zone
            </Button>
            <Button
              secondary
              upper
              className="know-more"
              as="a"
              href={CARDANO}
            >
              Cardano Island
            </Button>
          </div> */}
        </div>
      </div>
      <Listing />
      {/* <VirtuaFeature />
      <Collection />
      <MetaverseGames /> */}
    </div>
  </>
  );
};

export default Metaverse;
