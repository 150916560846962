import React from 'react';

const Spinner = () => {
  const spinnerContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width:'40px',
    height: '40px',
    margin:'4px auto 0'
  };

  const spinnerStyle = {
    width: '1.5rem',
    height: '1.5rem',
    border: '0.2rem solid rgba(0, 0, 0, 0.1)',
    borderTop: '0.2rem solid white',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div style={spinnerContainerStyle}>
      <style>{keyframesStyle}</style>
      <div style={spinnerStyle}></div>
    </div>
  );
};

export default Spinner;
