import "./whyModal.scss";
import { showBodyOverflow } from "../../constants/constant";
import Button from "../../components/generic/button";
import { Link } from "react-router-dom";
export const CookiesModal = ({ hide }) => {
  const closeModal = () => {
    showBodyOverflow();
    hide();
  };

  return (
    <>
      <div className="flex items-center flex-text-center active d-block cookies-modal">
        <div className="cookies-inner">
          <p>
            Virtua uses cookies to improve your experience, analyze website
            traffic, to keep track of your cart items, and to save user set
            preferences while viewing our website. You can read more about our
            cookie policy here:{" "}&nbsp;
            <Link className="link" to="/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>
          </p>
          <div className="flex items-center button-gap">
            <Button border0 upper secondary onClick={closeModal}>
              Reject
            </Button>
            <Button border0 upper secondary onClick={closeModal}>
              Accept
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
