import { useEffect } from "react";
import { MainRouter } from "../routes/MainRoutes";
import { BrowserRouter } from "react-router-dom";
import { REACT_APP_META_TAG_ROBOTS } from "../../constants/constant";
import { useState } from "react";

const Main = () => {
  const [vanryPrice, setVanryPrice] = useState(null);
  const [marketCap, setMarketCap] = useState(null);
  const [priceChangePercentage, setPriceChangePercentage] = useState(null);

  const isDataStale = (timestamp) => {
    const now = new Date();
    const fiveMinutesInMillis = 5 * 60 * 1000;
    return now.getTime() - timestamp > fiveMinutesInMillis;
  };
  const url = process.env.REACT_APP_VANRY_MCAP_PRICE;
  const getChangePercentage = process.env.REACT_APP_VANRY_CHANGEPRC

  const fetchData = async () => {
    try {
      const response = await fetch(url);
      const getPercentage = await fetch(getChangePercentage);
      const json = await response.json();
      const percJson = await getPercentage.json();
      const vanryData = json.data.filter((d) => d.s === "VANRYUSDT")
      const mCapInMillions = (vanryData[0]?.c * vanryData[0]?.cs).toLocaleString().split('.')[0]
      setVanryPrice(vanryData[0]?.c)
      setMarketCap(mCapInMillions)
      setPriceChangePercentage(percJson?.priceChangePercent)
      const now = new Date();
      const cacheData = {
        vanryPrice:vanryData[0]?.c,
        priceChangePercentage:percJson?.priceChangePercent,
        marketCap:mCapInMillions,
        timestamp: now.getTime(),
      };
      localStorage.setItem('apiData', JSON.stringify(cacheData));

    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // Check if there is cached data in localStorage
    setInterval(() => {
      const cachedDataString = localStorage.getItem('apiData');
      if (cachedDataString) {
        const cachedData = JSON.parse(cachedDataString);
        // Check if the cached data is still valid
        if (!isDataStale(cachedData.timestamp)) {
          // If valid, use the cached data
          setVanryPrice(cachedData.vanryPrice)
          setMarketCap(cachedData.marketCap)
          setPriceChangePercentage(cachedData?.priceChangePercentage)
          return;
        }
      }
  
      // If no valid cached data, fetch data from the API
      fetchData();
    }, 300000);
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  // useEffect(() => {
  //   const showMetaTag = REACT_APP_META_TAG_ROBOTS === 'true';

  //   const metaTag = document.createElement('meta');
  //   metaTag.name = 'robots';
  //   metaTag.content = showMetaTag ? 'index, follow' : 'noindex, nofollow';
  //   document.head.appendChild(metaTag);
  //   return () => {
  //     document.head.removeChild(metaTag);
  //   };
  // }, []);
  return (
    <BrowserRouter>
      <div id="wrapper">
        <MainRouter />
      </div>
    </BrowserRouter>
  );
};

export default Main;
