import styled from "styled-components";

const Input = styled.input`
  display: block;
  width: 100%;
  height: 54px;
  /* height: calc(1.5em + 0.75rem + 18px); */
  /* padding: 0.375rem 0.95rem; */
  padding-left: 24px;
  padding-right: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.3px;
  color: #FFF;
  background: linear-gradient(180deg, rgba(223, 201, 255, 0.08) 0%, rgba(146, 71, 255, 0.08) 100%);
  backdrop-filter: blur(15px);
  border: ${(props) =>
    props.error ? "1px solid #ED1C24" : "1px solid rgba(146, 71, 255, 0.30)"};
  background-clip: padding-box;
  border-radius: 100px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const ErrorInputMessage = styled.span`
  color: #ed1c24;
  display: block;
  padding: 8px 0 4px;
`;

export { Input, ErrorInputMessage };
