import axios from "axios";
import strings from "./strings/english";
import keycloak from "./keycloack";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_VIRTUA_SSO_URL,
  timeout: 500000,
});
axiosInstance.interceptors.request.use(
  async (config) => {
    if (keycloak.authenticated === false) {
      throw new Error(strings.session_expired);
    }
    if (keycloak.isTokenExpired(120)) {
      console.log("refresing token");
      await keycloak.updateToken(120);
    }
    config.headers.Authorization = `Bearer ${keycloak.token}`;
    return config;
  },
  (error) => {
    console.log("axios.interceptors error");
    return error;
  }
);

export const updatePasscodeAPI = async (
  passcode: string
): Promise<any | null> => {
  try {
    const responce = await axiosInstance.post<any, any>(
      `/realms/${process.env.REACT_APP_VIRTUA_SSO_REALM}/userWalletAdministration/updatePasscode`,
      {
        passcode,
      }
    );
    if (responce.data !== null) {
      return { success: true, message: responce.data?.message };
    }
  } catch (error) {
    console.log("error",error)
    if (keycloak.authenticated === false) {
      return { success: false, message: strings.session_expired };
    }
  }
  return {
    success: false,
    message: "Update Passcode request failed. Please try again",
  };
};
