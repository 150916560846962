import React from "react";
import styled from "styled-components";
import "./WebGames.scss";

function WebGames() {
  const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;
  return (
    <div className="tvk-games game-landing-page">
      <div className="mainBanner h-screen">
        <div className="relative">
          <div className="container">
            <div className="wrapper">
              <div className="txt-holder">
                <h2 className="section-title">
                  ADD A NEW LAYER OF ENGAGEMENT AND MONETISATION TO YOUR GAME
                </h2>
              </div>
            </div>
          </div>
          <GradientEffect />
          <a href="#unlockingMetaverse" className="explore-more">
            <div className="mouse"></div>
            <div className="txt">Explore More</div>
          </a>
        </div>
      </div>
      <div className="content relative" id="unlockingMetaverse">
        <div className="container">
          <h2 className="section-title">Unlocking your games potential in Web 3 and the Metaverse</h2>
          <p className="section-detail">
            Virtua is a transformative platform designed to extend the life and revenue of your hyper-casual game. By connecting your game to the metaverse, our platform drives players back into your game in a rewarding engagement loop. Achievements in your game unlock value in Virtua, incentivizing players to return and further engage. This not only amplifies player retention but also opens up fresh monetization avenues within the metaverse. And all this is done while ensuring data safety and compliance.
            <br /> <br />
            <strong>With Virtua, it's a win-win: your game becomes more captivating, and your revenue grows. Dive into the next level of gaming with us.</strong>
          </p>
          {/* <p className="section-detail">
            
          </p> */}
          <div className="cta">
            <a href="mailto:games@virtua.com" style={{ "marginTop": "calc(40px - 1rem)" }} className="custom-btn icon-btn lg dark uppercase">CONTACT US</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebGames;
