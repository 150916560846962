import React from "react";
import Button from "../../../components/generic/button";
import { Para, SectionHeading } from "../../../components/styledComponent";
import "./LoginForm.scss";

const LoginFormPresentation = React.memo(
  ({formObject,children}) => {
    const { formData, formmessage, handleChange, handleSubmit, captchavalue, loader } = formObject;
    return (
      <div className="join-virtua-form">
        <div className="login-form">
          <SectionHeading>Join Virtua</SectionHeading>
          <Para>
            Take your game to the next level. Get in contact and we will be able
            to tailor the best solution to expand your gaming experience.
          </Para>
          <div className="w-100 error_msg">
            <small
              className={
                  formmessage ===
                    "Your information has been successfully submitted. Thank you"
                  && "has-success"
              }
            >
              {formmessage==="Your information has been successfully submitted. Thank you" && "Your information has been successfully submitted. Thank you"}
            </small>
          </div>
          <form className="w-100" onSubmit={handleSubmit}>
            <div className="flex gap-23">
              <div className="form-group">
                <label htmlFor="name">Username*</label>
                <input
                  name="name"
                  id="name"
                  className={`${
                    formmessage === `Name must be at least 6 - 20 letters`
                      ? "red form-control mb-0"
                      : "form-control mb-0"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                />
                <div className="w-100 error_msg">
                  <small
                    className={
                      formmessage === "submitting"
                        ? null
                        : formmessage ===
                        `Name must be at least 6 - 20 letters`
                      && "has-error"
                    }
                  >
                    {formmessage===`Name must be at least 6 - 20 letters` && `Name must be at least 6 - 20 letters`}
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  name="email"
                  id="email"
                  className={`${
                    formmessage === `Please enter a valid email address`
                      ? "red form-control mb-0"
                      : "form-control mb-0"
                  }`}
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="w-100 error_msg">
                  <small
                    className={
                      formmessage === "submitting"
                        ? null
                        : formmessage ===
                        `Please enter a valid email address`
                       && "has-error"
                    }
                  >
                    {formmessage===`Please enter a valid email address` && `Please enter a valid email address`}
                  </small>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="studio">Studio</label>
              <input
                name="studio"
                className="form-control"
                id="studio"
                placeholder="Enter Studio"
                type="text"
                value={formData.studio}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                className="form-control"
                id="description"
                placeholder=""
                value={formData.description}
                onChange={handleChange}
              ></textarea>
            </div>
            {children}
            <Button
              border0
              upper
              purple
              clashDisplay
              className="w-100"
              type="submit"
              disabled={!captchavalue}
            >
              {loader ? "LOADING..." : "SEND NOW"}
            </Button>
          </form>
        </div>
      </div>
    );
  }
);

export default LoginFormPresentation;
