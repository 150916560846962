import Keycloak from "keycloak-js";


const keycloak = new Keycloak({
  url: process.env.REACT_APP_VIRTUA_SSO_URL,
  clientId: process.env.REACT_APP_VIRTUA_SSO_CLIENT_ID,
  realm: process.env.REACT_APP_VIRTUA_SSO_REALM,
});

export default keycloak;
