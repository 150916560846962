import { REACT_APP_VIRTUA_BACKEND_BASE_URL } from "../../../constants/constant";
import methods from "./methods";

export const getNotificatonsApi=async(token)=>{
    if(token)
    {
    const res=await methods.get(`${REACT_APP_VIRTUA_BACKEND_BASE_URL}/notifications`,token);
    return res;
    }
    else
    {
        console.error("user token not defined");
    }
}

export const getClaimStatusApi=async(token)=>
{
  if(token)
  {
    const res=await methods.get(`${REACT_APP_VIRTUA_BACKEND_BASE_URL}/reward/gamersLoungeRewardStatus`,token);
    return res;
  }
  else
  {
    console.error('user token not defined');
  }
}

export const updateMessageStatusApi = async (token, id) => {
    if (token && id) {
      try {
        const res = await methods.put(
          `${REACT_APP_VIRTUA_BACKEND_BASE_URL}/notifications`,
          token,id // Assuming token and id should be sent as part of the request body
        );
        return res;
      } catch (error) {
        console.error("Error updating message status:", error);
      }
    } else {
      console.log("User token or id is not defined");
    }
  };
  