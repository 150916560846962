export const REACT_APP_VIDEO_ASSETS_URL = process.env
  .REACT_APP_VIDEO_ASSETS_URL
  ? process.env.REACT_APP_VIDEO_ASSETS_URL
  : "";

export const REACT_APP_S3_CLOUDFLARE_ASSETS_URL = process.env
  .REACT_APP_S3_CLOUDFLARE_ASSETS_URL
  ? process.env.REACT_APP_S3_CLOUDFLARE_ASSETS_URL
  : "";

export const REACT_APP_S3_CLOUDFLARE_IMAGE_URL = process.env
  .REACT_APP_S3_CLOUDFLARE_IMAGE_URL
  ? process.env.REACT_APP_S3_CLOUDFLARE_IMAGE_URL
  : "";
export const REACT_APP_S3_VOTE_IMAGE_URL = process.env
  .REACT_APP_S3_VOTE_IMAGE_URL
  ? process.env.REACT_APP_S3_VOTE_IMAGE_URL
  : "";

export const REACT_APP_META_TAG_ROBOTS = process.env.REACT_APP_META_TAG_ROBOTS;

export const REACT_APP_LEDGER_API=process.env.REACT_APP_LEDGER_API;

export const REACT_APP_VIRTUA_BACKEND_BASE_URL=process.env.REACT_APP_VIRTUABACKEND_BASE_URL;

export const SOCIAL_ICONS = [
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}facebook.svg`,
    href: "https://www.facebook.com/VirtuaMetaverse/",
    alt: "facebook",
    text:"Facebook"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}twitter.svg`,
    href: "https://twitter.com/Virtuametaverse",
    alt: "twitter",
    text:"Twitter"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}instagram.svg`,
    href: "https://www.instagram.com/Virtuametaverse/",
    alt: "instagram",
    text:"Instagram"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}youtube.svg`,
    href: "https://www.youtube.com/VirtuaMetaverse",
    alt: "youtube",
    text:"Youtube"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}discord.svg`,
    href: "https://discord.com/invite/virtuaofficial",
    alt: "discord",
    text:"Discord"
  },
  // {
  //   src: reddit,
  //   href: "https://www.reddit.com/user/terravirtua",
  //   alt: "reddit",
  //   text:"Reddit"
  // },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}medium.svg`,
    href: "https://medium.com/terravirtua",
    alt: "medium",
    text:"Medium"
  },
  // {
  //   src: telegram,
  //   href: "https://t.me/Virtuametaverse",
  //   alt: "telegram",
  //   text:"Telegram"
  // },
];

export const showBodyOverflow = () => {
  document.body.classList.remove("overflow-hidden");
};
export const hideBodyOverflow = () => {
  document.body.classList.add("overflow-hidden");
};
export const addFilterClass = () => {
  document.body.classList.add("game-filter");
};
export const removeFilterClass = () => {
  document.body.classList.remove("game-filter");
};
export const REACT_APP_SITE_KEY=process.env.REACT_APP_SITE_KEY;