import React from "react";
import Viewer from "./Viewer";
import { useParams } from "react-router-dom";

function SketchfabModal() {
  const { id } = useParams();

  return (
    <div style={{ height: "800px" }}>
      <Viewer id={id} />
    </div>
  );
}

export default SketchfabModal;
