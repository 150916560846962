import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "./virtuaPrimeSlider.scss";
import { Mousewheel, Pagination } from "swiper";
import { SubTitle } from "../../../components/styledComponent";
import { FirstSection } from "./first-section/FirstSection";
import { SecondSection } from "./second-section/SecondSection";

export const VirtuaPrimeSlider = () => {
  return (
    <>
      <SubTitle className="slider-title">VIRTUA PRIME ISLANDS & ZONES</SubTitle>
      <section className="relative">
        <div className="main-slider-wrapper">
          <div className="my-custom-pagination-div" />
          <Swiper
            direction={"vertical"}
            slidesPerView={1}
            mousewheel={{
              thresholdDelta: 60,
            }}
            pagination={{
              el: ".my-custom-pagination-div",
              clickable: true,
              renderBullet: (index, className) => {
                return (
                  '<div className="' +
                  className +
                  '"><span>' +
                  0 +
                  (index + 1) +
                  "</span></div>"
                );
              },
            }}
            modules={[Mousewheel, Pagination]}
            className="spaceSwiper virtuaSlider"
          >
            <SwiperSlide>
              <FirstSection />
            </SwiperSlide>
            <SwiperSlide>
              <SecondSection />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};
