import React from 'react'
import "./MainBanner.scss";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from '../../../constants/constant';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const GradientEffect = styled.div`
  position: absolute;
  bottom: -60px;
  left: 0;
  content: "";
  background: linear-gradient(180deg, rgba(6, 0, 28, 0.00) 69.63%, #06001C 100%);
  width: 100%;
  z-index: 2;
  height: 220px;
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;

function MainBanner() {
    return (
            <div className='heroBnner'>
                <img
                    className="web"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}heroBannerWeb.webp`}
                    alt="heroBannerWeb" />
                <img
                    className="mob"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}heroBannerMob.webp`}
                    alt="heroBannerMob" />

                {/* <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}${isMobile ? "heroBannerWeb.webp":"heroBannerMob.webp"}`} /> */}
            </div>
    )
}

export default MainBanner
