import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import './spaceSlider.scss';
import {EffectCoverflow,Autoplay } from "swiper";
import {Para, SubTitle} from "../../../components/styledComponent";
import {FirstSection} from "../virtua-prime-slider/first-section/FirstSection";
import {SecondSection} from "../virtua-prime-slider/second-section/SecondSection";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";

export const SpaceSlider = () => {
    return (
        <>
            <div className="slider-main-title">
                <SubTitle>YOUR SPACE, YOUR CHOICE</SubTitle>
                <Para>What will you do with your Land Plot or Condo? Choose from the following options, and join our community to make your voice heard as the Virtua metaverse evolves!</Para>
            </div>
            <ul className="list-none flex items-center listing">
                <li>Virtual Buildings</li>
                <li>Socialize</li>
                <li>E-Commerce</li>
                <li>Rent</li>
            </ul>
                <section className="relative">
                <div className="main-slider-wrapper">
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={false}
                        centeredSlides={true}
                        slidesPerView={1.5}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 690,
                            depth: 170,
                            modifier: 1,
                            slideShadows : true,
                        }}
                        initialSlide={2}
                        modules={[EffectCoverflow, Autoplay]}
                        className="spaceSwiper"
                        breakpoints={{
                            320: {
                                slidesPerView: 1.1,
                                initialSlide:0,
                                coverflowEffect:{
                                    stretch:590,
                                    modifier:0.1,
                                }
                            },
                            600: {
                                slidesPerView: 1.5,
                                initialSlide:0,
                            },
                            768: {
                                slidesPerView: 1.7,
                            },
                            992: {
                                slidesPerView: 1.4,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <FirstSection/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <SecondSection/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}poster-img.jpg`} alt="img" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}landsale.png`} alt="img" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    );
}