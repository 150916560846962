import React, { useState } from "react";
import 'swiper/css'
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import "./vanry.scss";

function Vanry() {
  const [copyText, setCopyText] = useState(false);
  const vanryData = JSON.parse(localStorage?.getItem('apiData'))

  return (
    <div className="relative tvk-games tvk-landing-page">
      <div className="topMainBanner">
        <div className="tvk-mainBanner">
          <div className="container">
            <div className="wrapper">
              <div className="txt-holder">
                <h2 className="section-title">VANRY - Powering the Vanar Blockchain</h2>
                <p className="section-detail">
                  Vanar is an L1 blockchain designed for real world adoption, based on our years of experience working with games, entertainment and brands. Vanar incorporates a series of products, including gaming, metaverse, AI and brand solutions. Our products include Virtua Metaverse and VGN games network. Vanar is powered by the VANRY token.
                </p>
                {/* <div className="prestige-text">
                  <a href="https://virtua.gitbook.io/virtua-wiki/welcome-to-the-metaverse/tvk/prestige" target="blank">
                    Learn About Prestige Program
                    <svg width="15" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13.7108 0.29665C13.3224 -0.095938 12.6892 -0.0992931 12.2966 0.289156C11.9041 0.677606 11.9007 1.31076 12.2892 1.70335L17.6038 7.07458H1C0.447715 7.07458 0 7.52229 0 8.07458C0 8.62686 0.447715 9.07458 1 9.07458H17.6038L12.2892 14.4458C11.9007 14.8384 11.9041 15.4716 12.2966 15.86C12.6892 16.2485 13.3224 16.2451 13.7108 15.8525L20.7108 8.77793C21.0964 8.38828 21.0964 7.76088 20.7108 7.37123L13.7108 0.29665Z"
                        fill="#9247FF" />
                    </svg>
                  </a>
                </div> */}
                <div className="tvk-market">
                  <div className="tvk-market-price">
                    <p className="tag">VANRY Price</p>
                    <div className="market-rate">
                      <strong>${vanryData?.vanryPrice}</strong>
                      <p className={`rates up ${vanryData?.priceChangePercentage < 0 ? "down" : "up"}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                          <path
                            d="M4.18166 0.379873L0.128263 5.95914C0.0446912 6.07846 0.000471697 6.21375 3.75293e-06 6.35155C-0.000464191 6.48934 0.0428357 6.62483 0.125596 6.74454C0.208356 6.86426 0.327691 6.96402 0.471729 7.0339C0.615767 7.10378 0.779487 7.14134 0.9466 7.14286H9.0534C9.22051 7.14134 9.38423 7.10378 9.52827 7.0339C9.67231 6.96402 9.79164 6.86426 9.8744 6.74454C9.95716 6.62483 10.0005 6.48934 10 6.35155C9.99953 6.21375 9.95531 6.07846 9.87174 5.95914L5.81834 0.379873C5.73302 0.26391 5.6129 0.168034 5.46956 0.101495C5.32622 0.034956 5.1645 0 5 0C4.8355 0 4.67378 0.034956 4.53044 0.101495C4.3871 0.168034 4.26698 0.26391 4.18166 0.379873Z"
                            fill="#fff" />
                        </svg>
                        <span className="txt">{vanryData?.priceChangePercentage}%</span>
                      </p>
                    </div>
                  </div>
                  <div className="tvk-market-price">
                    <p className="tag">Market Cap</p>
                    <div className="market-rate">
                      <strong>${vanryData?.marketCap}</strong>
                      <p className={`rates up ${vanryData?.priceChangePercentage < 0 ? "down" : "up"}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                          <path
                            d="M4.18166 0.379873L0.128263 5.95914C0.0446912 6.07846 0.000471697 6.21375 3.75293e-06 6.35155C-0.000464191 6.48934 0.0428357 6.62483 0.125596 6.74454C0.208356 6.86426 0.327691 6.96402 0.471729 7.0339C0.615767 7.10378 0.779487 7.14134 0.9466 7.14286H9.0534C9.22051 7.14134 9.38423 7.10378 9.52827 7.0339C9.67231 6.96402 9.79164 6.86426 9.8744 6.74454C9.95716 6.62483 10.0005 6.48934 10 6.35155C9.99953 6.21375 9.95531 6.07846 9.87174 5.95914L5.81834 0.379873C5.73302 0.26391 5.6129 0.168034 5.46956 0.101495C5.32622 0.034956 5.1645 0 5 0C4.8355 0 4.67378 0.034956 4.53044 0.101495C4.3871 0.168034 4.26698 0.26391 4.18166 0.379873Z"
                            fill="#fff" />
                        </svg>
                        <span className="txt">{vanryData?.priceChangePercentage}%</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="token-img">
                <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vanry-logo.png`} alt="vanry-logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="tvk-token">
          <div className="container">
            <div className="tvk-token-cards">
              <div className="wrapper">
                <div className="tvk-card tvk">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vanry_icon.png`} alt="vanry_icon" />
                  <div className="tvk-card-address">
                    <div className="address" id="address">
                      0xfe721a06Ee7Cabf05c69fF88c58Cd9cc51167017
                    </div>
                    <span
                      className="icon"
                      onClick={() => {
                        setCopyText(true);
                        window.navigator.clipboard.writeText(
                          "0xfe721a06Ee7Cabf05c69fF88c58Cd9cc51167017"
                        );
                        setTimeout(() => {
                          setCopyText(false);
                        }, 2000);
                      }}
                    >
                      {copyText && <div className="tooltip">Copied</div>}
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 6.00752C0 2.68966 2.69177 0 6.01224 0H13.3449C13.778 0 14.1291 0.350825 14.1291 0.78359C14.1291 1.21635 13.778 1.56718 13.3449 1.56718H6.01224C3.55798 1.56718 1.56841 3.55519 1.56841 6.00752V13.4327C1.56841 13.8654 1.21731 14.2163 0.784206 14.2163C0.351101 14.2163 0 13.8654 0 13.4327V6.00752Z"
                          fill="white"
                        />
                        <path
                          d="M15.8436 3.70195C12.4549 3.3235 8.98013 3.3235 5.59136 3.70195C4.62598 3.80976 3.85021 4.5689 3.73642 5.541C3.33451 8.97467 3.33451 12.4434 3.73642 15.8771C3.85021 16.8492 4.62598 17.6084 5.59136 17.7162C8.98013 18.0946 12.4549 18.0946 15.8436 17.7162C16.809 17.6084 17.5848 16.8492 17.6986 15.8771C18.1005 12.4434 18.1005 8.97467 17.6986 5.541C17.5848 4.5689 16.809 3.80976 15.8436 3.70195Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                  <p>
                    Note: This is the Contract address, do not transfer any
                    tokens to this address.
                  </p>
                </div>
                <div className="tvk-card binance">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}binance_icon.png`} alt="binance_icon" />
                  <div className="tvk-card-cta group">
                    {/* <a
                      href="https://www.binance.com/en/trade/TVK_BUSD"
                      className="custom-btn sm"
                      target="blank"
                    >
                      VANRY-BUSD
                    </a> */}
                    <a
                      href="https://www.binance.com/en/trade/VANRY_USDT?type=spot"
                      className="custom-btn sm"
                      target="blank"
                    >
                      VANRY-USDT
                    </a>
                    <a
                      href="https://www.binance.com/en/trade/VANRY_BTC?type=spot"
                      className="custom-btn sm"
                      target="blank"
                    >
                      VANRY-BTC
                    </a>
                  </div>
                </div>
                <div className="tvk-card bybit">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}bybit_icon.png`} alt="bybit_icon" />
                  <div className="tvk-card-cta group">
                    <a
                      href="https://www.bybit.com/en/trade/spot/VANRY/USDT"
                      className="custom-btn sm"
                      target="blank"
                    >
                      VANRY-USDT
                    </a>
                    <a
                      href="https://partner.bybit.com/b/66059"
                      className="custom-btn sm"
                      target="blank"
                    >
                      Referral
                    </a>
                  </div>
                </div>
                <div className="tvk-card uniswap">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}uniswap_icon.png`} alt="uniswap_icon" />
                  <div className="tvk-card-cta">
                    <a
                      href="https://etherscan.io/address/0xfe721a06Ee7Cabf05c69fF88c58Cd9cc51167017"
                      target="blank"
                      className="custom-btn sm"
                    >
                      Buy
                    </a>
                  </div>
                </div>
                <div className="tvk-card gate">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gate_icon.png`} alt="gate_icon" />
                  <div className="tvk-card-cta">
                    <a
                      href="https://www.gate.io/trade/VANRY_USDT"
                      target="blank"
                      className="custom-btn sm"
                    >
                      VANRY-USDT
                    </a>
                  </div>
                </div>
                <div className="tvk-card kucoin">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}kucoin_icon.png`} alt="kucoin_icon" />
                  <div className="tvk-card-cta group">
                    <a
                      href="https://www.kucoin.com/trade/VANRY-USDT"
                      target="blank"
                      className="custom-btn sm"
                    >
                      VANRY-USDT
                    </a>
                    <a
                      href="https://www.kucoin.com/trade/VANRY-BTC"
                      className="custom-btn sm"
                      target="blank"
                    >
                      VANRY-BTC
                    </a>
                  </div>
                </div>
                <div className="tvk-card mexc">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mexc_icon.png`} alt="mexc_icon" />
                  <div className="tvk-card-cta">
                    <a
                      href="https://www.mexc.com/exchange/VANRY_USDT?_from=search"
                      target="blank"
                      className="custom-btn sm"
                    >
                      VANRY-USDT
                    </a>
                  </div>
                </div>
                <div className="tvk-card paribu">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}paribu_icon.png`} alt="paribu_icon" />
                  <div className="tvk-card-cta">
                    <a
                      href="https://www.paribu.com/markets/vanry_tl?view=classic"
                      target="blank"
                      className="custom-btn sm"
                    >
                      VANRY-TL
                    </a>
                  </div>
                </div>
                <div className="tvk-card coinbase">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}coinbase_icon.svg`} alt="coinbase_icon" />
                  <div className="tvk-card-cta">
                    <a
                      target="blank"
                      className="custom-btn sm"
                    >
                      Coming Soon
                    </a>
                  </div>
                </div>
                <div className="tvk-card kraken">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}kraken_icon.png`} alt="kraken_icon" />
                  <div className="tvk-card-cta">
                    <a
                      target="blank"
                      className="custom-btn sm"
                    >
                      Coming Soon
                    </a>
                  </div>
                </div>
                <div className="tvk-card ascendex">
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}ascendex_icon.png`} alt="ascendex_icon" />
                  <div className="tvk-card-cta">
                    <a
                      target="blank"
                      className="custom-btn sm"
                    >
                      Coming Soon
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vanry;
