import { KeycloakLoginOptions } from "keycloak-js";
import React, { useEffect, useState } from "react";
import keycloak from "./keycloack";
import { IKeyCloakProvider, IKeyCloakProviderState } from "./types";

export const KeyCloakContext = React.createContext<IKeyCloakProvider>({});
const KeyCloakProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [contextState, setContextState] = useState<IKeyCloakProviderState>({
    keyCloakUser: null,
    isKeycloakInit: false,
    isLoging: false,
    isAuthenticated: false,
  });
  const getAlreadyLoginUser = () => {
    try {
      let ssoInfo = localStorage.getItem("sso");
      if (ssoInfo !== null && ssoInfo !== undefined) {
        const decodeUser = JSON.parse(atob(ssoInfo));
        setContextState((prevState) => ({
          ...prevState,
          ...decodeUser,
        }));
      }
    } catch (error) {}
  };
  const setUserToStorage = (userInfo: any) => {
    try {
      if (userInfo !== null && userInfo !== undefined) {
        const encodedUser = btoa(JSON.stringify(userInfo));
        localStorage.setItem("sso", encodedUser);
      } else {
        localStorage.removeItem("sso");
      }
    } catch (error) {}
  };
  const doLogin = (options?: KeycloakLoginOptions | null): void => {
    setContextState((prevState) => ({
      ...prevState,
      isLoging: true,
    }));
      keycloak?.login();
  };
  const doSignUp = (options?: KeycloakLoginOptions | null): void => {
    setContextState((prevState) => ({
      ...prevState,
      isLogging: true,
    }));
  
    if(options)
    {
    keycloak?.register(options).catch(error => {
      console.error('Sign-up failed:', error);
      
      setContextState((prevState) => ({
        ...prevState,
        isLogging: false,
      }));
    });
  }
  else
  {
    keycloak?.register().catch(error => {
      console.error('Sign-up failed:', error);
      
      setContextState((prevState) => ({
        ...prevState,
        isLogging: false,
      }));
    });
  }

  };
  
  const doLogout = async (): Promise<void> => {
    if (keycloak.authenticated === true) {
      if (keycloak.isTokenExpired(120)) {
        await keycloak.updateToken(120);
      }
      await keycloak?.logout();
    }
    setContextState((prevState) => ({ ...prevState, keyCloakUser: null }));
    setUserToStorage(null);
  };
  const updateKeyCloakToken = async () => {
    setContextState((prevState) => ({
      ...prevState,
      keyCloakUser: {
        ...(prevState.keyCloakUser ?? {}),
        refreshToken: keycloak.refreshToken,
        accessToken: keycloak.token,
      },
    }));
  };

  const getUserProfile = async () => {
    const currentUser = await keycloak.loadUserProfile();
    if (currentUser !== undefined && currentUser !== null) {
      try {
        setContextState((prevState) => ({
          ...prevState,
          isKeycloakInit: true,
          isAuthenticated: keycloak.authenticated,
          isLoging: false,
          keyCloakUser: {
            ...currentUser,
            refreshToken: keycloak.refreshToken,
            accessToken: keycloak.token,
          },
        }));
        setUserToStorage({
          keyCloakUser: {
            ...currentUser,
            refreshToken: keycloak.refreshToken,
            accessToken: keycloak.token,
          },
        });
      } catch (error: any) {
        setContextState((prevState) => ({
          ...prevState,
          isKeycloakInit: true,
          isAuthenticated: keycloak.authenticated,
          isLoging: false,
          keyCloakUser: {
            ...currentUser,
            refreshToken: keycloak.refreshToken,
            accessToken: keycloak.token,
          },
        }));
        setUserToStorage({
          keyCloakUser: {
            ...currentUser,
            refreshToken: keycloak.refreshToken,
            accessToken: keycloak.token,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (keycloak !== null) {
      keycloak.onAuthLogout = () => {
        console.info("keycloak--->> onAuthLogout");
        window.location.reload()
      };

      keycloak.onTokenExpired = () => {
        console.info("keycloak--->> onTokenExpired");
        keycloak
          .updateToken(60)
          .then(() => {
            console.log("keycloak--->> Token Refreshed");
            // eslint-disable-next-line
            updateKeyCloakToken();
          })
          .catch(() => {
            doLogout();
          });
      };
    }
  }, [keycloak]);

  useEffect(() => {

    getAlreadyLoginUser();
    // Keycloak init failure case timout
    setTimeout(() => {
      setContextState((prevState) => ({
        ...prevState,
        isKeycloakInit: true,
        isLoging: false,
      }));
    }, 20000);
    // Init keycloak
    keycloak
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        pkceMethod: "S256",
      })
      .then((authenticated: any) => {
        if (authenticated) {
          setContextState((prevState) => ({
            ...prevState,
            isLoging: true,
            isAuthenticated: authenticated,
          }));
          getUserProfile();
        } else {
          setContextState((prevState) => ({
            ...prevState,
            isKeycloakInit: true,
            isLoging: false,
            isAuthenticated: authenticated,
          }));
          doLogout();
        }
      })
      .catch((error: any) => {
        console.error("error >>> ", error)
        setContextState((prevState) => ({
          ...prevState,
          isKeycloakInit: true,
          isLoging: false,
          isAuthenticated: false,
        }));
        doLogout();
      });
  }, []);

  return (
    <KeyCloakContext.Provider value={{ contextState, doLogout, doLogin,doSignUp, getUserProfile }}>
      {children}
    </KeyCloakContext.Provider>
  );
};
export default KeyCloakProvider;
