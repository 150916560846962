import { useContext } from "react";
import { KeyCloakContext } from "./KeyCloakProvider";
import { IKeyCloakHook } from "./types";

const useKeycloakFeatures = (): IKeyCloakHook => {
  const { contextState, doLogout, doLogin,doSignUp,getUserProfile } = useContext(KeyCloakContext);
  return {
    keyCloakUser: contextState?.keyCloakUser,
    doLogout,
    doLogin,
    doSignUp,
    isLoging: contextState?.isLoging,
    isKeycloakInit: contextState?.isKeycloakInit,
    isAuthenticated: contextState?.isAuthenticated,
    getUserProfile
  };
};

export default useKeycloakFeatures;
