import React, { useEffect, useState, lazy, Suspense } from "react";
import "./whyModal.scss";
import {
  Modal,
  ModalDialog,
  ModalHolder,
} from "../../components/condo-modal/CondoModalStyled";
import {
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
  showBodyOverflow,
  hideBodyOverflow,
} from "../../constants/constant";
import "react-toastify/dist/ReactToastify.css";
import { SectionHeading } from "../../components/styledComponent";
import PopNotification from "../../components/generic/PopNotification";
import Button from "../../components/generic/button";
import { useNotifications } from "../custom-hooks/useNotifications";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
import Loader from "../../components/core/loader/loader";
import NotificationsItem from "../../components/items/NotificationsItem";
import Spinner from "../../components/core/loader/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
export const NotificationModal = ({ hideModal }) => {
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [paginatedContent, setPaginatedContent] = useState([]);
  const [loader, setLoader] = useState("initial loader");
  const [messageReadId, setMessageReadId] = useState();
  const { keyCloakUser } = useKeycloakFeatures();
  const [itemDeleted,setItemDeleted]=useState([]);
  const [isDeleting, setIsDeleting] = useState(false)

  const { notifications: fetchedNotifications } = useNotifications(
    keyCloakUser?.accessToken,
    notifications,
    setNotifications,
    setLoader,
    setMessageReadId,
    messageReadId,
    setPaginatedContent,
    setHasMore,setItemDeleted,setIsDeleting
  );

  const closeModal = () => {
    showBodyOverflow();
    hideModal();
  };

  const handleMessageRead = (id) => {
    setIsDeleting(true);
    setItemDeleted(prevItemDeleted => [...prevItemDeleted, id]);
    setMessageReadId(id);
  };


  const fetchData = () => {
    setPage((prev)=>prev+1);
    const totalPages = Math.ceil(notifications?.length / 10);
    const nextPage = page + 1;
    const startIndex = page * 10;
    const endIndex = startIndex + 10;
    setLoader("infinite loader");
    if (nextPage > totalPages) {
      setHasMore(false);
      setLoader("loaded");
      return false;
    } else {
      setTimeout(() => {
        setPaginatedContent(notifications?.slice(0, endIndex));
        setLoader("loaded");
      }, 1500);
      return;
    }
  };
  return (
    <>
      <Modal className="flex items-center flex-text-center active">
        <ModalDialog className="whyModal modal-noti-1">
          <ModalHolder className="relative modalBg">
            <div
              className="inner-content-1 notification_modal"
            >
              <SectionHeading>Notifications</SectionHeading>
              {loader === "initial loader" && (
                <div className="notification-loader">
                  <Loader />
                </div>
              )}
              {paginatedContent?.length > 0 ? (
                <>
                  {/* Unread Notifications */}
                  {paginatedContent.filter(
                    (notification) => !notification.is_read
                  ).length > 0 && (
                    <>
                      <h2 className="text_size">Unread Notifications</h2>
                      <ul className="list-none list-holder"  id="scrollableDiv">
                        {paginatedContent.map((notification,index) => (
                          <NotificationsItem
                            index={index}
                            notifications={notification}
                            header="unread"
                            handleMessageRead={handleMessageRead}
                            loader={loader}
                            itemDeleted={itemDeleted}                          />
                        ))}
                          {/* {loader === "removed item" && <Spinner />} */}
                      </ul>
                    </>
                  )}

        
                  <InfiniteScroll
                    dataLength={paginatedContent?.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={hasMore}
                    loader={loader === "infinite loader" && <Spinner />}
                    scrollableTarget="scrollableDiv"
                  />
                </>
              ) : (
                <>
                  {loader === "loaded" &&
                    (notifications === undefined ||
                      notifications.length === 0) && (
                      <p className="noti-text">
                        No new notifications to display
                      </p>
                    )}
                </>
              )}
            </div>
            <div className="crossIcon" onClick={closeModal}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <path
                    d="M31.2765 15.1433C31.9448 14.4751 31.9448 13.3916 31.2765 12.7234C30.6083 12.0551 29.5248 12.0551 28.8565 12.7234L21.9999 19.58L15.1434 12.7234C14.4751 12.0551 13.3916 12.0551 12.7234 12.7234C12.0551 13.3916 12.0551 14.4751 12.7234 15.1433L19.58 21.9999L12.7234 28.8566C12.0551 29.5248 12.0551 30.6083 12.7234 31.2765C13.3916 31.9448 14.4751 31.9448 15.1433 31.2765L21.9999 24.4199L28.8566 31.2765C29.5248 31.9448 30.6083 31.9448 31.2765 31.2765C31.9448 30.6083 31.9448 29.5248 31.2765 28.8566L24.4199 21.9999L31.2765 15.1433Z"
                    fill="white"
                  />
                </svg>
              </span>
              {/* <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}crossicon.svg`} alt="crossIcon" onClick={closeModal} /> */}
            </div>
          </ModalHolder>
        </ModalDialog>
      </Modal>
      <PopNotification />
    </>
  );
};
