import React from "react";
import "./CollectReward.scss";
import styled from "styled-components";
import { Para, SectionHeading } from "../../../components/styledComponent";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import { BannerText } from "../hero-section/HeroSectionStyled";
import Button from "../../../components/generic/button";
import { Link } from "react-router-dom";

function CollectReward() {
  const GradientEffect = styled.div`
    position: absolute;
    bottom: -15px;
    left: 0;
    content: "";
    background: linear-gradient(
      180deg,
      rgba(12, 3, 26, 0) 0%,
      rgba(12, 3, 26, 0.3) 48.54%,
      #06001c 100%
    );
    width: 100%;
    z-index: 2;
    height: 240px;
  `;

  return (
    <>
      <div className="collect-reward">
        <div className="banner">
          <div className="text-holder">
            <img
              className="slide-bg web"
              src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}collect-rewardCard.webp`}
              alt="collect-reward"
            />
            <div className="content">
              <SectionHeading>Collect Rewards</SectionHeading>
              <Para>
                Experience new economies.
                <br />
                Play your favourite games, get rewarded and show off to your
                friends, or rivals!
              </Para>
              <Link to="/games">
                <Button border0 upper secondary>
                  collect Now
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <GradientEffect />
      </div>
    </>
  );
}

export default CollectReward;
