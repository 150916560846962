import React, { createContext, useState, useContext } from 'react';

// Create the context
const MyContext = createContext();

// Create a provider component
export const ProviderAppStatus = ({ children }) => {
  const [state, setState] = useState(false);

  const updateState = (newState) => {
    setState(newState);
  };

  return (
    <MyContext.Provider value={{ state, updateState }}>
      {children}
    </MyContext.Provider>
  );
};

// Custom hook for using context
export const useMyContext = () => {
  return useContext(MyContext);
};
