import React from "react";
import styled from "styled-components";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import { Video } from "../hero-section/HeroSectionStyled";
import "./Connect.scss";
import { Para, SectionHeading } from "../../../components/styledComponent";
import Button from "../../../components/generic/button";
import { Link } from "react-router-dom";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(0deg, rgba(6, 0, 28, 0) 49.91%, #06001c 94.15%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 61.57%, #06001c 100%),
    linear-gradient(0deg, rgba(6, 0, 28, 0.6) 0%, rgba(6, 0, 28, 0.6) 100%);
  width: 100%;
  z-index: 2;
  height: 100%;
`;

const ConnectSection = () => {
  return (
    <>
      <div className="connect-section">
        <div className="banner">
          <GradientEffect />
          <Video
            poster={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}connect-posterImg.webp`}
            cribVideo
            autoPlay
            loop
            muted
            playsInline
          >
            <source
              src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}connect-video.mp4`}
              type="video/mp4"
            />
          </Video>
          <div className="text-holder">
            <SectionHeading>
              Connect <span className="d-block">with Others</span>
            </SectionHeading>
            <Para>
              Virtua is redefining virtual gaming through the power of web3.
              Play games, hang out and compete with your friends to earn the top
              place in our leaderboards!
            </Para>
            <Link to="/virtual-world">
              <Button border0 upper secondary>
                CONNECT NOW
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectSection;
