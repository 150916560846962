import "./Partner.scss";
import React from "react";
import { SectionHeading } from "../../../components/styledComponent";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import JoinVirtua from "../join-virtua/JoinVirtua";
import useKeycloakFeatures from "../../../keycloak/useKeycloakFeatures";

const PartnerSection = () => {
    const { keyCloakUser } = useKeycloakFeatures();
    return (
        <section className="partner-section">
                <>
                    <div className="main-content">
                        <SectionHeading>
                            Partners
                            <span className="d-block">& Integrations</span>
                        </SectionHeading>
                    </div>
                    <div className="cardlisting partner_list">
                        <div className="d-grid-1">
                            <div className="custom-col-width">
                                <div className="card">
                                    <div className="card-img">
                                        <img
                                            className="img"
                                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vanar-partnerImg.webp`}
                                            alt="vanar-partnerImg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-col-width">
                                <div className="card">
                                    <div className="card-img">
                                        <img
                                            className="img"
                                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}viva-partnerImg.webp`}
                                            alt="viva-partnerImg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-col-width">
                                <div className="card">
                                    <div className="card-img">
                                        <img
                                            className="img"
                                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}bazaa-partnerImg.webp`}
                                            alt="bazaa-partnerImg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-col-width">
                                <div className="card">
                                    <div className="card-img">
                                        <img
                                            className="img"
                                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}maians-partnerImg.webp`}
                                            alt="maians-partnerImg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-col-width">
                                <div className="card">
                                    <div className="card-img">
                                        <img
                                            className="img"
                                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}nitro.webp`}
                                            alt="nitro-partnerImg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-col-width">
                                <div className="card cactus">
                                    <div className="card-img">
                                        <img
                                            className="img"
                                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}big-cactus.webp`}
                                            alt="cactus-partnerImg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-col-width">
                                <div className="card cardano">
                                    <div className="card-img">
                                        <img
                                            className="img"
                                            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}cardano.webp`}
                                            alt="cardano-partnerImg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            <JoinVirtua />
        </section>
    );
};

export default PartnerSection;
