import React, { useEffect, useState } from "react";
import { updatePasscodeAPI } from "../../keycloak/keycloakApis";
import strings from "../../utils/strings";
import { setPasscodePopupBoolean } from "../../utils/helpers/functions";
import {
  Modal,
  ModalDialog,
  ModalHolder,
} from "../../components/condo-modal/CondoModalStyled";
import { showBodyOverflow,hideBodyOverflow } from "../../constants/constant";
import { SectionHeading } from "../../components/styledComponent";
import Button from "../../components/generic/button";
import "./whyModal.scss";
import { useMessagesHandler } from "../../providers/ProviderMessagesHandler";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
const PasscodeModal = ({ profile,setIsOpenModal }) => {
  const { getUserProfile } = useKeycloakFeatures();
  // const [isOpenModal, setIsOpenModal] = useState(false);
  const [genPasscode, setGenPasscode] = useState("");
  const [isCopy, setIsCopy] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [inputPasscodeVal, setInputPasscodeVal] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setError, setSuccess } = useMessagesHandler();

  const generateRandomString = (length) => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = 0; i <= length; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      result += chars.substring(randomNumber, randomNumber + 1);
    }
    setGenPasscode(result);
  };

  const jwtToJson = (token) => {
    const base64Url = token?.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const passcodeAdded = JSON.parse(
      localStorage.getItem("passcodeAdded") || "false"
    );
    const payload = JSON.parse(jsonPayload);
    if (payload?.has_passcode !== true && passcodeAdded !== true) {
      setIsOpenModal(true);
    } else {
      setPasscodePopupBoolean(true);
    }
  };

  const handleConfirm = () => {
    if (inputPasscodeVal === "") {
      setHasError(true);
      return;
    }
    if (inputPasscodeVal !== genPasscode) {
      setError("Please enter a valid passcode", "Validation Error");
      return;
    }
    setIsLoading(true);
    setHasError(false);
    updatePasscodeAPI(inputPasscodeVal).then((res) => {
      if (res.success) {
        setSuccess(res.message || "Success", "", "OK", () => {
          closeModal();
          setPasscodePopupBoolean(true);
        });
        getUserProfile();
        localStorage.setItem('passcodeAdded', JSON.stringify(true));
        setIsOpenModal(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setError(res.message || strings.try_again_later);
      }
    });
  };

  useEffect(() => {
    if (profile?.accessToken) {
      jwtToJson(profile.accessToken);
    } else {
      setPasscodePopupBoolean(false);
    }
  }, [profile]);

  useEffect(() => {
    hideBodyOverflow();
    const timeOut = setTimeout(() => {
      setIsCopy(false);
    }, 1500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [isCopy]);

  const closeModal = () => {
    showBodyOverflow();
    setIsOpenModal(false);
    document.body.classList.remove("registration");
  };
  return (
    <>
        <Modal className="flex items-center flex-text-center active">
          <ModalDialog className="whyModal modal-noti">
            <ModalHolder className="relative modalBg">
              <div className="inner-content-1 notification_modal">
                <SectionHeading className="text-center">
                  Secret Passcode
                </SectionHeading>
                <p className="m-0 text-center name">
                  {isConfirm
                    ? "One step away, Enter Passcode and confirm to access the dashboard"
                    : "Write down this Secret Passcode and save it in a place that you trust and only you can access."}
                </p>
                {!isConfirm && (
                  <div className="passcode-modal-content">
                    <h6 className="fs-14">Remember:</h6>
                    <ul className="p-0 m-0">
                      <li className="list">
                        Virtua can’t recover your Secret Passcode.
                      </li>
                      <li className="list">
                        Never share your Secret Passcode with anyone.
                      </li>
                    </ul>
                  </div>
                )}
                <div className="passcode-modal-button mt-30">
                  {isConfirm ? (
                    <input
                      type="text"
                      name="passcode"
                      className={`pass mt-20 ${hasError ? "error" : ""}`}
                      // style={hasError ? { border: "2px solid red" } : {}}
                      value={inputPasscodeVal}
                      onChange={(e) => {
                        setInputPasscodeVal(e.target.value);
                      }}
                      placeholder="Enter your secret passcode"
                    />
                  ) : (
                    <>
                      {genPasscode && (
                        <>
                          <input
                            className="pass"
                            type="text"
                            readOnly
                            value={genPasscode}
                          />
                          <div
                            onClick={() => {
                              navigator.clipboard.writeText(genPasscode);
                              setIsCopy(true);
                              setIsNext(true);
                            }}
                            style={{ pointerEvents: isCopy ? "none" : "auto" }}
                          >
                            <Button border0 upper secondary className="cop-pass">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="pass-svg"
                              >
                                <path
                                  d="M14.5 0C15.3284 0 16 0.671562 16 1.5V10.5C16 11.3284 15.3284 12 14.5 12H5.5C4.67156 12 4 11.3284 4 10.5V1.5C4 0.671562 4.67156 0 5.5 0H14.5ZM5.5 13C4.1215 13 3 11.8785 3 10.5V4H1.5C0.671562 4 0 4.67156 0 5.5V14.5C0 15.3284 0.671562 16 1.5 16H10.5C11.3284 16 12 15.3284 12 14.5V13H5.5Z"
                                  fill="#9247FF"
                                ></path>
                              </svg>
                              {isCopy ? "Copied!" : "Copy Passcode"}
                            </Button>
                          </div>
                          <Button
                            border0 upper secondary
                            className={`w-100 mt-20 ${
                              !isNext ? "disabled-next-btn" : ""
                            }`}
                            disabled={!isNext}
                            onClick={() => {
                              setIsConfirm(true);
                            }}
                          >
                            Next
                          </Button>
                        </>
                      )}
                      {!genPasscode && (
                        <Button
                          className="w-100"
                          border0
                          upper
                          secondary
                          onClick={() => {
                            generateRandomString(50);
                          }}
                        >
                          Generate Secret PASSCODE
                        </Button>
                      )}
                    </>
                  )}
                  {isConfirm && (
                    <Button
                      border0 upper secondary
                      className="w-100"
                      disabled={isLoading}
                      onClick={handleConfirm}
                    >
                      {isLoading ? "loading..." : "Confirm"}
                    </Button>
                  )}
                </div>
              </div>
              {/* <div className="crossIcon">
                <span onClick={closeModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                  >
                    <path
                      d="M31.2765 15.1433C31.9448 14.4751 31.9448 13.3916 31.2765 12.7234C30.6083 12.0551 29.5248 12.0551 28.8565 12.7234L21.9999 19.58L15.1434 12.7234C14.4751 12.0551 13.3916 12.0551 12.7234 12.7234C12.0551 13.3916 12.0551 14.4751 12.7234 15.1433L19.58 21.9999L12.7234 28.8566C12.0551 29.5248 12.0551 30.6083 12.7234 31.2765C13.3916 31.9448 14.4751 31.9448 15.1433 31.2765L21.9999 24.4199L28.8566 31.2765C29.5248 31.9448 30.6083 31.9448 31.2765 31.2765C31.9448 30.6083 31.9448 29.5248 31.2765 28.8566L24.4199 21.9999L31.2765 15.1433Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div> */}
            </ModalHolder>
          </ModalDialog>
        </Modal>
      
    </>
  );
};

export default PasscodeModal;
