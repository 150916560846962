import React, { useEffect, useState } from "react";
import '../condo-modal/condoModal.scss';
import { CrossIcon, Modal, ModalDialog, ModalHolder } from "../condo-modal/CondoModalStyled";
import { showBodyOverflow, REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { Input } from "../form/input";
import { apiBaseuUrl, emailRegex } from "../../utils/base-url";
import axios from "axios";



export const GameModal = ({ hide }) => {
    const [emailError, setEmailError] = useState("");
    const [emailSuccessMsg, setEmailSuccessMsg] = useState("");
    const [emailVal, setEmailVal] = useState("");
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        showBodyOverflow();
        hide()
    };
    const removeMsg = () => {
        emailError && setEmailError("");
        emailSuccessMsg && setEmailSuccessMsg("");
    };

    const handleClick = () => {
        if (!emailVal) {
            setEmailError("Please enter email.");
        } else if (!emailVal.match(emailRegex)) {
            setEmailError("Please enter valid email.");
        } else {
            setEmailError("");
            setLoading(true);
            axios
                .post(apiBaseuUrl, { email: emailVal.toLocaleLowerCase(), project_identifier: 'games' })
                .then((res) => {
                    if ([200, 201].includes(res.status)) {
                        setEmailVal("");
                        setLoading(false);
                        setEmailSuccessMsg(res.data.message);
                    }
                })
                .catch((res) => {
                    setLoading(false);
                    setEmailError(res?.response?.data?.message);
                });
        }
    };

    useEffect(() => {
        let interval = setInterval(removeMsg, 3000);
        return () => clearInterval(interval);
    }, [emailError, emailSuccessMsg]);

    return (
        <>
            <Modal className="flex items-center flex-text-center active">
                <ModalDialog>
                    <ModalHolder className="relative game-modal">
                        <div className="inner-content ">
                            <div className="modal-title text-center">
                                <h3>A Gaming Metaverse</h3>
                                <p className="">
                                    Join us on our journey across a gamified metaverse. Reach out to us and stay updated by providing your email below. &nbsp;
                                </p>
                            </div>
                            <div className="input-holder">
                                <Input
                                    type="email"
                                    placeholder="Email Address"
                                    onChange={(e) => setEmailVal(e.target.value)}
                                    value={emailVal}
                                />
                                {emailError && (
                                    <small className="infoTxt error">{emailError}</small>
                                )}
                                {emailSuccessMsg && (
                                    <small className="infoTxt success">
                                        {emailSuccessMsg}
                                    </small>
                                )}
                            </div>

                            <div className="cta">
                                <div className="custom-btn icon-btn lg dark"  onClick={handleClick} style={{ pointerEvents: loading ? 'none' : 'auto' }}>{loading ? 'Loading' : "Let's Game"}</div>
                            </div>
                        </div>
                        <CrossIcon>
                            <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}crossicon.svg`} alt="crossIcon" onClick={closeModal} />
                        </CrossIcon>
                    </ModalHolder>
                </ModalDialog>
            </Modal>
        </>
    );
};

