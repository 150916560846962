import { Link } from "react-router-dom";
import "./ComponentNotFound.scss";
import {REACT_APP_S3_CLOUDFLARE_IMAGE_URL} from "../../constants/constant";

const ComponentNotFound = () => {
  return (
    <>
      <section className="not-found">
        <div className="not-found-content">
          <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}white-logo.svg`} alt="logo" />
          <h2>Page Not Found</h2>
          <p>The page you’re looking for cannot be found.</p>
          <Link to="/">Go to Homepage</Link>
        </div>
      </section>
    </>
  );
};

export default ComponentNotFound;
