export const METAVERSE_URL = process.env.REACT_APP_METAVERSE_URL;
export const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL;
export const CONTACT_US_URL = process.env.REACT_APP_CONTACT_US_URL;
export const ISLAND_URL = process.env.REACT_APP_ISLAND_URL;
export const BOTS = process.env.REACT_APP_BOTS_URL;
export const LABS = process.env.REACT_APP_LABS_URL;
export const FOUNDER = process.env.REACT_APP_FOUNDER_URL;
export const DASHBOARD = process.env.REACT_APP_DASHBOARD_URL;
export const VGN = process.env.REACT_APP_VGN_URL;
export const CARDANO = process.env.REACT_APP_CARDANO_URL;
export const CLAIM = process.env.REACT_APP_CLAIM_URL;
export const STAKE = process.env.REACT_APP_STAKE_URL;
export const RACERS = process.env.REACT_APP_RACERS_URL;
export const VANRY = process.env.REACT_APP_VANRY_URL;
export const META_TAG_ROBOTS = process.env.REACT_APP_META_TAG_ROBOTS;
export const GAMER_LOUNGE = process.env.REACT_APP_GAMER_LOUNGE_URL;