import React, { useEffect, useState } from "react";
import './condoModal.scss';
import { CrossIcon, Modal, ModalDialog, ModalHolder } from "./CondoModalStyled";
import Button from "../generic/button";
import { showBodyOverflow, REACT_APP_S3_CLOUDFLARE_IMAGE_URL, REACT_APP_VIRTUA_BACKEND_BASE_URL } from "../../constants/constant";
import { getAbuseContent, reportAbuseContent } from "../../utils/helpers/asyncfunctions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
import PopNotification from "../generic/PopNotification";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
import methods from "../common/api-calls/methods";


export const CondoModal = ({ hide, cribDoc }) => {
    const [abuse, setAbuse] = useState([]);
    const [reason, setReason] = useState("");
    const [isClickable,setIsClickable]=useState(true);
    const [description, setDescription] = useState("");
    const [iP, setIP] = useState("");
    const [error, setError] = useState(false)
    const [captchaToken, setCaptchaToken] = useState("")
    const [isLoading, setLoading] = useState(true)
    const [loader, setLoader] = useState(false)
    const {keyCloakUser} = useKeycloakFeatures()
    function onChange(value) {
        setCaptchaToken(value)
    }

    const closeModal = () => {
        showBodyOverflow();
        hide()
    };

    useEffect(() => {
        getContent()
        getUserIpAddress();
    }, [])

    const extractIp = (ip) => {
        if (ip.startsWith('::ffff:')) {
            return ip.split(':').pop();
        }
        return ip;
    };

    const getUserIpAddress = async () => {
        const res = await methods.get(`${REACT_APP_VIRTUA_BACKEND_BASE_URL}/auth/client-ip`,keyCloakUser?.accessToken);
        setIP(extractIp(res.ip))
    }

    const getContent = async () => {
        const data = []
        try {
            const querySnapshot = await getAbuseContent();
            querySnapshot?.forEach((doc) => {
                data.push(doc.data());
            })
            setAbuse(data)
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }
    const successNotification = () => {
        toast.success("Reported! Thank you for your feedback.");
    }
    const errorNotification = () => {
        toast.error("Something! Went wrong try again.")
    }
    const handleSubmit = (e) => {
        setLoader(true);
        setIsClickable(false);
        e.preventDefault();
        if (!iP) {
            toast.error("Please enable location from your browser.");
            setLoader(false)
        } else {
            if (reason === 'Other' && description === '') {
                setError(true)
                setLoader(false)
                setIsClickable(prev => !prev);
            } else {
                if (captchaToken) {
                    setIsClickable(false);
                    reportAbuseContent(reason, iP, cribDoc, description, closeModal, captchaToken, successNotification, setLoader, errorNotification);
                }
            };
        }
    }
    return (
        <>
            <Modal className="flex items-center flex-text-center active">
                <ModalDialog>
                    <ModalHolder className="relative">
                        <div className="inner-content">
                            <h3>Report Abuse</h3>
                            <p className="title">
                                How does this experience break the &nbsp;
                                <span>
                                    <a href="https://virtua.com/user-guidelines"
                                        target="_blank"> code of conduct?</a>
                                </span>
                            </p>
                            <form>
                                {isLoading ? (
                                    <div className="loader-container">
                                        <div className="img_loader">
                                            <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}tv_loader.gif`} alt="loader" />
                                        </div>
                                    </div>
                                ) : (
                                    <ul>
                                        {abuse?.map((item, index) => (
                                            <li key={index}>
                                                <input type="radio" id={index} value={item.value} name="reason"
                                                    onChange={(e) => {
                                                        setReason(e.target.value)
                                                        if (item.value !== 'Other') {
                                                            error && setError(false)
                                                            description && setDescription('')
                                                        }
                                                    }}
                                                />
                                                <div className="check" />
                                                <label htmlFor={index}>{item.value}</label>
                                            </li>
                                        ))
                                        }
                                    </ul>
                                )
                                }

                                <p className="bottom-title">You can also include relevant details on the offensive content</p>
                                <textarea
                                    placeholder='Add Details Here'
                                    value={description}
                                    className={`textarea-holder ${reason ? error ? "as-error" : "" : "disabled opacity-7"}`}
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                        error && setError(false)
                                    }}
                                />
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={onChange}
                                    className="recaptcha"
                                />
                                <Button
                                    fullWidth upper secondary
                                    className={`mt-3 btn-height ${reason && captchaToken && isClickable ? "loader" : "disabled opacity-7"}`}
                                    onClick={(e) => handleSubmit(e)}

                                >
                                    {loader ? <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}tv_loader.gif`} alt="loader" /> : "Report Abuse"}
                                </Button>
                            </form>
                        </div>
                        <CrossIcon>
                            <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}crossicon.svg`} alt="crossIcon" onClick={closeModal} />
                        </CrossIcon>
                    </ModalHolder>
                </ModalDialog>
            </Modal>
            <PopNotification />
        </>
    );
};

