import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
  SOCIAL_ICONS,
} from "../../../constants/constant";
import "./footer.scss";
import { Input } from "../../form/input";
import { SocialLinks } from "../../styledComponent";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseuUrl, emailRegex } from "../../../utils/base-url";
import { BOTS, ISLAND_URL, LABS, METAVERSE_URL } from "../../../enviroment";
import { MARKETPLACE_URL, CONTACT_US_URL } from "../../../enviroment";

const Icon = styled.img`
  width: 20px;
  max-height: 20px;
  @media (max-width: 420px) {
    width: 15px;
    height: 15px;
  }
`;
const FooterContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  @media (max-width: 600px) {
    padding: 0 15px;
  }
`;
const Title = styled.strong`
  margin: 0 0 32px;
  font-size: 22px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 267.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  @media (max-width: 900px) {
    margin: 0 0 8px;
    font-size: 18px;
  }
`;
const SocialWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  @media (max-width: 1400px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 900px) {
    flex-direction: row;
    margin-top: 5px;
  }
  @media (max-width: 360px) {
  }
`;

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <FooterContainer className="footer-content">
          <div className="footer-top">
            <div className="flex items-center web-footer-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  d="M9.23077 9.23077H38.7692V38.7692H9.23077V9.23077Z"
                  fill="white"
                />
                <path
                  d="M24.0177 7.16551e-06C20.863 -0.00243055 17.7387 0.61714 14.8236 1.82328C11.9086 3.02942 9.25992 4.79845 7.02919 7.02919C4.79845 9.25992 3.02942 11.9086 1.82328 14.8236C0.617142 17.7387 -0.00243055 20.863 7.16549e-06 24.0177C0.00843324 37.0646 10.9354 47.9916 23.9823 48C27.1368 48.0022 30.2609 47.3825 33.1757 46.1764C36.0906 44.9703 38.7391 43.2014 40.9697 40.9709C43.2004 38.7404 44.9695 36.092 46.1758 33.1773C47.3821 30.2625 48.002 27.1385 48 23.984C47.9899 10.9387 37.0629 0.0118037 24.0177 7.16551e-06ZM26.1697 36.4007C25.863 36.9062 25.5411 37.5601 24.8013 37.5601C24.1946 37.5601 23.679 36.9618 23.4076 36.4613L11.205 14.2367C10.868 13.6486 10.5848 12.9711 10.5309 12.3122C10.5002 12.0758 10.5213 11.8356 10.5927 11.6082C10.6642 11.3808 10.7842 11.1717 10.9445 10.9953C11.1049 10.819 11.3016 10.6796 11.5212 10.5869C11.7408 10.4942 11.9779 10.4504 12.2161 10.4585C15.0102 10.4433 18.3183 10.4247 21.1107 10.4736C22.2583 10.4938 23.0807 10.9792 23.6554 12.147C24.9513 14.7911 25.4164 16.7072 27.0646 19.0969C27.1337 19.1986 27.2097 19.2954 27.2921 19.3867C29.2368 21.5017 33.8914 22.8937 37.4741 16.6297L26.1697 36.4007Z"
                  fill="#9247FF"
                />
              </svg>
              {/* <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtua-footer.svg`} alt="virtua" /> */}
              <span className="title">
                Virtua is a registered trademark of Virtua Limited. All logos
                are registered trademarks of their respective owners. All
                content of this document, unless otherwise credited, are
                copyright @ {new Date().getUTCFullYear()} Virtua Limited.
              </span>
            </div>
            <div className="footer-buttons">
              <a
                href="https://apps.apple.com/pk/app/virtua-metaverse/id1597744060"
                target="_blank"
                className="btn"
              >
                <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}ios.svg`}
                    alt="ios"
                  />
                </span>
                App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.terravirtua.prod"
                target="_blank"
                className="btn"
              >
                <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android.svg`}
                    alt="android"
                  />
                </span>
                Google Play
              </a>
            </div>
          </div>
          <div className="footer-tabs">
            <div className="footer-tabs-inner">
              <div className="footer-col">
                <Title>Explore</Title>
                <ul className="list-none">
                  <li>
                    <a href={METAVERSE_URL}>Virtua Prime</a>
                  </li>
                  <li>
                    <a href={ISLAND_URL}>Virtua Island</a>
                  </li>
                  <li>
                    <a href={BOTS}>Land Bots</a>
                  </li>
                  {/* <li>
                      <a href={LABS}>Virtua Labs</a>
                    </li> */}
                </ul>
              </div>
              <div className="footer-col">
                <Title>About</Title>
                <ul className="list-none">
                  <li>
                    <a
                      href="https://virtua.gitbook.io/virtua-wiki/"
                      target={"_blank"}
                    >
                      Wiki
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://virtua.gitbook.io/virtua-wiki/welcome-to-the-metaverse/the-virtua-team"
                      target={"_blank"}
                    >
                      Our Team
                    </a>
                  </li>
                  <li>
                    <a href="https://blog.virtua.com/" target={"_blank"}>
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-col">
                <Title>Links</Title>
                <ul className="list-none">
                  <li>
                    <a href={MARKETPLACE_URL}>Marketplace</a>
                  </li>
                  {/* <li>
                      <a
                        href="https://virtua.gitbook.io/virtua-wiki/welcome-to-the-metaverse/tvk/prestige"
                        target={"_blank"}
                      >
                        Prestige Program
                      </a>
                    </li> */}
                  {/* <li>
                    <Link to="/vanry">VANRY</Link>
                  </li> */}
                  <li>
                    <a href={CONTACT_US_URL} target={"_blank"}>
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-col">
                <Title>Follow us</Title>
                <SocialWrapper className="list-none footer-social-icons" social>
                  {SOCIAL_ICONS.map((item) => (
                    <li key={item}>
                      <SocialLinks
                        className="flex items-center flex-text-start"
                        href={item.href}
                        target="_blank"
                      >
                        <Icon src={item.src} alt={item.alt} />{" "}
                        <span>{item.text}</span>
                      </SocialLinks>
                    </li>
                  ))}
                </SocialWrapper>
              </div>
            </div>
          </div>
        </FooterContainer>
        <div className="footer-bottom-list">
          <FooterContainer>
            <div className="bottom-wrap">
              <ul className="list-none flex items-center user-guidelines">
                <li>
                  <Link to="/user-guidelines" target="_blank" rel="noreferrer">
                    User Guidelines
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-service" target="_blank" rel="noreferrer">
                    Terms of Service
                  </Link>
                </li>
              </ul>
              {/* <div className="flex items-center powered-text">
              Powered by
              <a href={process.env.REACT_APP_VANARCHAIN_URL} target="_blank" rel="noreferrer">
                <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vanar.svg`} alt="powered-by-vanar" />
              </a>
            </div> */}
            </div>
          </FooterContainer>
        </div>
      </footer>
    </>
  );
};

export default Footer;
