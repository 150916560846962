import styled from "styled-components";
import theme from "../../assets/theme";

const Button = styled.button`
  z-index: ${(props) => (props.cribButton ? "8" : "")};
  transition: all 0.3s ease;
  background: ${(props) =>
    theme.bgColors[Object.keys(props).find((color) => theme.bgColors[color])] ||
    theme.bgColors.primary};
  text-transform: ${(props) => (props.upper ? "uppercase" : "none")};
  font-weight: 500;
  color: ${(props) =>
    theme.colors[Object.keys(props).find((color) => theme.colors[color])] ||
    theme.colors.default};
  padding: ${(props) => (props.footerButton ? "10px 20px" : "15px 25px")};
  border: 2px solid
    ${(props) =>
    theme.borders[
    Object.keys(props).find((border) => theme.borders[border])
    ] || theme.borders.bprimary};
  border-radius: 100px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 18px;
  min-width: ${(props) => (props.btnWidth ? "192px" : "220px")};
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.fullWidth ? "100%" : "")};
  font-family: ${(props) => (props.clashDisplay ? "clash Display" : "")};
  &:hover {
    background: linear-gradient(86deg, #9247FF 0%, #AE01FF 100%);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
        background: #9247FF;
    }
}
  @media (max-width: 1183px) {
    padding: ${(props) => (props.footerButton ? "10px 15px" : "")};
  }
  @media (max-width: 768px) {
    padding: ${(props) => (props.footerButton ? "10px 15px" : "12px 15px")};
    font-size: 14px;
    margin-left: ${(props) => (props.left ? "auto" : "")};
    margin-right: ${(props) => (props.left ? "auto" : "")};
    position: ${(props) => (props.responsive ? "absolute" : "")};
    bottom: ${(props) => (props.mblresponsive ? "calc(100vh - 75%)" : "")};
    left: ${(props) => (props.responsive ? "50%" : "")};
    transform: ${(props) => (props.responsive ? "translateX(-50%)" : "")};
    height: 38px;
  }
  @media (max-width: 600px) {
    min-width: 140px;
  }
`;

export default Button;
