import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from '../../../constants/constant'
const Loader = () => {
  return (
    <div className="loader-container">
    <div className="img_loader">
      <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}tv_loader.gif`} alt="loader" />
    </div>
  </div>
  )
}

export default Loader
