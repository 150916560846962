import { Para, SubTitle } from "../../../../components/styledComponent";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../../constants/constant";
import { BannerImage } from "../../../home/hero-section/HeroSectionStyled";
import "./firstSection.scss";
export const FirstSection = () => {
  return (
    <>
      <BannerImage src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}videoImg1.jpg`} alt="poster-img" />
      <div className="text-overlay">
        <div className="flex items-center flex-wrap">
          <div className="main-title">
            <SubTitle left>Enter the Pioneer Zone!</SubTitle>
            <Para>
              This is the area where the elites of Terra Prime will reside. This
              area represents the central area of Terra Prime
            </Para>
          </div>
          <a href="/">
            <img className="video-icon" src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}video.png`} alt="video" />
          </a>
        </div>
      </div>
    </>
  );
};
