import React, { useEffect } from "react";
import "./Developers.scss";
import styled from "styled-components";
import { Para, SectionHeading } from "../../components/styledComponent";
import MetricsGame from "./metrics-games/Metrics";
import VirtuaSdk from "./virtua-sdk/VirtuaSdk";
import LoginFormContainer from "../../containers/LoginFormContainer";

function Developers() {
  const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #06001C 100%
  );
  width: 100%;
  z-index: 2;
  height: 322px;
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="developer-banner">
        {/* <div className="mainBanner h-screen">
          <div className="relative">
            <div className="container">
              <div className="wrapper">
                <div className="txt-holder">
                  <SectionHeading>
                    Join  Virtua  Now!
                  </SectionHeading>
                  <Para>
                    At Virtua, we believe in expanding your gaming experiences and taking them to the next level. through the power of web, we increase the value of your games for both you as a developer, and fro the gamers who enjoy your game.
                  </Para>
                </div>
              </div>
            </div>
            <GradientEffect />
          </div>
        </div> */}
        <MetricsGame />
        <VirtuaSdk />
        <LoginFormContainer />
      </div>
    </>
  );
}

export default Developers;
