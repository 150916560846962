/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  getPublicCribsListing,
  fetchMetaverseConfig,
  handleLikeDislike,
  inviteLikeCounter,
} from "../../utils/helpers/asyncfunctions";
import "./metaverseHomePage.scss";
import { useModal } from "../custom-hooks/useModal";
import {
  hideBodyOverflow,
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
  REACT_APP_VIRTUA_BACKEND_BASE_URL,
} from "../../constants/constant";
import { CondoModal } from "../../components/condo-modal/CondoModal";
import CribCard from "../../components/generic/cribCard/CribCard";
import PopNotification from "../../components/generic/PopNotification";
import { TitleHeading } from "../../components/styledComponent";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
import methods from "../../components/common/api-calls/methods";
import { Link } from "react-router-dom";

const Listing = () => {
  const [cribList, setCribList] = useState([]);
  const [condoList, setCondoList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [experienceLink, setExperienceLink] = useState("");
  const [copyLink, setCopyLink] = useState("");
  const { isShowing, toggle } = useModal();
  const [ip, setIP] = useState("");
  const [selectedCrib, setSelectedCrib] = useState("");
  const { keyCloakUser } = useKeycloakFeatures()

  useEffect(() => {
    fetchConfigurations();
  }, []);

  useEffect(() => {
    fetchCondoCrib();
  }, []);

  useEffect(() => {
    if (copyLink) {
      setTimeout(() => {
        setCopyLink(null);
      }, 2000);
    }
  }, [copyLink]);

  useEffect(() => {
    getUserIpAddress();
  }, []);

  const fetchCondoCrib = async () => {
    try {
      setLoading(true);
      const crib = await getPublicCribsListing(
        1,
        "crib",
        "",
        "",
        true,
        "favorite_count"
      );
      const condo = await getPublicCribsListing(
        1,
        "condo",
        "",
        "",
        true,
        "favorite_count"
      );
      const cribData = crib;
      const condoData = condo;
      setCribList([...cribData.data.slice(0, 5)]);
      setCondoList([...condoData.data.slice(0, 5)]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onImageError = (e) => {
    e.target.src = REACT_APP_S3_CLOUDFLARE_IMAGE_URL + "logo_round_wht.svg";
    e.target.classList.add("placeholder");
  };

  const fetchConfigurations = async () => {
    await fetchMetaverseConfig((snapshot) => {
      setExperienceLink(snapshot.data()?.metaverse_url);
    });
  };

  const handleClickToLikeCrib = async (cribCondo, cribIndex) => {
    const tempList = cribCondo === "crib" ? [...cribList] : [...condoList];
    const currentCrib = tempList[cribIndex];
    currentCrib.is_liked = !(currentCrib.is_liked ?? false);
    if (currentCrib.favorite_count === undefined)
      currentCrib.favorite_count = 0;
    currentCrib.favorite_count =
      currentCrib.is_liked === true
        ? currentCrib.favorite_count + 1
        : currentCrib.favorite_count === 0
        ? 0
        : currentCrib.favorite_count - 1;
    await inviteLikeCounter(currentCrib, ip);
    cribCondo === "crib" ? setCribList(tempList) : setCondoList(tempList);
  };

  const handleClick = (id) => {
    setSelectedCrib(id);
    toggle();
    hideBodyOverflow();
  };

  const extractIp = (ip) => {
    if (ip.startsWith('::ffff:')) {
      return ip.split(':').pop();
    }
    return ip;
  };

  const getUserIpAddress = async () => {
    const res = await methods.get(`${REACT_APP_VIRTUA_BACKEND_BASE_URL}/auth/client-ip`, keyCloakUser?.accessToken);
    setIP(extractIp(res.ip))
  }

  return (
    <>
      <>
        {isShowing && <CondoModal hide={toggle} cribDoc={selectedCrib} />}
        <div className="cribs-listing crib-condos">
          <div className="container">
            <div className="forehead">
              <div className="title-holder">
                <TitleHeading>Our Community Heroes</TitleHeading>
              </div>
              <div className="right-content">
                <Link to="/experience">
                  See More
                  <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}dropdown-icon.svg`} alt="dropdownicon" />
                </Link>
              </div>
            </div>
            {isLoading ? (
              <div className="loader-container">
                <div className="img_loader">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}tv_loader.gif`}
                    alt="loader"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="listing-holder">
                  <div className="row">
                    {cribList?.length > 0 &&
                      cribList?.map((crib, cribIndex) => (
                        <div className="custom-col" key={crib.id}>
                          <CribCard
                            crib={crib}
                            onImageError={onImageError}
                            handleClickToLikeCrib={() => {
                              handleLikeDislike(
                                ip,
                                crib.document_id,
                                crib.is_liked,
                                () => {
                                  handleClickToLikeCrib("crib", cribIndex);
                                }
                              );
                            }}
                            experienceLink={experienceLink}
                            copyLink={copyLink}
                            setCopyLink={setCopyLink}
                            handleClick={() => handleClick(crib.document_id)}
                          />
                        </div>
                      ))}
                    {condoList?.length > 0 &&
                      condoList?.map((condo, cribIndex) => (
                        <div className="custom-col" key={condo.id}>
                          <CribCard
                            crib={condo}
                            onImageError={onImageError}
                            handleClickToLikeCrib={() => {
                              handleLikeDislike(
                                ip,
                                condo.document_id,
                                condo.is_liked,
                                () => {
                                  handleClickToLikeCrib("condo", cribIndex);
                                }
                              );
                            }}
                            experienceLink={experienceLink}
                            copyLink={copyLink}
                            setCopyLink={setCopyLink}
                            handleClick={() => handleClick(condo.document_id)}
                          />
                        </div>
                      ))}
                    {cribList?.length < 1 && condoList < 1 && (
                      <div className="loader-container">
                        <p>No Records Found!</p>
                      </div>
                    )}
                  </div>
                </div>
                {isLoading && (
                  <div className="loader-container">
                    <div className="img_loader">
                      <img
                        src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}tv_loader.gif`}
                        alt="loader"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
      <PopNotification />
    </>
  );
};

export default Listing;
