import React from "react";
import styled from "styled-components";
import Button from "../../components/generic/button";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { Video } from "../home/hero-section/HeroSectionStyled";
import "./crib.scss";
import Listing from "./Listing";
import { CLAIM, METAVERSE_URL } from "../../enviroment";
import { Para, SectionHeading } from "../../components/styledComponent";

const GradientEffect = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  background: linear-gradient( 180deg, rgba(12,3,26,0) 0%, rgba(12,3,26,0.3) 48.54%, #0c031a 100% );
  width: 100%;
  z-index: 2;
  height: 322px;
`;

const Crib = () => {
  return (<>
    <div className="crib-page">
      <div className="top-banner">
        <GradientEffect />
        <Video cribVideo autoPlay loop muted playsInline>
          <source
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}public_cribs_bg.mp4`}
            type="video/mp4"
          />
        </Video>
        <div className="container">
          {/* <h1>Explore the Virtua Metaverse</h1> */}
          <SectionHeading headingMargin>Explore the Virtua Metaverse</SectionHeading>
          <Para>Not only can you explore mind-blowing 3D spaces conjured up by our community, you can also create your own and bring your friends along for the ride. So buckle up and dive into a world where the only limit is your imagination.</Para>
          <div className="btns">
            <Button
              cribButton
              upper
              secondary
              border0
              as="a"
              href={CLAIM}
            >
              GET YOURS NOW
            </Button>
            <Button
              upper
              className="know-more"
              as="a"
              href={METAVERSE_URL}
            >
              Know More
            </Button>
          </div>
        </div>
      </div>
      <Listing />
    </div>
  </>
  );
};

export default Crib;
