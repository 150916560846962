import React, { useEffect } from "react";
import HeroSection from "./hero-section/HeroSection";
import Slider from "./whats-new-slider/Slider";
import Experience from "./experience/Experience";
import CollectionSlider from "./collection-slider/CollectionSlider";
import VirtuaKolect from "./virtua-kolect/VirtuaKolect";
import MonsterZone from "./monster-zone/MonsterZone";
import CribSlider from "./crib-experience-slider/Slider";
import Founder from "./founder/Founder";
import VirtuaWeb from "./virtua-web/VirtuaWeb";
import VirtuaGamesNetwork from "./virtua-games-network/VirtuaGamesNetwork";
import VirtuaFeature from "./virtua-feature/VirtuaFeature";
import VirtuaTab from "./virtua-tabs/VirtuaTab";
import ConnectSection from "./connect-section/Connect";
import PartnerSection from "./partner/Partner";
import PlayGamesSlider from "./play-games-slider/PlayGamesSlider";
import CollectReward from "./collect-reward/CollectReward";
import MainBanner from "./main-banner/MainBanner";
import GameSlider from "./game-slider/GameSlider";
import WaitingSection from "./waiting-section/Waiting";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
import { CookiesModal } from "../modal/CookiesModal";
const Home = () => {
  const { keyCloakUser } = useKeycloakFeatures();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative">
      {/* <CookiesModal/> */}
      {/* <HeroSection /> */}
      {/* <PlayGamesSlider /> */}
      <MainBanner/>
      <GameSlider />
      <CollectReward />
      <ConnectSection />
      {keyCloakUser===null &&
      <WaitingSection />}
      <PartnerSection />
      {/* <CribSlider /> */}
      {/* <Slider /> */}
      {/* <Founder /> */}
      {/* <VirtuaGamesNetwork /> */}
      {/* <VirtuaFeature />  */}
      {/* <Experience /> */}
      {/* <VirtuaTab /> */}
      {/* <CollectionSlider /> */}
      {/* <VirtuaKolect /> */}
      {/* <VirtuaWeb /> */}
      {/* <MonsterZone /> */}
    </div>
  );
};

export default Home;
