const strings = {
  transactionsFailedMessage: 'Transaction Failed. Please try again.',
  transactionsFailedTitle: 'Transaction Failed',
  transactionsSuccessMessage: 'Please wait, your transaction is processing.\n Transaction Hash: ',
  transactionsSuccessTitle: 'Transaction Success',
  try_again: 'Try Again',
  claim_was_failed: 'Your mint was unsuccessful!\n Please try again',
  sign_login_reject: 'SignIn request rejected.',
  invalid_credentials: 'Invalid credentials.',
  wrong_network: 'It seems you are using the wrong network to connect. Please shift to Ethereum Mainnet',
  try_again_later: 'Something broke. Please try again later.',
  wallet_doesnot_match: 'Connected wallet does not match with your account wallet. Please try again.',
  configurations_saved_success: 'Configurations saved successfully',
  mint: 'Mint',
  processing: 'Processing...',
  connect_to_view_resources: 'Please login to access dashboard features',
  no_resources: 'You have no resources to claim at the moment!',
  no_wallet_found: 'You haven\'t attached any wallet',
  dashboard_logout_resources_text: 'Sign in to your wallet',
  sso_signin_eth_statement: 'Sign in with Ethereum to the Virtua app.',
  sso_signin_cardano_statement: 'Sign in with Cardano to the Virtua app.',
  session_expired: 'Session expired. Please login again to continue.'
}

export default strings
