export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export const hasEmptyField=(obj)=> {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          return true;
        }
      }
    }
    return false;
  }

export const maxLength=(str,maxlength)=>
{
  if(str.length<maxlength)
  {
    return true;
  }
  return false;
}
export const minLength=(str,minlength)=>
{
  if(str.length>minlength)
  {
    return true;
  }
  return false;
}

export const setPasscodePopupBoolean = (value)=> {
  localStorage.setItem('passcode-popup', JSON.stringify(value))
}
